import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppGroupingList from '../../common/components/AppGroupingList';
import useAppContext from '../../common/hooks/useAppContext';
import {listToGroupingList, expandGroupList} from '../../common/group-list-helper';
import {objectComparator} from '../../common/data-helper';

const tableFields = [
  { name: "constructName", label: "現場名", type: "text", xs: true },
];

const breadcrumbs = [
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const ConstructListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const constructs = await app.http().get('/master/construct');
        constructs.sort(objectComparator('buildingConfirmationDate'));
        setData(listToGroupingList(constructs, 'corporateName'));
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleExpandGroup = (index) => {
    const result = expandGroupList(data, index);
    setData(result);
  };

  const handleSelectItem = (d) => (event) => navigate(`${d._id}/delivery`);
  const rowActions = [
    { name: '伝票一覧', icon: <ChevronRight color="primary" />, handler: handleSelectItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="施工会社・現場名一覧" breadcrumbs={breadcrumbs} />
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        <AppGroupingList groupName="corporateName" tableFields={tableFields} data={data} onExpand={handleExpandGroup} rowActions={rowActions} />
      </Container>
    </div>
  );
};

export default ConstructListPage;
