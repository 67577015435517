import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  rowButton: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  border: {
    border: 'solid #cccccc 1px !important',
  }
}));

const AppAccordion = ({title, border = false, children}) => {
  const classes = useStyles();
  const summaryClasses = border ? {root: classes.border} : {};

  return (
    <Accordion elevation={0} disableGutters={true} square={true} defaultExpanded={false}>
      <AccordionSummary expandIcon={<ExpandMore color="secondary" />} classes={summaryClasses}>
        <Typography variant="h6" sx={{pr: '40px'}}>{title}</Typography>
        <Box sx={{position: 'absolute', right: 40, top: '33%'}}>
          <Typography color="secondary" variant="body2">詳細</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails classes={{root: classes.accordionDetails}} onClick={(event) => event.stopPropagation()}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AppAccordion;
