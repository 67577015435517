import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import useAppContext from '../../common/hooks/useAppContext';
import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppForm from '../../common/components/AppForm'
import AppAccordion from '../../common/components/AppAccordion'
import Construct from './components/Construct';
import ConcretePlant from './components/ConcretePlant';
import CompoundTable from './components/CompoundTable';
import { ConcreteLocation, DeliveryStatus, formatVolume } from '../../common/data-helper';

const formFields = [
  { name: "supplier", label: "製造プラント", type: "text" },
  { name: "concreteID", label: "配合計画", type: "select" },
  { name: "volume", label: "数量", type: "number", format: formatVolume },
  { name: "shippingDatetime", label: "納入発", type: "date" },
  { name: "deliveryDatetime", label: "納入着", type: "date" },
  { name: "status", label: "状況", type: "select" },
  { name: "creator", label: "活動量入力者", type: "select" },
  { name: "confirmer", label: "受入確認者", type: "select" },
  { name: "completeness", label: "完全性", type: "select" },
  { name: "location", label: "打設箇所種別", type: "select" },
  { name: "placement", label: "打設箇所", type: "text" },
  { name: "reliability", label: "信頼性", type: "select" },
  { name: "_id", label: "活動量ID", type: "text", size: 3 },
  { name: "assetId", label: "proof", type: "text", size: 3 },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const DeliveryPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const params = useParams();
  const [selectList, setSelectList] = useState({
    location: ConcreteLocation,
    status: DeliveryStatus,
    concreteID: [],
  });
  const [data, setData] = useState(null);
  const [construct, setConstruct] = useState(null);
  const [compoundPlan, setCompoundPlan] = useState(null);
  const [supplier, setSupplier] = useState(null);

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const res = await app.http().getSlipWithRelations(params.deliveryId);
        setData(res.slip);
        setConstruct(res.construct);
        setCompoundPlan(res.compoundPlan);
        setSupplier(res.supplier);
        setSelectList({
          ...selectList,
          ...res.selectList,
        });
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, [params.activityId, params.deliveryId]); // eslint-disable-line react-hooks/exhaustive-deps

  const breadcrumbs = [
    { name: '現場名・活動量一覧', path: '../activity' },
    { name: '活動詳細一覧', path: `../activity/${params.activityId}/delivery` },
  ];
  
  return (
    <div className={classes.root}>
      <AppPrimaryArea title="活動量情報" breadcrumbs={breadcrumbs} />
      <Container component="main" maxWidth="md" sx={{ my: 2 }}>
        <AppAccordion title={construct ? construct.constructName : ''}>
          <Container component="main" sx={{ mx: 1, my: 1 }}>
            <Construct data={construct} />
          </Container>
        </AppAccordion>
      </Container>
      <hr />
      <Container component="main" maxWidth="md" sx={{ my: 2 }}>
        <AppAccordion title={compoundPlan ? `${compoundPlan.plantName}　${compoundPlan.name}` : ''}>
          <Container component="main" sx={{ mx: 1, my: 1 }}>
            <ConcretePlant data={supplier} />
            <Box sx={{my: 3}} />
            <CompoundTable data={compoundPlan} />
          </Container>
        </AppAccordion>
      </Container>
      <hr />
      <Container component="main" maxWidth="md" sx={{pt: 4}}>
        <AppForm formFields={formFields} data={data} selectList={selectList} readonly={true} columns={{ xs: 1, md: 3 }} />
      </Container>
    </div>
  );
};

export default DeliveryPage;
