import React from 'react';
import { makeStyles } from '@mui/styles';
import { DialogContent, Dialog, DialogTitle } from '@mui/material';
import * as yup from 'yup';

import useAppContext from '../hooks/useAppContext';
import AppForm from './AppForm';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 300
  },
  text: {
  },
  buttonArea: {
    justifyContent: 'center'
  },
  button: {
    minWidth: 80
  }
}));

const firstPasswordSchema = yup.string()
  .required('パスワードを入力してください')
  // eslint-disable-next-line no-template-curly-in-string
  .min(8, 'パスワードは${min}文字以上で指定してください');
const confirmPasswordSchema = yup.mixed()
  .required(`確認パスワードを入力してください`)
  .test('passwordCheck', '入力パスワードが一致していません', (value, context) => value === context.parent.secondPassword);
const formFields = [
  { name: "firstPassword", label: "現在のパスワード", type: "password", schema: firstPasswordSchema },
  { name: "secondPassword", label: "新しいパスワード", type: "password", schema: firstPasswordSchema },
  { name: "thirdPassword", label: "新しいパスワード（確認用）", type: "password", schema: confirmPasswordSchema },
];

const ChangePasswordDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const app = useAppContext();

  const handleChangePassword = async (values) => {
    app.showConfirmDialog('確認', 'パスワードの変更を実行してよろしいですか？', async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          const req = {
            oldPassword: values.firstPassword,
            password: values.secondPassword
          };
          await app.http().post('/auth/change-password', req);
          app.showMessageDialog('確認', 'パスワードの変更を実施しました。<br>次回ログイン時は、新しいパスワードを使用してください。');
          onClose();
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });
  };

  const submitActions = [
    { name: '変更', handler: handleChangePassword },
    { name: 'キャンセル', handler: onClose, variant: 'outlined', skipValidation: true, color: 'primary' }
  ];

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>パスワード変更</DialogTitle>
      <DialogContent>
        <AppForm formFields={formFields} submitActions={submitActions} columns={{ xs: 1, md: 1 }} />
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;
