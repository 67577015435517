import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Typography } from '@mui/material';

import MasterMaintenancePrimaryArea from './components/MasterMaintenancePrimaryArea';
import useAppContext from '../../common/hooks/useAppContext';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const BlockchainMaintenancePage = () => {
  const classes = useStyles();
  const app = useAppContext();

  const handleExecute = async () => {
    app.showConfirmDialog('確認', `ブロックチェーン内の伝票・活動量の全データをクリアします。この操作は取り消すことができませんが、実行してよろしいですか？`, async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          await app.http().post('/blockchain/deleteall', {});
          app.showMessageDialog('確認', `ブロックチェーン内の全データをクリアしました。`);
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      <MasterMaintenancePrimaryArea selected="blockchain-maintenance">
        <Container component="main" maxWidth="md" sx={{py: 2}}>
          <Typography>ブロックチェーンの全データをクリアします</Typography>
          <Box sx={{ py: 1 }}>
            <Button color="error" variant="contained" onClick={handleExecute}>データクリアの実行</Button>
          </Box>
        </Container>
      </MasterMaintenancePrimaryArea>
    </div>
  );
};

export default BlockchainMaintenancePage;
