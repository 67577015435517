import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Button, IconButton, Checkbox, Radio, MenuItem, Select } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(theme => ({
  table: {
  },
  body: {
    background: '#ffffff',
  },
  rowButton: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}));

const AppTable = ({ tableFields, data, headerActions = [], rowActions = [], selection, onChangeSelection }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [displayData, setDisplayData] = useState([]);

  const handleChangeSelection = (index) => (event) => {
    const result = [...data];
    if (selection === 'radio') {
      result.forEach((d) => d._checked = false);
      result[index]._checked = true;
    } else {
      result[index]._checked = !result[index]._checked;
    }
    setDisplayData(result);
    if (onChangeSelection) {
      const selected = result.filter((d) => d._checked);
      onChangeSelection(selected);
    }
  };

  useEffect(() => {
    const result = [...data];
    result.forEach((d) => d._checked = false);
    setDisplayData(result);
  }, [data]);

  const buildCell = (b, h) => {
    if (h.type === "precastConcreteId") {
      return  <Select
                size="small"
                fullWidth
                onChange={(e) => {
                  b[h.name] = e.target.value
                  h.onChange(e.target.value, b.activityID, b.isPCa)
                }}
                value={b[h.name] ? b[h.name] : 0}
              >
                <MenuItem value={0}>
                  <span className={classes.selectText}>{b.isPCa ? "PCa" : "なし"}</span>
                </MenuItem>
                {
                  h.data.map(e => (
                    <MenuItem value={e._id} key={e._id}>
                      <span className={classes.selectText}>{e.name}</span>
                    </MenuItem>
                  ))
                }
              </Select>
    }

    if (h.type === "isPCa") {
      return  <Checkbox 
                color="primary"
                size="small"
                checked={b[h.name] ? true : false}
                onChange={(e)=>{
                  b[h.name] = e.target.checked
                  h.onChange(e.target.checked, b.activityID, b.precastConcreteId)
                }}
              />
    }

    return h.format ? h.format(b[h.name]) : b[h.name]
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
        <Box>
          {headerActions.map((d) => (
            <Button key={d.name} fullWidth variant="contained" color="info" onClick={d.handler}>
              {d.name}
            </Button>
          ))}
        </Box>
      </Box>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {(selection === 'select' || selection === 'radio') && (
                <TableCell size="small" sx={{ py: '2px', width: '40px' }} />
              )}
              {tableFields.filter((h) => mdUp || h.xs).map((h) => (
                <TableCell key={h.name} size="small" align={h.type === 'number' ? 'right' : 'left'} sx={{ color: '#666666' }}>
                  {h.label}
                </TableCell>
              ))}
              {rowActions.length > 0 && (
                <TableCell />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((d, i) => (
              <TableRow key={i} classes={{ root: classes.body }}>
                {selection === 'select' && (
                  <TableCell size="small" sx={{ py: '4px', width: '40px' }} >
                    <Checkbox color="primary" size="small" checked={d._checked} onChange={handleChangeSelection(i)} />
                  </TableCell>
                )}
                {selection === 'radio' && (
                  <TableCell size="small" sx={{ py: '4px', width: '40px' }} >
                    <Radio color="primary" size="small" checked={d._checked} onChange={handleChangeSelection(i)} />
                  </TableCell>
                )}
                {tableFields.filter((h) => mdUp || h.xs).map((h) => (
                  <TableCell key={h.name} size="small" align={h.type === 'number' ? 'right' : 'left'}>{buildCell(d, h)}</TableCell>
                ))}
                {rowActions.length > 0 && (
                  <TableCell size="small" align="right">
                    {rowActions.map((a) => a.icon ? (
                      <IconButton key={a.name} size="small" onClick={a.handler(d)}>{a.icon}</IconButton>
                    ) : (
                      <span className={classes.rowButton} key={a.name} onClick={a.handler(d)}>{a.name}</span>
                    ))}
                  </TableCell>
                )}
              </TableRow>
            ))}
            {data.length === 0 && (
              <TableRow>
                <TableCell colSpan={tableFields.length + 1} sx={{ textAlign: 'center' }}>（該当データがありません）</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default AppTable;
