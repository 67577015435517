import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Button, Grid, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormik } from 'formik';

import { AppText, AppNumber, AppPassword, AppSelect, AppRadio, AppDate, AppDateTime, AppAttachment, AppMultipleSelect, AppUpload, AppButton } from './AppFormField';
import { getValueObject, getValidationSchemaObject, toStructuredJson } from '../form-helper';
import { dateToTimestamp, formatDate } from '../data-helper';

const useStyles = makeStyles(theme => ({
  root: {
  },
  section: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth: 12,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: 1,
    paddingLeft: 8
  }
}));

const AppForm = ({ formFields, data, selectList = {}, handlers = {}, adornment = {}, submitActions = [], readonly = false, columns = { xs: 1, md: 3 }, watch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const toAppJson = (values) => {
    const result = toStructuredJson(values);
    formFields.filter((d) => d.type === 'date' || d.type === 'datetime').forEach((d) => result[d.name] = dateToTimestamp(result[d.name]));
    formFields.filter((d) => d.type === 'datestr').forEach((d) => result[d.name] = formatDate(result[d.name]));
    return result;
  };

  const formik = useFormik({
    initialValues: getValueObject(formFields, data),
    validationSchema: getValidationSchemaObject(formFields),
    onSubmit: async (values) => {
      const appValues = toAppJson(values);
      delete appValues._button;
      const action = submitActions.find((d) => d.name === values._button);
      action.handler(appValues);
    },
  });

  useEffect(() => {
    const values = getValueObject(formFields, data);
    formik.setValues(values);
  }, [data, formFields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watch) {
      const appValues = toAppJson(formik.values);
      watch(appValues);
    }
  }, [formik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (d) => async (event) => {
    if (d.skipValidation) {
      const appValues = toAppJson(formik.values);
      const action = submitActions.find((s) => s.name === d.name);
      action.handler(appValues);
    } else {
      await formik.setFieldValue('_button', d.name);
      await formik.handleSubmit(event);
    }
  };

  const handleChange = (d) => async (event) => {
    const handler = handlers[d.name];
    if (handler && handler.onChange) {
      const previousValues = toAppJson(formik.values);
      await formik.handleChange(event);
      handler.onChange(event, previousValues);
    } else {
      await formik.handleChange(event);
    }
  };

  const handleBlur = (d) => (event) => {
    const handler = handlers[d.name];
    if (handler && handler.onBlur) {
      const previousValues = toAppJson(formik.values);
      formik.handleBlur(event);
      handler.onBlur(event, previousValues);
    } else {
      formik.handleBlur(event);
    }
  };

  const handleChangeRadio = (d) => async (event, value) => {
    await formik.setFieldTouched(d.name);
    await formik.setFieldValue(d.name, value, true);
  };

  const handleChangeDate = (d) => async (date) => {
    await formik.setFieldTouched(d.name);
    await formik.setFieldValue(d.name, date, true);
  };

  const handleChangeAttachment = (d) => async ({ newFiles, removeFiles }) => {
    const attachments = { ...formik.values._attachments };
    attachments[d.name] = { newFiles, removeFiles };
    await formik.setFieldTouched(d.name);
    await formik.setFieldValue('_attachments', attachments);
  };

  const handleChangeAutoComplate = (d) => async (value) => {
    await formik.setFieldTouched(d.name);
    await formik.setFieldValue(d.name, value);
  };

  const renderField = (d) => {
    switch (d.type) {
      case 'text':
        return (
          <AppText
            type={d.type}
            name={`['${d.name}']`}
            label={d.label}
            value={formik.values[d.name]}
            error={formik.touched[d.name] && formik.errors[d.name]}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChange(d)}
            onBlur={handleBlur(d)}
            format={d.format}
            inputProps={d.inputProps}
            adornment={adornment[d.name]}
            offAutoComplete={d.offAutoComplete}
          />
        );
      case 'number':
        return (
          <AppNumber
            name={`['${d.name}']`}
            label={d.label}
            value={formik.values[d.name]}
            error={formik.touched[d.name] && formik.errors[d.name]}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChange(d)}
            onBlur={handleBlur(d)}
            format={d.format}
            inputProps={d.inputProps}
            unit={d.unit}
          />
        );
      case 'password':
        return (
          <AppPassword
            type={d.type}
            name={`['${d.name}']`}
            label={d.label}
            value={formik.values[d.name]}
            error={formik.touched[d.name] && formik.errors[d.name]}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChange(d)}
            onBlur={handleBlur(d)}
            format={d.format}
            inputProps={d.inputProps}
          />
        );
      case 'select':
        return (
          <AppSelect
            type={d.type}
            name={`['${d.name}']`}
            label={d.label}
            value={formik.values[d.name]}
            error={formik.touched[d.name] && formik.errors[d.name]}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChange(d)}
            onBlur={handleBlur(d)}
            selectList={selectList[d.name]}
          />
        );
      case 'radio':
        return (
          <AppRadio
            type={d.type}
            name={d.name}
            label={d.label}
            value={formik.values[d.name]}
            error={formik.touched[d.name] && formik.errors[d.name]}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChangeRadio(d)}
            selectList={selectList[d.name]}
          />
        );
      case 'date':
      case 'datestr':
        return (
          <AppDate
            size="small"
            type={d.type}
            name={d.name}
            label={d.label}
            value={formik.values[d.name]}
            error={formik.touched[d.name] && formik.errors[d.name]}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChangeDate(d)}
            onBlur={formik.handleBlur}
          />
        );
      case 'datetime':
        return (
          <AppDateTime
            size="small"
            type={d.type}
            name={d.name}
            label={d.label}
            value={formik.values[d.name]}
            error={formik.touched[d.name] && formik.errors[d.name]}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChangeDate(d)}
            onBlur={formik.handleBlur}
          />
        );
      case 'attachment':
        return (
          <AppAttachment
            label={d.label}
            files={formik.values[d.name]}
            currentNewFiles={formik.values._attachments[d.name].newFiles}
            currentRemoveFiles={formik.values._attachments[d.name].removeFiles}
            error={d.error}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChangeAttachment(d)}
          />
        );
      case 'multiselect':
        return (
          <AppMultipleSelect
            type={d.type}
            name={d.name}
            label={d.label}
            value={formik.values[d.name]}
            error={formik.touched[d.name] && formik.errors[d.name]}
            required={d.required}
            readonly={readonly || d.readonly}
            onChange={handleChangeAutoComplate(d)}
            onBlur={handleBlur(d)}
            selectList={selectList[d.name]}
          />
        );

      case 'upload':
        return (
          <AppUpload
            label={d.label}
            required={d.required}
            file={d.file}
            setFile={d.setFile}
            accept={d.accept}
            multiple={d.multiple}
          />
        );

      case 'button':
        return (
          <AppButton
            label={d.label}
            onClick={d.onClick}
            endIcon={d.endIcon}
          />
        );
      case 'section':
        return (
          <Typography variant="subtitle1" classes={{ root: classes.section }}>{d.label}</Typography>
        );
      case 'blank':
        return (<div />);
      default:
        return (<div>{d.name} is unknown type</div>);
    }
  };

  const renderGrid = (d) => {
    const maxColumns = mdUp ? columns.md : columns.xs;
    const alwaysFull = ['attachment', 'section', 'multiselect'];
    const size = alwaysFull.includes(d.type) ? maxColumns : (d.size && maxColumns >= d.size ? d.size : 1);
    return (
      <Grid key={d.name} item xs={size} md={size}>
        {renderField(d)}
      </Grid>
    );
  };

  const rowSpacing = { xs: 1, md: 3 };
  const columnSpacing = readonly ? { xs: 0, md: 0 } : { xs: 0, md: 3 };
  return (
    submitActions.length === 1 ? 
      <form className={classes.root} onSubmit={handleSubmit(submitActions[0])}>
        <Grid container rowSpacing={rowSpacing} columnSpacing={columnSpacing} columns={columns}>
          {formFields.filter((d) => d.type !== 'hidden').map((d) => renderGrid(d))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3 }}>
          <Box sx={{ ml: 0 }} flexGrow={!mdUp || submitActions[0].fullWidth ? 1 : 0}>
            <Button
              type="submit"
              size="large"
              fullWidth={!mdUp || submitActions[0].fullWidth}
              variant={submitActions[0].variant ? submitActions[0].variant : 'contained'}
              color={submitActions[0].color ? submitActions[0].color : 'info'}
              disabled={submitActions[0].disabled}
            >
              {submitActions[0].name}
            </Button>
          </Box>
        </Box>
      </form> : 
      <form className={classes.root}>
        <Grid container rowSpacing={rowSpacing} columnSpacing={columnSpacing} columns={columns}>
          {formFields.filter((d) => d.type !== 'hidden').map((d) => renderGrid(d))}
        </Grid>
        {submitActions.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3 }}>
            {submitActions.map((d, i) => (
              <Box key={d.name} sx={{ ml: i === 0 ? 0 : 1 }} flexGrow={!mdUp || d.fullWidth ? 1 : 0}>
                <Button
                  size="large"
                  fullWidth={!mdUp || d.fullWidth}
                  variant={d.variant ? d.variant : 'contained'}
                  color={d.color ? d.color : 'info'}
                  onClick={handleSubmit(d)}
                  disabled={d.disabled}
                >
                  {d.name}
                </Button>
              </Box>
            ))}
          </Box>
        )}
      </form>
  );
};

export default AppForm;
