const initGroupingList = (data) => {
  data.forEach((d) => {
    d._children = [];
    d._expand = false;
  });
  return data;
};

const listToGroupingList = (data, groupName, groupNameSorter) => {
  const obj = {};
  data.forEach((d) => {
    let entry = obj[d[groupName]];
    if (!entry) {
      entry = [];
      obj[d[groupName]] = entry;
    }
    entry.push(d);
  });
  const result = Object.keys(obj).map((k) => ({ [groupName]: k, _expand: false, _children: obj[k] }));
  if (result.length > 0) {
    if (groupNameSorter) {
      result.sort(groupNameSorter);
    }
    result[0]._expand = true;
  }
  return result;
};

const expandGroupList = (data, index, children = null, openMultipleGroups = false) => {
  const result = [...data];
  const nextState = !result[index]._expand;
  if (!openMultipleGroups) {
    result.forEach((d) => d._expand = false);
  }
  result[index]._expand = nextState;
  if (children != null) {
    result[index]._children = children;
  }
  return result;
};

export {
  initGroupingList,
  listToGroupingList,
  expandGroupList,
};
