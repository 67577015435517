import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import useAppContext from '../../common/hooks/useAppContext';
import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppForm from '../../common/components/AppForm'
import { ConcreteLocation, DeliveryStatus, dateToTimestamp, formatVolume } from '../../common/data-helper';
import dayjs from 'dayjs';

const formFields1 = [
  { name: "deliveryDatetime", label: "納入着", type: "datetime", required: true, size: 1 },
  { name: "location", label: "打設箇所種別", type: "radio", required: true, size: 1.7 },
  { name: "placement", label: "打設箇所", type: "text", required: true, size: 1 },
  { name: "confirmer", label: "伝票作成者", type: "hidden", required: true },
];

const formFields2 = [
  { name: "volume", label: "数量", type: "number", readonly: true, format: formatVolume },
  { name: "totalVolumes", label: "累積数量", type: "text", readonly: true },
  { name: "totalTracks", label: "納入台数", type: "text", readonly: true },
  { name: "shippingDatetime", label: "納入発", type: "datetime", readonly: true },
  { name: "supplier", label: "製造プラント", type: "text", readonly: true },
  { name: "concreteID", label: "配合計画", type: "select", readonly: true },
  { name: "creator", label: "伝票作成者", type: "select", readonly: true },
  { name: "confirmer", label: "受入担当者", type: "select", readonly: true },
  { name: "status", label: "状況", type: "select", readonly: true },
  { name: "_id", label: "伝票番号", type: "text", readonly: true, size: 3},
];

const localStorageKey = (app, variable) => `constructor-user:${app.loginInfo.user.loginId}:${dayjs().format('YYYYMMDD')}:${variable}`;

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const DeliveryPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const params = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    deliveryDatetime: dateToTimestamp(dayjs()),
    location: 'Underground',
    confirmer: app.loginInfo.user._id,
  });
  const [selectList, setSelectList] = useState({
    status: DeliveryStatus,
    location: ConcreteLocation,
    confirmer: [
      { label: app.loginInfo.user.displayName, value: app.loginInfo.user._id }
    ]
  });

  useEffect(() => {
    if (params.deliveryId) {
      const query = async () => {
        try {
          app.setIsLoading(true);
          const res = await app.http().getSlipWithRelations(params.deliveryId);
          const totalTracks = res.slip.activity.acceptedTrackNumber + ' / ' + res.slip.activity.shippingTrackNumber + ' 台';
          const totalVolumes = res.slip.activity.acceptedVolume.toFixed(2) + ' / ' + res.slip.activity.shippingVolume.toFixed(2) + ' m\u00B3';
          const lastValueStr = localStorage.getItem(localStorageKey(app, 'lastValue'));
          const lastValue = lastValueStr ? JSON.parse(lastValueStr) : {};
          setData({
            ...res.slip,
            ...data,
            totalTracks,
            totalVolumes,
            ...lastValue,
          });
          setSelectList({
            ...selectList,
            ...res.selectList,
          });
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      };
      query();
    }
  }, [params.activityId, params.deliveryId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAccept = (accept) => async (values) => {
    try {
      if (!accept) {
        const confirmed = await new Promise((resolve) => {
          app.showConfirmDialog('返却の確認', '本当に返却してよろしいですか？', (result) => resolve(result));
        });
        if (!confirmed) {
          return;
        }
      }
      app.setIsLoading(true);
      const status = accept ? '1' : '2';
      const req = {
        _id: values._id,
        deliveryDatetime: values.deliveryDatetime,
        location: values.location,
        placement: values.placement,
        confirmer: values.confirmer,
        status: status
      };
      const lastValue = {
        location: values.location,
      };
      localStorage.setItem(localStorageKey(app, 'lastValue'), JSON.stringify(lastValue));
      await app.http().post('/blockchain/slip/accept', req);
      navigate(`../activity/${params.activityId}/delivery`);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const breadcrumbs = [
    // { name: '現場名・伝票一覧', path: `../delivery` },
    { name: '現場名・活動量一覧', path: '../activity' },
    { name: '伝票一覧', path: `../activity/${params.activityId}/delivery` },
  ];
    
  const submitActions = [
    { name: '受入', handler: handleAccept(true) },
    { name: '返却', handler: handleAccept(false), color: 'primary', variant: 'outlined' }
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={data ? data.constructName : ''} breadcrumbs={breadcrumbs}>
        <AppForm formFields={formFields1} data={data} selectList={selectList} submitActions={submitActions} columns={{ xs: 1, md: 4 }}/>
      </AppPrimaryArea>
      <Container component="main" maxWidth="md" sx={{ my: 2 }}>
        <AppForm formFields={formFields2} data={data} selectList={selectList} readonly={true} />
      </Container>
    </div>
  );
};

export default DeliveryPage;
