import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Toolbar } from '@mui/material';

import AppHeader from '../common/components/AppHeader';
import AppDrawer from '../common/components/AppDrawer';
import ActivityListPage from './delivery/ActivityListPage';
import DeliveryListPage from './delivery/DeliveryListPage';
import AcceptDeliveryPage from './delivery/AcceptDeliveryPage';
import ViewDeliveryPage from './delivery/ViewDeliveryPage';
import DeliveryPage from './delivery/DeliveryPage';
import ConstructListPage from './construct/ConstructListPage';
import ConstructPage from './construct/ConstructPage';
import CompoundPlanListPage from './construct/CompoundPlanListPage';
import CompoundPlanPage from './construct/CompoundPlanPage';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
}));

const pages = [
  { name: '現場名・活動量一覧', path: '/constructor-user/activity' },
  { name: '現場管理', path: '/constructor-user/construct' },
];

const ConstructorUserPage = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppHeader />
      <AppDrawer pages={pages}></AppDrawer>
      <div className={classes.content}>
        <Toolbar variant="dense" />
        <Routes>
          <Route path="/" element={<Navigate to="./activity" />} />
          <Route path="/activity" element={<ActivityListPage />} />
          <Route path="/activity/:activityId/delivery" element={<DeliveryListPage />} />
          <Route path="/activity/:activityId/delivery/:deliveryId/accept" element={<AcceptDeliveryPage />} />
          <Route path="/activity/:activityId/delivery/:deliveryId/view" element={<ViewDeliveryPage />} />
          <Route path="/delivery/:deliveryId" element={<DeliveryPage />} />
          <Route path="/construct" element={<ConstructListPage />} />
          <Route path="/construct/:constructId" element={<ConstructPage />} />
          <Route path="/construct/:constructId/compound-plan" element={<CompoundPlanListPage />} />
          <Route path="/construct/:constructId/compound-plan/:compoundPlanId" element={<CompoundPlanPage />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default ConstructorUserPage;
