import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { Stepper, Step, StepButton, StepContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { HelpOutlineOutlined } from '@mui/icons-material';
import AppDataTable from '../../common/components/AppDataTable';
import MasterMaintenancePrimaryArea from './components/MasterMaintenancePrimaryArea';
import TextAreaDialog from './components/TextAreaDialog';
import useAppContext from '../../common/hooks/useAppContext';
import { getMasterByText } from './master-data-helper';
import { parseTsv, readTextFile } from '../../common/tsv-file-helper';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

const unknownMaster = {
  label: '不明',
  name: 'Invalid',
};

const InformationTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '80vw',
  },
});

const MasterMaintenancePage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [master, setMaster] = useState(null);
  const [content, setContent] = useState('');
  const [tableFields, setTableFields] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [step, setStep] = useState(0);
  const [mode, setMode] = useState('INSERT');
  const fileInputRef = useRef(null);

  const handleParseContent = (text) => {
    setMode('INSERT');
    const newTextList = text.split(/\r\n|\r|\n/);
    newTextList[0] = newTextList[0].replace(new RegExp('"', 'g'), '');
    text = newTextList.join('\n')
    const masterDef = getMasterByText(text);
    if (masterDef != null) {
      const { fields, data } = parseTsv(text);
      setContent(text);
      setMaster(masterDef);
      setTableFields(fields);
      setDisplayData(data);
      setStep(1);
    } else {
      setContent('');
      setMaster(unknownMaster);
      setTableFields([]);
      setDisplayData([]);
    }
  };

  const handlePaste = async () => {
    const text = await navigator.clipboard.readText();
    handleParseContent(text);
  };

  const handleOpenFile = () => fileInputRef.current.click();

  const handleFile = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const text = await readTextFile(file);
      handleParseContent(text);
    }
    event.target.value = '';
  };

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = (text) => {
    if (text) {
      handleParseContent(text);
    }
    setDialogOpen(false);
  };

  const handleChangeMode = (event) => setMode(event.target.value);
  const handleExecute = async () => {
    // confirm and execute
    const modeTexts = {
      'INSERT': '新規登録',
      'DELETE': '削除',
      'UPDATE': '更新',
      'REPLACE': '入れ替え',
    };
    app.showConfirmDialog(`マスタ${modeTexts[mode]}の確認`, `${master.label}の${modeTexts[mode]}を実行します。よろしいですか？`, async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          const req = {
            name: master.name,
            content: content,
            mode: mode,
          };
          const result = await app.http().post('/master/maintenance', req);
          let msg = `入力データ件数: ${result.n} 件 `;
          if (mode === 'INSERT' || mode === 'REPLACE') {
            msg += `(うち新規に登録された件数: ${result.nUpserted} 件)`;
          } else if (mode === 'UPDATE') {
            msg += `(うち更新された件数: ${result.nModified + result.nMatched} 件)`;
          } else if (mode === 'DELETE') {
            msg += `(うち削除された件数: ${result.nRemoved} 件)`;
          }
          app.showMessageDialog(`マスタ${modeTexts[mode]}の終了`, `${master.label}の${modeTexts[mode]}が完了しました。</br>${msg}`, () => {
            setStep(0);
            setMaster(null);
            setContent('');
            setTableFields([]);
            setDisplayData([]);
          });
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });
  };

  const text = master ? (master.name === 'Invalid' ? '入力されたデータは無効です' : master.label) : '';
  return (
    <div className={classes.root}>
      <MasterMaintenancePrimaryArea selected="master-maintenance">
        <Container component="main" maxWidth="md" sx={{ py: 2 }}>
          <Stepper activeStep={step} orientation="vertical">
            <Step key="step1">
              <StepButton color="inherit" onClick={() => setStep(0)}>
                データの指定
              </StepButton>
              <StepContent>
                <Typography variant="body2">処理を実施したいマスタデータを入力します</Typography>
                <Box sx={{ pt: 1 }}>
                  <Button color="primary" variant="outlined" onClick={handlePaste}>クリップボードから入力</Button>
                  &nbsp;&nbsp;
                  <input style={{ display: 'none' }} type="file" accept=".tsv" ref={fileInputRef} onChange={handleFile} />
                  <Button color="primary" variant="outlined" onClick={handleOpenFile}>ファイルを選択</Button>
                  &nbsp;&nbsp;
                  <Button color="primary" variant="outlined" onClick={handleOpenDialog}>テキストで入力</Button>
                </Box>
              </StepContent>
            </Step>
            <Step key="step2" disabled={!content}>
              <StepButton color="inherit" onClick={() => setStep(1)}>
                データの更新
              </StepButton>
              <StepContent>
                <Typography>マスタデータの更新処理を実行します</Typography>
                <Box sx={{ py: 1 }}>
                  <Select size="small" variant="standard" name="master" onChange={handleChangeMode} value={mode} sx={{ verticalAlign: 'bottom', width: 100 }}>
                    <MenuItem value="INSERT">新規登録</MenuItem>
                    {/* <MenuItem value="UPDATE">更新</MenuItem> */}
                    <MenuItem value="DELETE">削除</MenuItem>
                    {(master && master.name !== 'User') && <MenuItem value="REPLACE">入れ替え</MenuItem>}
                  </Select>
                  <InformationTooltip title={
                    <React.Fragment>
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <span style={{ fontWeight: 'bold', textDecoration: 'underline', marginRight: 4 }}>新規登録</span>
                        指定されたデータを新規登録します。登録済みのデータ行については、処理がスキップされます。
                      </div>
                      {/* <div style={{ whiteSpace: 'nowrap' }}>
                        <span style={{ fontWeight: 'bold', textDecoration: 'underline', marginRight: 4 }}>更新</span>
                        指定されたデータを更新します。登録されていないデータ行については、処理がスキップされます。
                      </div> */}
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <span style={{ fontWeight: 'bold', textDecoration: 'underline', marginRight: 4 }}>削除</span>
                        指定されたデータを削除します。指定されたデータに含まれないデータはそのまま残ります。
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <span style={{ fontWeight: 'bold', textDecoration: 'underline', marginRight: 4 }}>入れ替え</span>
                        既存データを全て削除した上で、指定されたデータが新規登録されます。
                      </div>
                    </React.Fragment>
                  }>
                    <IconButton>
                      <HelpOutlineOutlined />
                    </IconButton>
                  </InformationTooltip>
                  &nbsp;&nbsp;
                  <Button color="info" variant="contained" disabled={!content} onClick={handleExecute}>{master ? master.label : ''}の処理を実行</Button>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </Container>
      </MasterMaintenancePrimaryArea>
      <Container component="main" maxWidth="md">
        <Box sx={{ my: 1 }}>
          <Typography variant="h6">{text}</Typography>
        </Box>
        <AppDataTable groupFields={[]} tableFields={tableFields} data={displayData} maxHeight="calc(100vh - 400px)" />
      </Container>
      <TextAreaDialog open={dialogOpen} initialText="" onClose={handleCloseDialog} />
    </div>
  );
};

export default MasterMaintenancePage;
