import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import useAppContext from '../../common/hooks/useAppContext';
import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import CompoundPlan from './components/CompoundPlan';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const CompoundPlanPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    corporateId: app.loginInfo.user.corporateId,
    corporateName: app.loginInfo.user.corporateName,
    plannedVolume: 0,
    disclosure: 'Private',
  });
  const [readOnly, setReadOnly] = useState(true);
  const [construct, setConstruct] = useState(null);

  useEffect(() => {
    if (params.constructId && params.compoundPlanId) {
      const query = async () => {
        try {
          app.setIsLoading(true);
          const res1 = await app.http().get(`/master/construct/${params.constructId}`);
          setConstruct(res1);
          if (params.compoundPlanId !== 'new') {
            const res2 = await app.http().get(`/master/compound-plan/${params.compoundPlanId}`);
            res2.periodsText = res2.periods.map((x) => x.from + '-' + x.to).join(', ');
            setData(res2);
          } else {
            setReadOnly(false);
          }
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      };
      query();
    }
  }, [params.constructId, params.compoundPlanId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditItem = (edit) => () => {
    if (params.constructId === 'new') {
      navigate(`../construct/${params.constructId}/compound-plan`);
    } else {
      setReadOnly(!edit);
    }
  };

  const handleRemoveItem = () => {
    app.showConfirmDialog('削除確認', `${data.name} を削除しようとしています。</br>実行してよろしいですか？`, async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          await app.http().delete(`/master/compound-plan/${data._id}`);
          navigate(`../construct/${params.constructId}/compound-plan`);
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });
  };

  const handleSubmit = async (values) => {
    try {
      app.setIsLoading(true);
      const formData = new FormData();
      const data = { ...values };
      delete data._attachments;
      const periods = data.periodsText.split(',');
      data.periods = periods.map((d) => {
        const [from, to] = d.trim().split('-');
        return {from, to};
      });
      formData.append("params", JSON.stringify(data));
      formData.append('removeCompoundPlanEvidence', JSON.stringify(values._attachments.compoundPlanEvidence.removeFiles));
      values._attachments.compoundPlanEvidence.newFiles.forEach((file) => formData.append('newCompoundPlanEvidence', file));
      await app.http(true).post('/master/compound-plan', formData);
      if (params.compoundPlanId === 'new') {
        navigate(`../construct/${params.constructId}/compound-plan`);
      } else {
        const result = await app.http().get(`/master/compound-plan/${params.compoundPlanId}`);
        setData(result);
        setReadOnly(true);
      }
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const breadcrumbs = [
    { name: '現場一覧', path: '../construct' },
    { name: construct ? construct.constructName : '', path: `../construct/${params.constructId}` },
    { name: '配合計画一覧', path: `../construct/${params.constructId}/compound-plan` },
  ];

  const selectList = {
  };

  const submitActions = [
    { name: params.compoundPlanId === 'new' ? '登録' : '更新', handler: handleSubmit },
    { name: 'キャンセル', handler: handleEditItem(false), skipValidation: true, color: 'primary', variant: 'outlined' }
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={'配合計画' + (data.name ? `　${data.name}` : '')} breadcrumbs={breadcrumbs}>
        {readOnly && (
          <div style={{display: 'none'}}>
            <Button size="large" variant="contained" color="info" onClick={handleEditItem(true)}>
              編集
            </Button>
            &nbsp;&nbsp;
            <Button size="large" variant="outlined" color="primary" onClick={handleRemoveItem}>
              削除
            </Button>
          </div>
        )}
        {!readOnly && <CompoundPlan data={data} selectList={selectList} readOnly={false} submitActions={submitActions} />}
      </AppPrimaryArea>
      <Container component="main" maxWidth="md" sx={{ px: 2, my: 2 }}>
        {readOnly && <CompoundPlan data={data} selectList={selectList} />}
      </Container>
    </div>
  );
};

export default CompoundPlanPage;
