import React from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 350
  },
  buttonArea: {
    justifyContent: 'center'
  },
  button: {
    minWidth: 80
  }
}));

const MessageDialog = props => {
  const classes = useStyles();
  const { open, title, body, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: body }} />
        <DialogActions classes={{ root: classes.buttonArea }}>
          <Button
            className={classes.button}
            onClick={() => onClose(true)}
            color="primary"
            variant="outlined"
            autoFocus
          >
            閉じる
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default MessageDialog;
