import React, { useState } from 'react';
import { Button, MenuItem, Select } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { toLineData, downloadTsv } from '../../../common/tsv-file-helper';
import {master} from './compound-plan-master';

const CompoundPlanDownload = ({ data, name }) => {
  const [encoding, setEncoding] = useState('SJIS');

  const handleChange = (event) => setEncoding(event.target.value);
  const handleDownload = (event) => {
    const tsv = [];
    const addLine = (d) => tsv.push(toLineData(d));
    event.preventDefault();

    // create header
    addLine(master.itemLabels);
    // create data
    data.forEach((d) => {
      const lineData = master.itemNames.map((name, i) => {
        let value = d[name];
        if (master.itemTypes[i] === 'number' && value === 0) {
          value = '';
        }
        return value;
      });
      addLine(lineData);
    });

    // execute download
    downloadTsv(tsv, name, encoding);
  };

  return (
    <React.Fragment>
      {data &&
        <div>
          <Select size="small" variant="standard" name="master" onChange={handleChange} value={encoding} sx={{ verticalAlign: 'bottom' }}>
            <MenuItem value="SJIS">Excel用</MenuItem>
            <MenuItem value="UTF8">UTF8</MenuItem>
          </Select>
          &nbsp;&nbsp;
          <Button size="inline" variant="outlined" endIcon={<FileDownload />} onClick={handleDownload}>
            TSVダウンロード
          </Button>
        </div>
      }
    </React.Fragment>
  );
};

export default CompoundPlanDownload;
