import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';

import AppPrimaryArea from '../../components/AppPrimaryArea';
import AppAccordion from '../../components/AppAccordion'
import AppDataTable from '../../components/AppDataTable';
import JCreditReportBasicInfo from './components/CO2ReportBasicInfo';
import JCreditReportDownload from './components/CO2ReportDownload';
import useAppContext from '../../hooks/useAppContext';
import { getFormatDecimal, concreteLocationToLabel, formatTimestampAsDate, concreteTypeToLabel } from '../../data-helper';
import DQRDialog from '../../../common/components/DQRDialog';

const breadcrumbs = [
  { name: 'CO2排出レポート', path: '../co2-report' },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const CO2ReportViewPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();

  const [dqrDialog, setDqrDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const showDqrDialog = async (_id) => {
    setDqrDialog(true);
    try {
      app.setIsLoading(true);
      const result = await app.http().get('/master/precast-concrete', {_id});
      setDialogData(result[0]);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };
  const toDisplayData = (data) => {
    const specFields = [
      { "name": "specNumber", "label": "番号", type: "number" },
      { "name": "datetime", "label": "活動日付", type: "Date", format: formatTimestampAsDate, formatExport: formatTimestampAsDate },
      { "name": "activityID", "label": "活動量", type: "link", format: (d) => `/${app.loginInfo.user.role === 'ConstructorAdmin' ? 'constructor-admin' : 'platform-admin'}/activity/${d}/delivery`, formatExport: (d) => d },
      { "name": "precastConcreteName", "label": "原単位", type: "dqrText", onClick: showDqrDialog }
    ];
    const concreteFields = [
      { "name": "concreteInfo.supplier", "label": "コンクリート調達先", type: "text", width: 180 },
      { "name": "concreteInfo.type", "label": "種別", type: "text", width: 40, format: concreteTypeToLabel, formatExport: concreteTypeToLabel },
      { "name": "concreteInfo.name", "label": "呼び名", type: "text" },
      { "name": "concreteInfo.volume", "label": "コンクリート打設体積(m\u00B3)", type: "number", format: getFormatDecimal(1) },
      { "name": "concreteInfo.location", "label": "打設箇所種別", type: "text", format: concreteLocationToLabel, formatExport: concreteLocationToLabel },
      { "name": "concreteInfo.placement", "label": "打設箇所", type: "text", width: 180 },
    ];
    const totalMaterialProductFields = [
      { "name": "material.materialTotalProductEmission", "label": "材料製造による\u000A排出量合計\u000A(tCO2)", type: "number", format: getFormatDecimal(3), width: 110 },
    ];
    const totalMaterialTransportFields = [
      { "name": "material.materialTotalTransportEmission", "label": "運搬による\u000A排出量合計\u000A(tCO2)", type: "number", format: getFormatDecimal(3), width: 110 },
    ];
    const totalConcreteProductFields = [
      { "name": "concrete.concreteTotalProductEmission", "label": "練混による排出量\u000A(tCO2)", type: "number", format: getFormatDecimal(3), width: 110 },
    ];
    const totalConcreteTransportFields = [
      { "name": "concrete.concreteTotalTransportEmission", "label": "運搬による排出量\u000A(tCO2)", type: "number", format: getFormatDecimal(3), width: 110 },
    ];
    const totalProjectEmissionFields = [
      { "name": "totalProjectEmission", "label": "プロジェクト\u000A排出量合計\u000A(tCO2)", type: "number", format: getFormatDecimal(3), width: 110 },
    ];
  
    const groupDictionary = {
      'concreteInfo': { label: 'コンクリート情報', canCollapse: false, initialCollapse: false },
      'material': { label: '材料製造・採掘に伴うCO2排出量', canCollapse: false, initialCollapse: false },
      'material.production': { label: '材料製造・採掘によるCO2排出量', canCollapse: true, initialCollapse: true },
      'material.transport': { label: '運搬によるCO2排出量', canCollapse: true, initialCollapse: true },
      'concrete': { label: 'コンクリート製造に伴うCO2排出量', canCollapse: false, initialCollapse: false },
      'concrete.production': { label: '練混によるCO2排出量', canCollapse: false, initialCollapse: false },
      'concrete.transport': { label: '運搬によるCO2排出量', canCollapse: false, initialCollapse: false },
    };
  
    const getLabel = (kind, emission, content) => {
      switch (kind) {
        case 'production':
          return `${content}\u000A(tCO2)`;
        case 'transport':
          return `${content}\u000A(tCO2)`;
        default:
          return '(unknown)';
      }
    };
  
    const getFormat = (kind) => {
      switch (kind) {
        case 'production':
        case 'transport':
          return getFormatDecimal(3);
        default:
          return null;
      }
    };
  
    const getFields = (dataHolder, emission, kind, prefix = '') => {
      return dataHolder[emission][kind].map((d) => ({
        name: `${prefix}${emission}.${kind}.${d.type}`,
        label: getLabel(kind, emission, d.content),
        type: 'number',
        width: 110,
        format: getFormat(kind),
      }));
    };
  
    const getObjectValue = (obj, key) => {
      const index = key.lastIndexOf('.');
      const k = key.substring(index + 1);
      return obj[k];
    };
  
    const getArrayValue = (arr, key) => {
      const index = key.lastIndexOf('.');
      const k = key.substring(index + 1);
      const obj = arr.find((d) => d.type === k);
      return obj ? obj.value : 0;
    };
  
    const valueFromObject = (fields, data) => {
      const obj = {};
      fields.forEach((f) => {
        obj[f.name] = getObjectValue(data, f.name);
      });
      return obj;
    };
  
    const valueFromArray = (fields, data, setNaN = false) => {
      const obj = {};
      fields.forEach((f) => {
        obj[f.name] = setNaN ? NaN : getArrayValue(data, f.name);
      });
      return obj;
    };
  
    // build dynamic field information from first emission data
    if (!data.constructInfo || !data.constructInfo.emissions || data.constructInfo.emissions.length < 1) {
      return { groupFields: [], tableFields: [], displayData: [], displayDataBaseline: [] };
    }
    const materialProductionFields = getFields(data.constructInfo.emissions[0], 'material', 'production');
    const materialTransportFields = getFields(data.constructInfo.emissions[0], 'material', 'transport');
    const concreteProductionFields = getFields(data.constructInfo.emissions[0], 'concrete', 'production');
    const concreteTransportFields = getFields(data.constructInfo.emissions[0], 'concrete', 'transport');
  
    // define table fields
    const tableFields = [];
    tableFields.push(...specFields);
    tableFields.push(...concreteFields);
    tableFields.push(...materialProductionFields);
    tableFields.push(...totalMaterialProductFields);
    tableFields.push(...materialTransportFields);
    tableFields.push(...totalMaterialTransportFields);
    // tableFields.push(...concreteProductionFields);
    tableFields.push(...totalConcreteProductFields);
    // tableFields.push(...concreteTransportFields);
    tableFields.push(...totalConcreteTransportFields);
    tableFields.push(...totalProjectEmissionFields);
  
    // define grouping fields
    const createGroupFields = (tableFields, level) => {
      const result = [];
      let lastGroupKey = '';
      tableFields.forEach((d) => {
        const keys = d.name.split('.');
        if (keys.length < level) {
          return;
        }
        const groupKey = keys.slice(0, level).join('.');
        if (d.name === groupKey) {
          result.push({ name: groupKey, label: d.label, canCollapse: false, initialCollapse: false, width: d.width });
        } else if (lastGroupKey !== groupKey) {
          result.push({ name: groupKey, ...groupDictionary[groupKey] });
        }
        lastGroupKey = groupKey;
      });
      return result;
    };
  
    const groupFields = [
      createGroupFields(tableFields, 1),
      createGroupFields(tableFields, 2)
    ];
  
    // convert to display data
    const displayData = [];
    data.constructInfo.emissions.forEach((e, j) => {
      const item = {};
      const isNotIndirect = e.docSubtype === 'DIRECT' || e.docSubtype === 'PLANNED';
      Object.assign(item, valueFromObject(specFields, e));
      item.docSubtype = e.docSubtype;
      item.datetime = e.concreteInfo.datetime;
      item.activityID = isNotIndirect ? '' : e.activityID;
      Object.assign(item, valueFromObject(concreteFields, e.concreteInfo));
      Object.assign(item, valueFromArray(materialProductionFields, e.material.production, isNotIndirect));
      item[totalMaterialProductFields[0].name] = e.material.totalProductEmission;
      Object.assign(item, valueFromArray(materialTransportFields, e.material.transport, isNotIndirect));
      item[totalMaterialTransportFields[0].name] = isNotIndirect ? NaN : e.material.totalTransportEmission;
      Object.assign(item, valueFromArray(concreteProductionFields, e.concrete.production));
      item[totalConcreteProductFields[0].name] = e.concrete.totalProductEmission;
      Object.assign(item, valueFromArray(concreteTransportFields, e.concrete.transport));
      item[totalConcreteTransportFields[0].name] = e.concrete.totalTransportEmission;
      item[totalProjectEmissionFields[0].name] = e.totalProjectEmission;
      item._sx = item[totalProjectEmissionFields[0].name] === 0 ? { background: '#DFEFFF' } : null;
      displayData.push(item);
    });
    const displayDataBaseline = [];
    data.constructInfo.emissions_baseline.forEach((e, j) => {
      const item = {};
      const isNotIndirect = e.docSubtype === 'DIRECT' || e.docSubtype === 'PLANNED';
      Object.assign(item, valueFromObject(specFields, e));
      item.docSubtype = e.docSubtype;
      item.datetime = e.concreteInfo.datetime;
      item.activityID = isNotIndirect ? '' : e.activityID;
      Object.assign(item, valueFromObject(concreteFields, e.concreteInfo));
      Object.assign(item, valueFromArray(materialProductionFields, e.material.production, isNotIndirect));
      item[totalMaterialProductFields[0].name] = e.material.totalProductEmission;
      Object.assign(item, valueFromArray(materialTransportFields, e.material.transport, isNotIndirect));
      item[totalMaterialTransportFields[0].name] = isNotIndirect ? NaN : e.material.totalTransportEmission;
      Object.assign(item, valueFromArray(concreteProductionFields, e.concrete.production));
      item[totalConcreteProductFields[0].name] = e.concrete.totalProductEmission;
      Object.assign(item, valueFromArray(concreteTransportFields, e.concrete.transport));
      item[totalConcreteTransportFields[0].name] = e.concrete.totalTransportEmission;
      item[totalProjectEmissionFields[0].name] = e.totalProjectEmission;
      item._sx = item[totalProjectEmissionFields[0].name] === 0 ? { background: '#DFEFFF' } : null;
      displayDataBaseline.push(item);
    });
    data.totalEmission = data.constructInfo.totalEmission;
    data.totalBaselineEmission = data.constructInfo.totalBaselineEmission;
    data.totalReduction = data.constructInfo.totalReduction;
    return { groupFields, tableFields, displayData, displayDataBaseline, data };
  };

  const [data, setData] = useState(null);
  const [groupFields, setGroupFields] = useState([]);
  const [tableFields, setTableFields] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [displayDataBaseline, setDisplayDataBaseline] = useState([]);

  useEffect(() => {
    const paramsStr = sessionStorage.getItem('CO2ReportSubmitData');
    if (!paramsStr) {
      navigate('../');
    }
    const params = JSON.parse(paramsStr);
    const query = async () => {
      try {
        app.setIsLoading(true);
        const req = { constructID: params.constructIDs[0] };
        const res = await app.http().post('/report/co2', req);
        res.managementInfo = params.managementInfo;
        const result = toDisplayData(res);
        setData(result.data);
        setGroupFields(result.groupFields);
        setTableFields(result.tableFields);
        setDisplayData(result.displayData);
        setDisplayDataBaseline(result.displayDataBaseline);
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatDecimal = getFormatDecimal(3);

  const renderPanel = (label, value) => (
    <Box sx={{ flex: 1, textAlign: 'center', maxWidth: '50%' }}>
      <div style={{ color: '#666666', borderBottom: 'solid #7697A9 1px' }}>{label}</div>
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{value}</span>
        <div style={{ fontSize: '0.9rem', position: 'absolute', bottom: 0, right: -50 }}>tCO2</div>
      </div>
    </Box>
  );

  const hanldeManageActivity = () => navigate('./direct-activity');

  const [echartOption, setEchartOption] = useState({});
  const [echartBaselineOption, setEchartBaselineOption] = useState({});
  useEffect(() => {
    if (displayData.length > 0) {
      getOption();
    }
  }, [displayData]);

  useEffect(() => {
    if (displayDataBaseline.length > 0) {
      getBaselineOption();
    }
  }, [displayDataBaseline]);

  const formatReduce = (value) => Number((value || 0).toFixed(3));
  const getOption = () => {
    const materialTotalProductEmission = formatReduce(displayData.reduce((prev, cur) => prev + formatReduce(cur['material.materialTotalProductEmission']), 0));
    const materialTotalTransportEmission = formatReduce(displayData.reduce((prev, cur) => prev + formatReduce(cur['material.materialTotalTransportEmission']), 0));
    const concreteTotalProductEmission = formatReduce(displayData.reduce((prev, cur) => prev + formatReduce(cur['concrete.concreteTotalProductEmission']), 0));
    const concreteTotalTransportEmission = formatReduce(displayData.reduce((prev, cur) => prev + formatReduce(cur['concrete.concreteTotalTransportEmission']), 0));

    setEchartOption({
      xAxis: {
        type: 'value',
        axisTick:{
          show:false
        },
        axisLine: {
          show: false
        },
        splitLine:{
          show:false
        }
      },
      yAxis: {
        type: 'category',
        axisTick:{
          show:false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          show: false
        }
      },
      legend: {
        orient: 'horizontal',
        left: 0,
        top: 25,
        formatter: function(name) {
          let result = '';
          switch (name) {
            case '材料製造':
              result = `${name}（${materialTotalProductEmission} tCO2）`
              break;
            case '材料運搬':
              result = `${name}（${materialTotalTransportEmission} tCO2）`
              break;
            case '練り混ぜ':
              result = `${name}（${concreteTotalProductEmission} tCO2）`
              break;
            case '生コン運搬':
              result = `${name}（${concreteTotalTransportEmission} tCO2）`
              break;
          }
          return result;
        }
      },
      grid: {
        left: 5
      },
      series: [
        {
          data: [materialTotalProductEmission],
          type: 'bar',
          stack: 'x',
          name: '材料製造'
        },
        {
          data: [materialTotalTransportEmission],
          type: 'bar',
          stack: 'x',
          name: '材料運搬'
        },
        {
          data: [concreteTotalProductEmission],
          type: 'bar',
          stack: 'x',
          name: '練り混ぜ'
        },
        {
          data: [concreteTotalTransportEmission],
          type: 'bar',
          stack: 'x',
          name: '生コン運搬'
        }
      ]
    });
  };

  const getBaselineOption = () => {
    const materialTotalProductEmission = formatReduce(displayDataBaseline.reduce((prev, cur) => prev + formatReduce(cur['material.materialTotalProductEmission']), 0));
    const materialTotalTransportEmission = formatReduce(displayDataBaseline.reduce((prev, cur) => prev + formatReduce(cur['material.materialTotalTransportEmission']), 0));
    const concreteTotalProductEmission = formatReduce(displayDataBaseline.reduce((prev, cur) => prev + formatReduce(cur['concrete.concreteTotalProductEmission']), 0));
    const concreteTotalTransportEmission = formatReduce(displayDataBaseline.reduce((prev, cur) => prev + formatReduce(cur['concrete.concreteTotalTransportEmission']), 0));

    setEchartBaselineOption({
      xAxis: {
        type: 'value',
        axisTick:{
          show:false
        },
        axisLine: {
          show: false
        },
        splitLine:{
          show:false
        }
      },
      yAxis: {
        type: 'category',
        axisTick:{
          show:false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          show: false
        }
      },
      legend: {
        orient: 'horizontal',
        left: 0,
        top: 25,
        itemStyle: {
          opacity: 0.8
        },
        formatter: function(name) {
          let result = '';
          switch (name) {
            case '材料製造':
              result = `${name}（${materialTotalProductEmission} tCO2）`
              break;
            case '材料運搬':
              result = `${name}（${materialTotalTransportEmission} tCO2）`
              break;
            case '練り混ぜ':
              result = `${name}（${concreteTotalProductEmission} tCO2）`
              break;
            case '生コン運搬':
              result = `${name}（${concreteTotalTransportEmission} tCO2）`
              break;
          }
          return result;
        }
      },
      grid: {
        left: 5
      },
      series: [
        {
          data: [materialTotalProductEmission],
          type: 'bar',
          stack: 'x',
          name: '材料製造',
          itemStyle: {
            opacity: 0.8
          },
        },
        {
          data: [materialTotalTransportEmission],
          type: 'bar',
          stack: 'x',
          name: '材料運搬',
          itemStyle: {
            opacity: 0.8
          },
        },
        {
          data: [concreteTotalProductEmission],
          type: 'bar',
          stack: 'x',
          name: '練り混ぜ',
          itemStyle: {
            opacity: 0.8
          },
        },
        {
          data: [concreteTotalTransportEmission],
          type: 'bar',
          stack: 'x',
          name: '生コン運搬',
          itemStyle: {
            opacity: 0.8
          },
        }
      ]
    });
  };

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={data && data.constructInfo ? 'CO2排出レポート　' + data.constructInfo.constructName : '' } breadcrumbs={breadcrumbs}>
        {app.loginInfo.user.role === 'ConstructorAdmin' && <Button variant="outlined" color="primary" onClick={hanldeManageActivity}>打設量調整管理</Button>}
      </AppPrimaryArea>
      <Container component="main" maxWidth={false} sx={{ py: 1, maxWidth: '90vw' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginTop: 'auto', marginBottom: '8px' }}>
            <Container component="main" disableGutters={true} maxWidth={false} sx={{ mt: 1, minWidth: 600, maxWidth: 900 }}>
              <AppAccordion title={data && data.managementInfo ? data.managementInfo.name : ''} border={true}>
                <Box sx={{ px: 2, pb: 2 }}>
                  <JCreditReportBasicInfo data={data} />
                </Box>
              </AppAccordion>
              <div style={{marginTop: 24}}>プロジェクト排出量</div>
              <ReactEcharts option={echartOption} style={{height: 150, width: 800}}/>
              ベースライン排出量
              <ReactEcharts option={echartBaselineOption} style={{height: 150, width: 800}}/>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                {renderPanel('プロジェクト排出量合計', formatDecimal(data ? data.totalEmission : 0))}
                {renderPanel('ベースライン排出量合計', formatDecimal(data ? data.totalBaselineEmission : 0))}
                {renderPanel('排出削減量', formatDecimal(data ? data.totalReduction : 0))}
              </Box>
            </Container>
          </Box>
          <Box sx={{ flex: 1 }}></Box>
          <Box sx={{ marginTop: 'auto', marginBottom: '8px', minWidth: 175 }}>
            <JCreditReportDownload groupFields={groupFields} tableFields={tableFields} data={data} displayData={displayData} />
          </Box>
        </Box>
        <AppDataTable groupFields={groupFields} tableFields={tableFields} data={displayData} maxHeight="calc(100vh - 390px)" />
      </Container>
      <DQRDialog data={dialogData} open={dqrDialog} onClose={() => setDqrDialog(false)} readOnly={true}/>
    </div>
  );
};

export default CO2ReportViewPage;
