import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import useAppContext from '../../common/hooks/useAppContext';
import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppForm from '../../common/components/AppForm';
import { DataQuality } from '../../common/data-helper';

const useStyles = makeStyles(theme => ({
  root: {
  },
  label: {
    color: '#666666',
  },
  required: {
    color: '#00ACFF',
  },
}));

const DeliveryPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const params = useParams();
  const navigate = useNavigate();

  const [construct, setConstruct] = useState(null);
  const selectList = {
    creator: [
      { label: app.loginInfo.user.displayName, value: app.loginInfo.user._id }
    ],
    completeness: DataQuality,
    reliability: DataQuality
  };
  const data = {
    creator: app.loginInfo.user._id,
    completeness: 1,
    reliability: 1,
    status: '0',
  };

  const [file, setFile] = useState(null);
  const formFields = [
    { name: "fileInput", label: "一括入力ファイル", type: "upload", required: true, file, setFile, accept: ".xlsx", multiple: false },
    { name: "creator", label: "活動量入力者", type: "select", required: true, readonly: true },
    { name: "completeness", label: "完全性", type: "select", required: true },
    { name: "reliability", label: "信頼性", type: "select", required: true },
    { name: "status", label: "状況", type: "hidden", readonly: true },
  ];

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const http = app.http();
        const construct = await http.get(`/master/construct/${params.constructId}`);
        setConstruct(construct);
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, [params.constructId, params.deliveryId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values) => {
    if (!file) {
      return
    }
    try {
      app.setIsLoading(true);

      const req = new FormData();
      req.append("file", file[0])
      req.append("constructID", construct._id)
      req.append("constructName", construct.constructName)
      req.append("constructee", construct.corporateName)
      req.append("creator", values.creator)
      req.append("status", values.status)

      const res = await app.http(true).post('/blockchain/slip/check', req);
      app.showConfirmDialog('活動量確認', `活動量の入力を行います。</br>入力値を確認し、 問題なければ 「はい」 を押してください。</br>登録件数: ${res.slip.length}</br>数量: ${res.volume}/㎥`, async (result) => {
        if (result) {
          try {
            app.setIsLoading(true);
            await app.http().post('/blockchain/slip', {slip: res.slip, completeness: values.completeness, reliability: values.reliability});
            navigate(`../construct/${params.constructId}/delivery`);
          } catch (err) {
            app.handleHttpError(err);
          } finally {
            app.setIsLoading(false);
          }
        }
      });
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const breadcrumbs = [
    { name: '施工会社・現場名一覧', path: '../construct' },
    { name: '活動量一覧', path: `../construct/${params.constructId}/delivery` },
  ];

  const submitActions = [
    { name: '登録', handler: handleSubmit, disabled: file ? false : true },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={`活動量入力　${construct ? construct.constructName : ''}`} breadcrumbs={breadcrumbs}>
        <Container component="main" maxWidth="xs" sx={{ ml: 0 }}>
          <AppForm formFields={formFields} data={data} selectList={selectList} submitActions={submitActions} columns={{ xs: 1, md: 1 }} />
        </Container>
      </AppPrimaryArea>
    </div>
  );
};

export default DeliveryPage;
