import React from 'react';
import { makeStyles } from '@mui/styles';
import { DialogContent, Dialog } from '@mui/material';
import * as yup from 'yup';

import useAppContext from '../hooks/useAppContext';
import AppForm from './AppForm';
import { CommonPrecastConcreteField, PrecastConcreteTransportType } from '../../common/data-helper';

const useStyles = makeStyles(theme => ({
  paper: {
  },
  text: {
  },
  buttonArea: {
    justifyContent: 'center'
  },
  button: {
    minWidth: 80
  }
}));

const idSchema = yup.string()
  .required('入力してください')
  .not(['_id', 'name'], '_id の名前に「_id」と「name」を使用することはできません')
const paramSchema = yup.string()
  .required('入力してください')
const formFields = [
  { name: "_id", label: "_id", type: "text", schema: idSchema },
  { name: "baseline", label: "baseline", type: "select", schema: paramSchema },
  { name: "co2", label: "co2", type: "select", schema: paramSchema },
  { name: "concrete", label: "concrete", type: "select", schema: paramSchema },
  { name: "content", label: "content", type: "text", schema: paramSchema },
  { name: "field", label: "field", type: "text", schema: paramSchema },
  { name: "jcredit", label: "jcredit", type: "select", schema: paramSchema },
  { name: "stage", label: "stage", type: "text", schema: paramSchema },
  { name: "title", label: "title", type: "text", schema: paramSchema },
  { name: "transportType", label: "transportType", type: "select", schema: paramSchema },
  { name: "type", label: "type", type: "text", schema: paramSchema },
  { name: "unit", label: "unit", type: "text", schema: paramSchema },
  { name: "value", label: "value", type: "number", schema: paramSchema },
];

const selectList = {
  baseline: CommonPrecastConcreteField,
  co2: CommonPrecastConcreteField,
  concrete: CommonPrecastConcreteField,
  jcredit: CommonPrecastConcreteField,
  transportType: PrecastConcreteTransportType
};

const AddPrecastConcreteFieldDialog = ({ selectedId, open, onClose, onFinish }) => {
  const classes = useStyles();
  const app = useAppContext();

  const handleSubmit = async (values) => {
    try {
      app.setIsLoading(true);
      const param = {_id: selectedId, values}
      await app.http().post('/master/precast-concrete/add-field', param);
      onFinish();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const submitActions = [
    { name: '作成', handler: handleSubmit },
    { name: 'キャンセル', handler: onClose, variant: 'outlined', skipValidation: true, color: 'primary' }
  ];

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogContent>
        <AppForm formFields={formFields} selectList={selectList} submitActions={submitActions} columns={{ xs: 1, md: 3 }} />
      </DialogContent>
    </Dialog>
  );
};

export default AddPrecastConcreteFieldDialog;
