import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppGroupingList from '../../common/components/AppGroupingList';
import useAppContext from '../../common/hooks/useAppContext';
import {formatTimestampAsDate, objectComparator} from '../../common/data-helper';
import {initGroupingList, expandGroupList} from '../../common/group-list-helper';

const breadcrumbs = [
];

const tableFields = [
  { name: "datetime", label: "納入日", type: "date", format:formatTimestampAsDate, xs: true },
  { name: "concreteName", label: "配合計画", type: "text" },
  { name: "placement", label: "打設箇所", type: "text" },
  { name: "totalVolumes", label: "受入量", type: "text", xs: true },
  { name: "totalTracks", label: "受入台数", type: "text", xs: false },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const ActivityListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const updateGroupingList = async (groupListData, index) => {
    try {
      app.setIsLoading(true);
      const activities = await app.http().get('/blockchain/activity', { constructID: groupListData[index]._id});
      activities.sort(objectComparator('datetime', 'desc'));
      activities.forEach((d) => {
        d.totalTracks = d.acceptedTrackNumber + ' / ' + d.shippingTrackNumber + ' 台';
        d.totalVolumes = d.acceptedVolume.toFixed(2) + ' / ' + d.shippingVolume.toFixed(2) + ' m\u00B3';
      });
      const result = expandGroupList(groupListData, index, activities);
      setData(result);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const handleExpandGroup = async (index) => {
    await updateGroupingList(data, index);
  };

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const constructs = await app.http().get('/master/construct', {
          corporateName: app.loginInfo.user.corporateName,
        });
        const groupingListData = initGroupingList(constructs);
        if (groupingListData.length > 0) {
          await updateGroupingList(groupingListData, 0);
        }
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectItem = (item) => () => {
    navigate(`${item._id}/delivery`);
  };

  const rowActions = [
    { name: '伝票詳細', icon: <ChevronRight color="primary" />, handler: handleSelectItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="現場名・活動量一覧" breadcrumbs={breadcrumbs} />
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        <AppGroupingList groupName="constructName" tableFields={tableFields} data={data} onExpand={handleExpandGroup} rowActions={rowActions} />
      </Container>
    </div>
  );
};

export default ActivityListPage;
