import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Button, IconButton, Typography, MenuItem, Select, Checkbox } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';


const useStyles = makeStyles(theme => ({
  rowButton: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  accordionSummary: {
    borderTop: 'none',
    borderRight: 'solid rgba(0, 0, 0, 0.12) 2px !important',
    borderBottom: 'solid rgba(0, 0, 0, 0.12) 2px !important',
    borderLeft: 'solid rgba(0, 0, 0, 0.12) 2px !important',
    '&.Mui-expanded': {
      borderTop: 'solid rgba(0, 0, 0, 0.12) 2px !important',
    }
  },
  selectText: {
    color: '#333333'
  }
}));

const AppGroupingList = ({ tableFields, groupName, data, onExpand, showHeader = false, headerActions = [], rowActions = [] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleClick = (index) => (e) => {
    onExpand(index);
  };

  const displayFields = tableFields.filter((h) => mdUp || h.xs);
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
        <Box>
          {headerActions.map((d) => (
            <Button key={d.name} fullWidth variant="contained" color="info" onClick={d.handler}>
              {d.name}
            </Button>
          ))}
        </Box>
      </Box>
      {data.map((d, i) => (
        <Accordion key={d[groupName]} elevation={0} disableGutters={true} square={true} defaultExpanded={false} expanded={d._expand} onClick={handleClick(i)}>
          <AccordionSummary expandIcon={<ExpandMore color="secondary" />} classes={{ root: classes.accordionSummary }}>
            <Typography>{d[groupName]}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetails }} onClick={(event) => event.stopPropagation()}>
            <TableContainer>
              <Table>
                {showHeader ? (
                  <TableHead>
                    <TableRow>
                      {displayFields.map((h) => (
                        <TableCell key={h.name} size="small" sx={{color: '#666666'}}>{h.label}</TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                ) : (
                  <TableHead />
                )}
                <TableBody>
                  {d._children.map((b, j) => (
                    <TableRow key={j}>
                      {displayFields.map((h) => (
                        <TableCell key={h.name} size="small">
                          {h.format ? h.format(b[h.name]) : b[h.name]}
                        </TableCell>
                      ))}
                      <TableCell size="small" align="right">
                        {rowActions.map((a) => a.icon ? (
                          <IconButton key={a.name} size="small" onClick={a.handler(b)}>{a.icon}</IconButton>
                        ) : (
                          <span className={classes.rowButton} key={a.name} onClick={a.handler(b)}>{a.name}</span>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                  {d._children.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={displayFields.length + 1}>（該当データがありません）</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </React.Fragment>
  );
};

export default AppGroupingList;
