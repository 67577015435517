import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, MenuItem, Select } from '@mui/material';
import { masters, getMasterByName } from './master-data-helper';

import AppDataTable from '../../common/components/AppDataTable';
import MasterMaintenancePrimaryArea from './components/MasterMaintenancePrimaryArea';
import MasterDownload from './components/MasterDownload';
import useAppContext from '../../common/hooks/useAppContext';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const MasterDownloadPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const [name, setName] = useState('Corporate');
  const [master, setMaster] = useState(getMasterByName('Corporate'));
  const [tableFields, setTableFields] = useState([]);
  const [data, setData] = useState([]);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleExecute = async () => {
    try {
      app.setIsLoading(true);
      const req = {
        name: name,
      };
      const res = await app.http().get('/master/maintenance', req);
      const masterDef = getMasterByName(name);
      const fieldsDef = masterDef.itemLabels.map((label, i) => ({
        label: label,
        name: masterDef.itemNames[i],
        type: 'text'
      }));
      setMaster(masterDef);
      setTableFields(fieldsDef);
      setData(res);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <MasterMaintenancePrimaryArea selected="master-download">
        <Container component="main" maxWidth="md" sx={{ py: 4 }}>
          <Box sx={{ display: 'flex' }}>
            <Select
              size="small"
              name="master"
              onChange={handleChange}
              value={name}
              sx={{ mr: 2, width: 240 }}
            >
              {masters.map((s, i) => (
                <MenuItem key={i} value={s.name}>
                  <span className={classes.selectText}>{s.label}</span>
                </MenuItem>
              ))}
            </Select>
            <Button color="info" variant="contained" onClick={handleExecute}>マスタデータ取得</Button>
          </Box>
        </Container>
      </MasterMaintenancePrimaryArea>
      <Container component="main" maxWidth="md" sx={{ py: 1 }}>
        <Box sx={{ float: 'left' }}>
          {data.length > 0 && <p>データ件数: {data.length.toLocaleString()} 件</p>}
        </Box>
        <Box sx={{ my: 1, float: 'right' }}>
          <MasterDownload master={master} data={data} />
        </Box>
        <AppDataTable groupFields={[]} tableFields={tableFields} data={data} maxHeight="calc(100vh - 400px)" />
      </Container>
    </div>
  );
};

export default MasterDownloadPage;
