import React from 'react';
import AppForm from '../../../common/components/AppForm'

const formatKgPerM3 = (d) => d ? `${d} kg/m\u00B3` : '';
const formatPricePerM3 = (d) => `${Number(d).toLocaleString()} 円/m\u00B3`;

const formFields = [
  { name: "section-basic", label: "配合計画基本情報", type: "section" },
  { name: "_id", label: "配合計画ID", type: "text" },
  { name: "number", label: "配合計画書番号", type: "text" },
  { name: "date", label: "配合計画作成日", type: "text" },
  { name: "type", label: "コンクリート種別", type: "text" },
  { name: "name", label: "呼び方", type: "text" },
  { name: "blank", label: "blank", type: "blank" },
  { name: "season", label: "適用季節", type: "text" },
  { name: "periodsText", label: "適用期間", type: "text" },
  { name: "compoundPlanEvidence", label: "配合計画エビデンス", type: "attachment" },
  { name: "section-compound", label: "配合表", type: "section" },
  { name: "compoundTable.cement.type", label: "セメント種別", type: "text" },
  { name: "compoundTable.cement.value", label: "セメント数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.cement.supplier", label: "セメント会社", type: "text" },
  { name: "compoundTable.mineralAdmixture1.content", label: "混和材①", type: "text" },
  { name: "compoundTable.mineralAdmixture1.value", label: "混和材①数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.mineralAdmixture1.supplier", label: "混和材①供給元", type: "text" },
  { name: "compoundTable.mineralAdmixture2.content", label: "混和材②", type: "text" },
  { name: "compoundTable.mineralAdmixture2.value", label: "混和材②数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.mineralAdmixture2.supplier", label: "混和材②供給元", type: "text" },
  { name: "compoundTable.water.value", label: "水量", type: "number", format: formatKgPerM3},
  { name: "blank-water1", label: "blank-water1", type: "blank" },
  { name: "blank-water2", label: "blank-water2", type: "blank" },
  { name: "compoundTable.fineAggregate1.content", label: "細骨材①", type: "text" },
  { name: "compoundTable.fineAggregate1.value", label: "細骨材①数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.fineAggregate1.supplier", label: "細骨材①供給元", type: "text" },
  { name: "compoundTable.fineAggregate2.content", label: "細骨材②", type: "text" },
  { name: "compoundTable.fineAggregate2.value", label: "細骨材②数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.fineAggregate2.supplier", label: "細骨材②供給元", type: "text" },
  { name: "compoundTable.fineAggregate3.content", label: "細骨材③", type: "text" },
  { name: "compoundTable.fineAggregate3.value", label: "細骨材③数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.fineAggregate3.supplier", label: "細骨材③供給元", type: "text" },
  { name: "compoundTable.coarseAggregate1.content", label: "粗骨材①", type: "text" },
  { name: "compoundTable.coarseAggregate1.value", label: "粗骨材①数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.coarseAggregate1.supplier", label: "粗骨材①供給元", type: "text" },
  { name: "compoundTable.coarseAggregate2.content", label: "粗骨材②", type: "text" },
  { name: "compoundTable.coarseAggregate2.value", label: "粗骨材②数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.coarseAggregate2.supplier", label: "粗骨材②供給元", type: "text" },
  { name: "compoundTable.coarseAggregate3.content", label: "粗骨材③", type: "text" },
  { name: "compoundTable.coarseAggregate3.value", label: "粗骨材③数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.coarseAggregate3.supplier", label: "粗骨材③供給元", type: "text" },
  { name: "compoundTable.chemicalAdmixture1.content", label: "混和剤①", type: "text" },
  { name: "compoundTable.chemicalAdmixture1.value", label: "混和剤①数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.chemicalAdmixture1.supplier", label: "混和剤①供給元", type: "text" },
  { name: "compoundTable.chemicalAdmixture2.content", label: "混和剤②", type: "text" },
  { name: "compoundTable.chemicalAdmixture2.value", label: "混和剤②数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.chemicalAdmixture2.supplier", label: "混和剤②供給元", type: "text" },
  { name: "compoundTable.chemicalAdmixture3.content", label: "混和剤③", type: "text" },
  { name: "compoundTable.chemicalAdmixture3.value", label: "混和剤③数量", type: "number", format: formatKgPerM3 },
  { name: "compoundTable.chemicalAdmixture3.supplier", label: "混和剤③供給元", type: "text" },
  // { name: "ratios.waterCementRatio", label: "水セメント比率", type: "text" },
  // { name: "ratios.fineAggregateRatio", label: "細骨材比", type: "text" },
  { name: "section-price", label: "価格", type: "section" },
  { name: "price", label: "コンクリート価格", type: "number", format: formatPricePerM3 },
];

const CompoundTable = ({data}) => <AppForm formFields={formFields} data={data} readonly={true} columns={{ xs: 1, md: 3 }} />

export default CompoundTable;
