import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppTable from '../../common/components/AppTable';
import useAppContext from '../../common/hooks/useAppContext';

const breadcrumbs = [
];

const tableFields = [
  { name: "constructName", label: "現場名", type: "text", xs: true },
  { name: "prefecture", label: "都道府県", type: "text" },
  { name: "address", label: "住所", type: "text" },
];

const useStyles = makeStyles(theme => ({
  root: {}
}));

const ConstructListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const result = await app.http().get('/master/construct');
        setData(result);
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditItem = (item) => () => navigate(`./${item._id}`);

  const headerActions = [
    { name: '新規登録', handler: handleEditItem({ _id: 'new' }) }
  ];
  const rowActions = [
    { name: '詳細', icon: <ChevronRight color="primary" />, handler: handleEditItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="現場一覧" breadcrumbs={breadcrumbs} />
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        <AppTable tableFields={tableFields} data={data} headerActions={headerActions} rowActions={rowActions} />
      </Container>
    </div>
  );
};

export default ConstructListPage;
