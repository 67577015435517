import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { readTextFile } from '../../../common/tsv-file-helper';
import { master, tableFields } from './compound-plan-master';
import AppDataTable from '../../../common/components/AppDataTable';
import useAppContext from '../../../common/hooks/useAppContext';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: '90vw',
    minHeight: '80vh'
  },
  buttonArea: {
    justifyContent: 'center'
  },
  button: {
    minWidth: 80
  }
}));

const parse = (content) => {
  const lines = content.split(/\r\n|\r|\n/);
  const data = [];
  for (let i = 1; i < lines.length; i++) {
    if (!lines[i] || lines[i].trim().length === 0) {
      continue;
    }
    const headers = lines[0].split('\t').map(e => e.replace(new RegExp('"', 'g'), ''));
    if (master.itemLabels.some((label, i) => label !== headers[i])) {
      throw new Error('invalid header names');
    }
    const values = lines[i].split('\t');
    const result = {};
    // general mapping
    values.forEach((d, i) => {
      let value = d;
      if (value.startsWith('=')) {
        value = value.substring(1);
      }
      if (value.startsWith('"') && value.endsWith('"')) {
        value = value.slice(1, -1);
      }
      if (master.itemTypes[i] === 'number') {
        value = Number(value);
      }
      result[master.itemNames[i]] = value;
    });
    // special mapping
    const periods = result.periodsText ? result.periodsText.split(',') : [];
    result.periods = periods.map((d) => {
      const [from, to] = d.trim().split('-');
      return { from, to };
    });
    data.push(result);
  }
  return data;
};

const CompoundPlanImport = ({ onSubmit }) => {
  const classes = useStyles();
  const app = useAppContext();
  const [data, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleClick = () => fileInputRef.current.click();

  const handleFile = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let isValid = true;
      let result = null;
      try {
        const content = await readTextFile(file);
        result = parse(content);
        isValid = result.length > 0;
      } catch (err) {
        console.error(err);
        isValid = false;
      }
      if (isValid) {
        setData(result);
        setDialogOpen(true);
      } else {
        app.showMessageDialog('ファイル読み込みエラー', 'ファイル読み込み中にエラーが発生しました。<br>TSVファイルが正しい配合計画データかを確認してください。');
      }
    }
    event.target.value = '';
  };

  const handleSubmit = async () => {
    await onSubmit(data);
    handleClose();
  };

  const handleClose = () => {
    setDialogOpen(false);
    setData([]);
  };

  return (
    <div>
      <Button variant="contained" color="info" onClick={handleClick}>TSVインポート</Button>
      <input style={{ display: 'none' }} type="file" accept=".tsv" ref={fileInputRef} onChange={handleFile} />
      <Dialog open={dialogOpen} onClose={handleClose} classes={{ paper: classes.paper }}>
        <DialogTitle>配合計画のインポート</DialogTitle>
        <DialogContent>
          <div>{data.length} 件</div>
          <AppDataTable groupFields={[]} tableFields={tableFields} data={data} maxHeight="calc(80vh - 200px)" />
          <DialogActions classes={{ root: classes.buttonArea }}>
            <Button className={classes.button} onClick={handleSubmit} color="info" variant="contained">
              実行
            </Button>
            <Button className={classes.button} onClick={handleClose} color="primary" variant="outlined">
              キャンセル
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompoundPlanImport;
