import React from 'react';
import AppForm from '../../../common/components/AppForm';

const formFields = [
  { name: "constructName", label: "現場名", type: "text" },
  { name: "corporateName", label: "施工主の会社名", type: "text" },
  { name: "blank", label: "blank", type: "blank" },
  { name: "postalCode", label: "郵便番号", type: "text" },
  { name: "prefecture", label: "都道府県", type: "text" },
  { name: "address", label: "住所", type: "text", size: 2 },
  { name: "contact", label: "連絡先", type: "text" },
  { name: "particiationStatus", label: "低炭素社会実行計画への参加状況", type: "text" },
  { name: "savingEntityId", label: "省エネ法特定事業者番号", type: "text" },
  { name: "globalWarmingEntityCode", label: "温対法特定排出者コード", type: "text" },
  { name: "enterDate", label: "入会申込日", type: "text" },
  { name: "buildingConfirmationDate", label: "建築確認済証交付日", type: "text" },
  { name: "completeDate", label: "完了検査済証交付日", type: "text" },
  { name: "buildingConfirmationEvidence", label: "建築確認済証交付エビデンス", type: "attachment" },
];

const Construct = ({data}) => <AppForm formFields={formFields} data={data} readonly={true} columns={{ xs: 1, md: 3 }} />

export default Construct;
