import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import useAppContext from '../../common/hooks/useAppContext';
import AppPrimaryArea from '../../common/components/AppPrimaryArea';

const breadcrumbs = [
  { name: '現場名・伝票一覧', path: `../delivery` },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const DeliveryPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const slip = await app.http().get(`/blockchain/slip/${params.deliveryId}`);
        if (slip != null) {
          if (slip.status === '0') {
            // navigate(`../delivery/accept/${slip._id}`);
            navigate(`../activity/${slip.activity._id}/delivery/${slip._id}/accept`);
          } else {
            // navigate(`../delivery/view/${slip._id}`);
            navigate(`../activity/${slip.activity._id}/delivery/${slip._id}/view`);
          }
        } else {
          setNoData(true);
        }
      } catch (err) {
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          app.setPathAfterLogin(location.pathname);
          app.logout();
        } else {
          app.handleHttpError(err);
        }
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, [params.deliveryId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={noData ? `伝票ID ${params.deliveryId} が見つかりません` : ''} breadcrumbs={breadcrumbs} />
    </div>
  );
};

export default DeliveryPage;
