import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AppPrimaryArea from '../../components/AppPrimaryArea';
import AppTable from '../../components/AppTable';
import useAppContext from '../../hooks/useAppContext';
import { objectComparator } from '../../data-helper';
import AppForm from '../../components/AppForm';

const formFields = [
  { name: "name", label: "プロジェクト名称", type: "text", required: true, size: 2 },
];

const tableFields = [
  { name: "constructName", label: "現場名", type: "text", xs: true },
];

const breadcrumbs = [
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const CO2ReportPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const [data] = useState({
    name: "CO2排出レポート",
  });
  const [formWatchData, setFormWatchData] = useState(null);
  const [constructs, setConstructs] = useState([]);
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const params = {discloseConstruct: true};
        const res = await app.http().get('/master/construct', params);
        res.sort(objectComparator('buildingConfirmationDate'));
        setConstructs(res);
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const watchFormValues = (values) => setFormWatchData(values);

  const handleChangeSelection = (selected) => setSelection(selected);

  const handleSubmit = (event) => {
    // pass parameters by session storage
    const params = {
      constructIDs: selection.map((d) => d._id),
      managementInfo: formWatchData
    };
    sessionStorage.setItem('CO2ReportSubmitData', JSON.stringify(params));
    navigate(`./view`);
  };

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="CO2排出レポート" breadcrumbs={breadcrumbs}>
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        <AppForm formFields={formFields} data={data} watch={watchFormValues} />
        <AppTable tableFields={tableFields} data={constructs} selection="radio" onChangeSelection={handleChangeSelection} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 2 }}>
          <Box>
            <Button variant="contained" color="info" onClick={handleSubmit} disabled={selection.length === 0}>
              レポート表示
            </Button>
          </Box>
        </Box>
      </Container>
      </AppPrimaryArea>
    </div>
  );
};

export default CO2ReportPage;
