import React, { useState } from 'react';
import { Button, MenuItem, Select } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { toLineData, downloadTsv } from '../../../tsv-file-helper';
import { managementInfoFields, unitsTableFields } from './CO2ReportBasicField';

const JCreditReportDownload = ({ groupFields, tableFields, data, displayData }) => {
  const [encoding, setEncoding] = useState('SJIS');

  const handleChange = (event) => setEncoding(event.target.value);

  const handleDownload = (event) => {
    const tsv = [];
    const addLine = (d) => tsv.push(toLineData(d));
    event.preventDefault();

    // managementInfo
    addLine(['■ プロジェクト情報']);
    if (data) {
      managementInfoFields.forEach((d) => {
        const lineData = [d.label, data.managementInfo[d.name]];
        addLine(lineData);
      });
    }
    addLine();

    // units
    addLine(['■ プロジェクトで想定する数値']);
    if (data) {
      data.units.forEach((d) => {
        const lineData = unitsTableFields.map((h) => d[h.name]);
        addLine(lineData);
      });
    }
    addLine();

    // total value
    addLine(['■ CO2排出活動情報サマリー']);
    if (data) {
      addLine(['プロジェクト排出量合計(tCO2)', data.totalEmission]);
      addLine(['ベースライン排出量合計(tCO2)', data.totalBaselineEmission]);
      addLine(['排出削減量(tCO2)', data.totalReduction]);
    }
    addLine();

    // detail tables
    addLine(['■ CO2排出活動情報']);
    const targetGroupFields = groupFields.filter((d) => d.type !== 'link');
    const targetTableFields = tableFields.filter((d) => d.type !== 'link');
    // create headers
    const headers = [];
    targetGroupFields.forEach((arr, i) => {
      const result = targetTableFields.map((h) => {
        const groupField = arr.find((g) => g.name === h.name || h.name.indexOf(`${g.name}.`) !== -1);
        if (groupField) {
          return { name: groupField.name, label: groupField.label };
        } else {
          return { name: h.name, label: h.label };
        }
      });
      headers.push(result);
    });
    headers.push(targetTableFields);
    const alreadyDefined = {};
    headers.forEach((rows) => {
      const lineData = rows.map((h) => {
        if (!alreadyDefined[h.name]) {
          alreadyDefined[h.name] = true;
          return h.label;
        } else {
          return '';
        }
      });
      addLine(lineData);
    });
    // create data
    displayData.forEach((d) => {
      const lineData = targetTableFields.map((h) => {
        let value = '';
        if (h.formatExport) {
          value = h.formatExport(d[h.name]);
        } else if (typeof d[h.name] === 'number' && Number.isNaN(d[h.name])) {
          value = '-';
        } else {
          value = d[h.name];
        }
        return value;
      });
      addLine(lineData);
    });

    // execute download
    downloadTsv(tsv, 'CO2Report', encoding);
  };

  return (
    <div>
      <Select size="small" variant="standard" name="master" onChange={handleChange} value={encoding} sx={{ verticalAlign: 'bottom' }}>
        <MenuItem value="SJIS">Excel用</MenuItem>
        <MenuItem value="UTF8">UTF8</MenuItem>
      </Select>
      &nbsp;&nbsp;
      <Button variant="outlined" endIcon={<FileDownload />} onClick={handleDownload}>
        TSVダウンロード
      </Button>
    </div>
  );
};

export default JCreditReportDownload;
