import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppForm from '../../common/components/AppForm';
import AppTable from '../../common/components/AppTable';
import useAppContext from '../../common/hooks/useAppContext';
import { ConcreteLocation, formatTimestampAsDate, formatVolume, deliveryStatusToLabel, objectComparator } from '../../common/data-helper';

const formFields = [
  // { name: "constructName", label: "現場名", type: "text" },
  // { name: "datetime", label: "打設日", type: "date" },
  { name: 'supplier', label: '製造プラント', type: 'text', readonly: true },
  { name: "totalVolumes", label: "受入量", type: "text", readonly: true },
  { name: "totalTracks", label: "受入台数", type: "text", readonly: true },
  // { name: "concreteName", label: "配合計画", type: "text", readonly: true },
  // { name: "location", label: "打設箇所種別", type: "select", readonly: true },
  // { name: "placement", label: "打設箇所", type: "text", readonly: true },
  // { name: "slipEvidence", label: "伝票エビデンス", type: "attachment" },
];

const tableFields = [
  { name: "shippingDatetime", label: "納入発", type: "datetime", format: formatTimestampAsDate, xs: true },
  { name: "concreteName", label: "配合計画", type: "text" },
  { name: "placement", label: "打設箇所", type: "text" },
  { name: "volume", label: "数量", type: "number", xs: true, format: formatVolume },
  { name: "status", label: "状況", type: "select", format: deliveryStatusToLabel, xs: true },
];

const breadcrumbs = [
  { name: '現場名・活動量一覧', path: '../activity' },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const DeliveryListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const params = useParams();
  const [title, setTitle] = useState('');
  const [activity, setActivity] = useState(null);
  const [selectList] = useState({
    location: ConcreteLocation,
  });
  const [data, setData] = useState([]);

  const retrieveData = async () => {
    const http = app.http();
    const res = await app.http().getActivityWithRelations(params.activityId, false);
    setActivity(res.activity);
    setTitle(res.activity.constructName + '　' + formatTimestampAsDate(res.activity.datetime));
    const slips = await http.get('/blockchain/slip', { constructID: res.activity.constructID, activityID: btoa(params.activityId) });
    slips.sort(objectComparator('shippingDatetime', 'desc'));
    setData(slips);
  };

  useEffect(() => {
    if (params.activityId) {
      const query = async () => {
        try {
          app.setIsLoading(true);
          await retrieveData();
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      };
      query();
    }
  }, [params.activityId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectItem = (item) => () => {
    if (item.status === '0') {
      navigate(`../activity/${params.activityId}/delivery/${item._id}/accept`);
    } else {
      navigate(`../activity/${params.activityId}/delivery/${item._id}/view`);
    }
  };

  const rowActions = [
    { name: '伝票詳細', icon: <ChevronRight color="primary" />, handler: handleSelectItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={"伝票一覧" + (title ? `　${title}` : '')} breadcrumbs={breadcrumbs} />
      <Container component="main" maxWidth="md" sx={{ pt: 4 }}>
        <Box>
          <AppForm formFields={formFields} data={activity} selectList={selectList} readonly />
        </Box>
        <Box sx={{mt: 1}}>
          <AppTable tableFields={tableFields} data={data} rowActions={rowActions} />
        </Box>
      </Container>
    </div>
  );
};

export default DeliveryListPage;
