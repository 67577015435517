import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import AppPrimaryArea from '../../components/AppPrimaryArea';
import AppTable from '../../components/AppTable';
import useAppContext from '../../hooks/useAppContext';
import {formatTimestampAsDateTime, objectComparator} from '../../data-helper';

const breadcrumbs = [
  { name: 'CO2排出レポート', path: '../co2-report' },
  { name: 'CO2排出レポート結果', path: '../co2-report/view' },
];

const tableFields = [
  { name: "datetime", label: "打設日", type: "date", format:formatTimestampAsDateTime, xs: true },
  { name: "volumeStr", label: "打設量", type: "text", xs: true },
  { name: "directUnitStr", label: "原単位", type: "text", },
  { name: "supplier", label: "生コンプラント", type: "text", },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const ActivityListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [construct, setConstruct] = useState({constructName: ''});

  useEffect(() => {
    const paramsStr = sessionStorage.getItem('CO2ReportSubmitData');
    if (!paramsStr) {
      navigate('../');
    }
    const params = JSON.parse(paramsStr);
    const query = async () => {
      try {
        app.setIsLoading(true);
        const construct = await app.http().get(`/master/construct/${params.constructIDs[0]}`);
        setConstruct(construct);
        const req = { constructID: params.constructIDs[0], subtype: 'DIRECT' };
        const activities = await app.http().get('/blockchain/activity', req);
        activities.sort(objectComparator('datetime', 'desc'));
        activities.forEach((d) => {
          d.volumeStr = Number(d.shippingVolume ? d.shippingVolume : 0).toFixed(2) + ' m\u00B3';
          d.directUnitStr = Number(d.directUnit ? d.directUnit : 0).toFixed(3) + ' tCO2/m\u00B3';
        });
        setData(activities);
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBackReport = () => navigate('../co2-report/view');

  const handleSelectItem = (item) => () => navigate(`${item._id}`);

  const headerActions = [
    { name: '新規登録', handler: handleSelectItem({ _id: 'new' }) }
  ];

  const rowActions = [
    { name: '詳細', icon: <ChevronRight color="primary" />, handler: handleSelectItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={`活動量調整管理　${construct.constructName}`} breadcrumbs={breadcrumbs}>
        <Button color="primary" variant="outlined" onClick={handleBackReport}>CO2排出レポート結果の表示に戻る</Button>
      </AppPrimaryArea>
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        <AppTable tableFields={tableFields} data={data} headerActions={headerActions} rowActions={rowActions} />
      </Container>
    </div>
  );
};

export default ActivityListPage;
