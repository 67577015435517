import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Breadcrumbs, Container, Link } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F8FAFB'
  }
}));

const AppPrimaryArea = ({ breadcrumbs, title, children, size }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNavigate = (path) => (event) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth={size ? size : "md"} sx={{ py: 2 }}>
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Box>
            <Breadcrumbs>
              {breadcrumbs.map((d) => (
                <Link key={d.path} underline="hover" color="secondary" href="" onClick={handleNavigate(d.path)}>
                  <ChevronLeft sx={{ mr: 0.5 }} fontSize="inherit" />
                  {d.name}
                </Link>
              ))}
            </Breadcrumbs>
          </Box>
        )}
        <Box sx={{ typography: 'h5', py: 1.5 }}>{title ? title : ''}</Box>
        {children && (
          <Box sx={{ pb: 1.5 }}>{children}</Box>
        )}
      </Container>
    </div>
  );
};

export default AppPrimaryArea;
