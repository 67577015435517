import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import useAppContext from '../../hooks/useAppContext';
import AppPrimaryArea from '../../components/AppPrimaryArea';
import AppForm from '../../components/AppForm';
import { dateToTimestamp } from '../../data-helper';

const breadcrumbs = [
  { name: 'CO2排出レポート', path: '../co2-report' },
  { name: 'CO2排出レポート結果', path: '../co2-report/view' },
  { name: '活動量調整管理', path: '../co2-report/view/direct-activity' },
];

const useStyles = makeStyles(theme => ({
  root: {}
}));

const queryConstruct = async (app, constructId) => app.http().get(`/master/construct/${constructId}`);

const queryConcretePlants = async (app, plantIds) => {
  const res = await app.http().get('/master/concrete-plant');
  const concretePlants = [{ label: '（指定なし）', value: 'NONE' }];
  res.filter((d) => plantIds.includes(d._id)).forEach((d) => concretePlants.push({ label: d.plantName, value: d._id }));
  return concretePlants;
};

const queryActivity = async (app, activityId) => {
  const activity = await app.http().get(`/blockchain/activity/${activityId}`);
  return activity;
};

const directUnitList = [
  { label: '普通ポルトランドセメントコンクリート', value: 0.345 },
  { label: '高炉セメントコンクリート', value: 0.222 },
  { label: 'フライアッシュセメントコンクリート', value: 0.289 },
];

const formFields = [
  { name: "datetime", label: "打設日", type: "datetime", required: true },
  { name: "blank1", label: "blank", type: "blank", size: 2 },
  { name: "acceptedVolume", label: "数量(m\u00B3)", type: "number", required: true, inputProps: { step: "0.25" } },
  { name: "blank2", label: "blank", type: "blank", size: 2 },
  { name: "directUnit", label: "原単位(tCO2/m\u00B3)", type: "number", required: true, inputProps: { step: "0.01" } },
  { name: "directUnitType", label: "代表的な原単位から選択", type: "select", required: false },
  { name: "blank3", label: "blank", type: "blank" },
  { name: "supplierID", label: "製造プラント", type: "select", required: false },
];
const DirectActivityForm = ({ data, selectList, handlers, readOnly, submitActions }) => <AppForm data={data} formFields={formFields} selectList={selectList} handlers={handlers} submitActions={submitActions} />

const formFieldsReadOnly = [
  { name: "datetime", label: "打設日", type: "datetime", required: true },
  { name: "blank1", label: "blank", type: "blank", size: 2 },
  { name: "acceptedVolume", label: "数量(m\u00B3)", type: "number", required: true, inputProps: { step: "0.25" } },
  { name: "blank2", label: "blank", type: "blank", size: 2 },
  { name: "directUnit", label: "原単位(tCO2/m\u00B3)", type: "number", required: true, inputProps: { step: "0.01" } },
  { name: "blank3", label: "blank", type: "blank", size: 2 },
  { name: "supplierID", label: "製造プラント", type: "select", required: false },
];
const ReadOnlyDirectActivityForm = ({ data, selectList, }) => <AppForm data={data} formFields={formFieldsReadOnly} selectList={selectList} readonly={true} />

const DirectActivityPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    supplierID: 'NONE',
    datetime: dateToTimestamp(dayjs().set('hour', 8).set('minute', 0).set('second', 0).set('millisecond', 0)),
  });
  const [construct, setConstruct] = useState({constructName: ''});
  const [concretePlants, setConcretePlants] = useState([{ label: '（指定なし）', value: 'NONE' }]);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    const sessionParamsStr = sessionStorage.getItem('CO2ReportSubmitData');
    if (!sessionParamsStr) {
      navigate('../');
    }
    if (params.activityId) {
      const query = async () => {
        try {
          app.setIsLoading(true);
          const sessionParams = JSON.parse(sessionParamsStr);
          const construct = await queryConstruct(app, sessionParams.constructIDs[0]);
          setConstruct(construct);
          const plants = await queryConcretePlants(app, construct.plantIds);
          setConcretePlants(plants);
          if (params.activityId !== 'new') {
            const activity = await queryActivity(app, params.activityId);
            setData(activity);
          } else {
            setReadOnly(false);
          }
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      };
      query();
    }
  }, [params.activityId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditItem = (edit) => () => {
    if (params.activityId === 'new') {
      navigate('../co2-report/view/direct-activity');
    } else {
      setReadOnly(!edit);
    }
  };

  const handleRemoveItem = () => {
    app.showConfirmDialog('削除確認', `活動量を削除しようとしています。</br>実行してよろしいですか？`, async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          await app.http().delete(`/blockchain/activity/${data._id}`);
          navigate('../co2-report/view/direct-activity');
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });
  };

  const handleSubmit = async (values) => {
    try {
      app.setIsLoading(true);
      const plant = concretePlants.find((d) => d.value === values.supplierID);
      const req = {
        docSubtype: 'DIRECT',
        constructID: construct._id,
        directUnit: values.directUnit,
        datetime: values.datetime,
        acceptedVolume: values.acceptedVolume,
        shippingVolume: values.acceptedVolume,
        supplierID: plant.value,
        supplier: plant.label,
      };
      await app.http().post('/blockchain/activity', req);
      if (params.activityId === 'new') {
        navigate('../co2-report/view/direct-activity');
      } else {
        const result = await queryActivity(app, params.activityId);
        setData(result);
        setReadOnly(true);
      }
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const selectList = {
    supplierID: concretePlants,
    directUnitType: directUnitList,
  };

  const handlers = {
    directUnitType: {
      onChange: (event, values) => {
        const data = {
          ...values,
          directUnit: event.target.value,
          directUnitType: event.target.value,
        };
        setData(data);
      },
    }
  };

  const submitActions = [
    { name: params.activityId === 'new' ? '登録' : '更新', handler: handleSubmit },
    { name: 'キャンセル', handler: handleEditItem(false), variant: 'outlined', skipValidation: true, color: 'primary' }
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={`活動量調整登録　${construct.constructName}`} breadcrumbs={breadcrumbs}>
        {readOnly && (
          <div>
            {/*
              <Button size="large" variant="contained" color="info" onClick={handleEditItem(true)}>
                編集
              </Button>
              &nbsp;&nbsp;
            */}
            <Button size="large" variant="outlined" color="primary" onClick={handleRemoveItem}>
              削除
            </Button>
          </div>
        )}
        {!readOnly &&
          <DirectActivityForm data={data} selectList={selectList} handlers={handlers} submitActions={submitActions} />
        }
      </AppPrimaryArea>
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        {readOnly && <ReadOnlyDirectActivityForm data={data} selectList={selectList} />}
      </Container>
    </div>
  );
};

export default DirectActivityPage;
