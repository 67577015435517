import dayjs from "dayjs";

const ApplicationName = '低炭素建設PF (仮) PoC';

const toLabelValueList = (list, labelProps, valueProps) => list.map((d) => ({ label: d[labelProps], value: d[valueProps] }));

const valueToLabel = (list, value, labelProps = 'label', valueProps = 'value') => {
  let result = value ? value : '';
  if (list) {
    const entry = list.find((d) => d[valueProps] === value);
    if (entry) {
      result = entry[labelProps];
    }
  }
  return result;
};

const labelToValue = (list, label, labelProps = 'label', valueProps = 'value') => {
  let result = null;
  if (list) {
    const entry = list.find((d) => d[labelProps] === label);
    if (entry) {
      result = entry[valueProps];
    }
  }
  return result;
};

const Roles = [
  { label: '建築会社(管理)', value: 'ConstructorAdmin' },
  { label: '建築現場', value: 'ConstructorUser' },
  { label: 'コンクリート製造', value: 'ConcreteMaker' },
  { label: '検証機関', value: 'Authority' },
  { label: 'テスト', value: 'Test' },
];
const roleToLabel = (value) => valueToLabel(Roles, value);
const labelToRole = (label) => labelToValue(Roles, label);

const ConcreteLocation = [
  { label: '基礎・地下', value: 'Underground' },
  { label: '上部', value: 'Ground' },
  { label: '土木構造物', value: 'CivilEngineering' },
];
const concreteLocationToLabel = (value) => valueToLabel(ConcreteLocation, value);
const labelToConcreteLocation = (label) => labelToValue(ConcreteLocation, label);

const DeliveryStatus = [
  { label: '運搬中', value: '0' },
  { label: '受入済', value: '1' },
  { label: '返却', value: '2' },
];
const deliveryStatusToLabel = (value) => valueToLabel(DeliveryStatus, value);
const labelToDeliveryStatus = (label) => labelToValue(DeliveryStatus, label);

const ConcreteType = [
  { label: 'ECM', value: 'ECM' },
  { label: 'BLS', value: 'BLS' },
  { label: '普通', value: 'N' },
  { label: '普通', value: 'BB' },
];
const concreteTypeToLabel = (value) => valueToLabel(ConcreteType, value);
const labelToConcreteType = (label) => labelToValue(ConcreteType, label);

const Judgement = [
  { label: 'NG', value: 0 },
  { label: 'OK', value: 1 },
];
const judementToLabel = (value) => valueToLabel(Judgement, value);
const labelToJudement = (label) => labelToValue(Judgement, label);

const Season = [
  { label: '標準', value: 'Standard' },
  { label: '夏期', value: 'Summer' },
  { label: '冬期', value: 'Winter' },
];
const seasonToLabel = (value) => valueToLabel(Season, value);
const labelToSeason = (label) => labelToValue(Season, label);

const DataQuality = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
];

const CommonPrecastConcreteField = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
];

const PrecastConcreteTransportType = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

const Presence = [
  { label: '無', value: '無' },
  { label: '有', value: '有' },
];

const Disclosure = [
  { label: '非公開', value: 'Private' },
  { label: '公開', value: 'Public' },
];

const Prefecture = [
  { label: '北海道', value: '北海道' },
  { label: '青森県', value: '青森県' },
  { label: '岩手県', value: '岩手県' },
  { label: '宮城県', value: '宮城県' },
  { label: '秋田県', value: '秋田県' },
  { label: '山形県', value: '山形県' },
  { label: '福島県', value: '福島県' },
  { label: '茨城県', value: '茨城県' },
  { label: '栃木県', value: '栃木県' },
  { label: '群馬県', value: '群馬県' },
  { label: '埼玉県', value: '埼玉県' },
  { label: '千葉県', value: '千葉県' },
  { label: '東京都', value: '東京都' },
  { label: '神奈川県', value: '神奈川県' },
  { label: '新潟県', value: '新潟県' },
  { label: '富山県', value: '富山県' },
  { label: '石川県', value: '石川県' },
  { label: '福井県', value: '福井県' },
  { label: '山梨県', value: '山梨県' },
  { label: '長野県', value: '長野県' },
  { label: '岐阜県', value: '岐阜県' },
  { label: '静岡県', value: '静岡県' },
  { label: '愛知県', value: '愛知県' },
  { label: '三重県', value: '三重県' },
  { label: '滋賀県', value: '滋賀県' },
  { label: '京都府', value: '京都府' },
  { label: '大阪府', value: '大阪府' },
  { label: '兵庫県', value: '兵庫県' },
  { label: '奈良県', value: '奈良県' },
  { label: '和歌山県', value: '和歌山県' },
  { label: '鳥取県', value: '鳥取県' },
  { label: '島根県', value: '島根県' },
  { label: '岡山県', value: '岡山県' },
  { label: '広島県', value: '広島県' },
  { label: '山口県', value: '山口県' },
  { label: '徳島県', value: '徳島県' },
  { label: '香川県', value: '香川県' },
  { label: '愛媛県', value: '愛媛県' },
  { label: '高知県', value: '高知県' },
  { label: '福岡県', value: '福岡県' },
  { label: '佐賀県', value: '佐賀県' },
  { label: '長崎県', value: '長崎県' },
  { label: '熊本県', value: '熊本県' },
  { label: '大分県', value: '大分県' },
  { label: '宮崎県', value: '宮崎県' },
  { label: '鹿児島県', value: '鹿児島県' },
  { label: '沖縄県', value: '沖縄県' },
];

const dateToTimestamp = (date) => date ? date.unix() : null;
const timestampToDate = (value) => dayjs.isDayjs(value) ? value : (value ? dayjs.unix(value) : null);
const parseDateStr = (str) => str ? dayjs(str, 'YYYY/MM/DD') : null;
const formatDate = (date) => date ? date.format('YYYY/MM/DD') : null;
const formatDateTime = (date) => date ? date.format('YYYY/MM/DD HH:mm') : null;
const formatDateTimeShort = (date) => date ? date.format('MM/DD HH:mm') : null;
const formatTimestampAsDate = (value) => formatDate(timestampToDate(value));
const formatTimestampAsDateTime = (value) => formatDateTime(timestampToDate(value));
const formatTimestampAsDateTimeShort = (value) => formatDateTimeShort(timestampToDate(value));

const objectComparator = (key, dir = 'asc') => (x, y) => {
  const base = dir === 'desc' ? -1 : 1;
  if (x[key] > y[key]) {
    return base;
  } else if (x[key] < y[key]) {
    return -base;
  }
  return 0;
};

const formatVolume = (value) => (value ? Number(value).toFixed(2) : '0.00') + ' m\u00B3';
const getFormatDecimal = (accuracy, unit) => (value) => (value ? value.toLocaleString(undefined, { minimumFractionDigits: accuracy, maximumFractionDigits: accuracy }) : Number.isNaN(value) ? '-' : Number(0).toFixed(accuracy)) + (unit ? ` ${unit}` : '');
const getFormatPercent = (accuracy) => (value) => (value ? Number(value * 100).toFixed(accuracy) : Number(0).toFixed(accuracy)) + ' %';

const formatCompoundPlan = (c) => {
  return `${concreteTypeToLabel(c.type)} ${c.name} ${c.season}`;
};

export {
  ApplicationName,
  toLabelValueList,
  valueToLabel,
  labelToValue,
  Roles,
  roleToLabel,
  labelToRole,
  ConcreteLocation,
  concreteLocationToLabel,
  labelToConcreteLocation,
  DeliveryStatus,
  deliveryStatusToLabel,
  labelToDeliveryStatus,
  ConcreteType,
  concreteTypeToLabel,
  labelToConcreteType,
  Judgement,
  judementToLabel,
  labelToJudement,
  Season,
  seasonToLabel,
  labelToSeason,
  DataQuality,
  CommonPrecastConcreteField,
  PrecastConcreteTransportType,
  Presence,
  Prefecture,
  Disclosure,
  dateToTimestamp,
  timestampToDate,
  parseDateStr,
  formatDate,
  formatDateTime,
  formatDateTimeShort,
  formatTimestampAsDate,
  formatTimestampAsDateTime,
  formatTimestampAsDateTimeShort,
  objectComparator,
  formatVolume,
  getFormatDecimal,
  getFormatPercent,
  formatCompoundPlan,
};
