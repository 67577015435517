import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppForm from '../../common/components/AppForm';
import AppTable from '../../common/components/AppTable';
import Attachment from '../../common/components/Attachment';
import AppAccordion from '../../common/components/AppAccordion'
import Construct from './components/Construct';
import ConcretePlant from './components/ConcretePlant';
import CompoundTable from './components/CompoundTable';
import useAppContext from '../../common/hooks/useAppContext';
import { ConcreteLocation, formatTimestampAsDate, formatVolume, deliveryStatusToLabel, objectComparator } from '../../common/data-helper';

const formFields = [
  // { name: "constructName", label: "現場名", type: "text" },
  // { name: "datetime", label: "打設日", type: "date" },
  { name: 'supplier', label: '製造プラント', type: 'text', readonly: true },
  { name: "totalVolumes", label: "受入量", type: "text", readonly: true },
  { name: "totalTracks", label: "受入台数", type: "text", readonly: true },
  // { name: "concreteName", label: "配合計画", type: "text", readonly: true },
  // { name: "location", label: "打設箇所種別", type: "select", readonly: true },
  // { name: "placement", label: "打設箇所", type: "text", readonly: true },
  // { name: "slipEvidence", label: "伝票エビデンス", type: "attachment" },
];

const tableFields = [
  { name: "shippingDatetime", label: "納入発", type: "datetime", format: formatTimestampAsDate, xs: true },
  { name: "concreteName", label: "配合計画", type: "text" },
  { name: "placement", label: "打設箇所", type: "text" },
  { name: "volume", label: "数量", type: "number", xs: true, format: formatVolume },
  { name: "status", label: "状況", type: "select", format: deliveryStatusToLabel, xs: true },
];

const breadcrumbs = [
  { name: '現場名・活動量一覧', path: '../activity' },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const DeliveryListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const params = useParams();
  const [title, setTitle] = useState('');
  const [activity, setActivity] = useState(null);
  const [selectList] = useState({
    location: ConcreteLocation,
  });
  const [data, setData] = useState([]);
  const [construct, setConstruct] = useState(null);
  const [compoundPlan, setCompoundPlan] = useState(null);
  const [supplier, setSupplier] = useState(null);

  const retrieveData = async () => {
    const http = app.http();
    const res = await app.http().getActivityWithRelations(params.activityId);
    setActivity(res.activity);
    setConstruct(res.construct);
    setCompoundPlan(res.compoundPlan);
    setSupplier(res.supplier);
    setTitle(formatTimestampAsDate(res.activity.datetime));
    const slips = await http.get('/blockchain/slip', { constructID: res.activity.constructID, activityID: btoa(params.activityId) });
    slips.sort(objectComparator('shippingDatetime', 'desc'));
    setData(slips);
  };

  useEffect(() => {
    if (params.activityId) {
      const query = async () => {
        try {
          app.setIsLoading(true);
          await retrieveData();
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      };
      query();
    }
  }, [params.activityId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeAttachment = async ({ newFiles, removeFiles }) => {
    try {
      app.setIsLoading(true);
      const formData = new FormData();
      const params = {
        _id: activity._id,
        slipEvidence: activity.slipEvidence,
      };
      formData.append("params", JSON.stringify(params));
      newFiles.forEach((file) => formData.append('newFiles', file));
      formData.append('removeFiles', JSON.stringify(removeFiles));
      await app.http(true).put('/blockchain/activity/attachment', formData);
      await retrieveData();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const handleSelectItem = (item) => () => navigate(`./${item._id}`);
  const rowActions = [
    { name: '伝票詳細', icon: <ChevronRight color="primary" />, handler: handleSelectItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={"活動詳細一覧" + (title ? `　${title}` : '')} breadcrumbs={breadcrumbs}>
        {activity && (
          <Box sx={{ mt: -1, mb: 2 }}>
            <Attachment addLabel="伝票等PDF書類添付" files={activity ? activity.slipEvidence : []} currentNewFiles={[]} currentRemoveFiles={[]} onChange={handleChangeAttachment} />
          </Box>
        )}
      </AppPrimaryArea>
      <Container component="main" maxWidth="md" sx={{ my: 2 }}>
        <AppAccordion title={construct ? construct.constructName : ''}>
          <Container component="main" sx={{ mx: 1, my: 1 }}>
            <Construct data={construct} />
          </Container>
        </AppAccordion>
      </Container>
      <hr />
      <Container component="main" maxWidth="md" sx={{ my: 2 }}>
        <AppAccordion title={compoundPlan ? `${compoundPlan.plantName}　${compoundPlan.name}` : ''}>
          <Container component="main" sx={{ mx: 1, my: 1 }}>
            <ConcretePlant data={supplier} />
            <Box sx={{my: 3}} />
            <CompoundTable data={compoundPlan} />
          </Container>
        </AppAccordion>
      </Container>
      <hr />
      <Container component="main" maxWidth="md" sx={{ pt: 4 }}>
        <Box>
          <AppForm formFields={formFields} data={activity} selectList={selectList} readonly />
        </Box>
        <Box sx={{mt: 1}}>
          <AppTable tableFields={tableFields} data={data} rowActions={rowActions} />
        </Box>
      </Container>
    </div>
  );
};

export default DeliveryListPage;
