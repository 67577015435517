// removed items: constructId, corporateId, plantId, plantName, compoundPlanEvidence, ratios.waterCementRatio, ratios.fineAggregateRatio
const master = {
  label: '配合計画マスタ',
  name: 'CompoundPlan',
  itemLabels: ['呼び名', '計画書No', '作成日', '種別(N,BB,ECM,BLS)', '季節', '適用期間', '単価', 'セメント種類', 'セメント供給元', 'セメント数量', '混和材①内容', '混和材①供給元', '混和材①数量', '混和材②内容', '混和材②供給元', '混和材②数量', '水量', '細骨材①内容', '細骨材①供給元', '細骨材①数量', '細骨材②内容', '細骨材②供給元', '細骨材②数量', '細骨材③内容', '細骨材③供給元', '細骨材③数量', '粗骨材①内容', '粗骨材①供給元', '粗骨材①数量', '粗骨材②内容', '粗骨材②供給元', '粗骨材②数量', '粗骨材③内容', '粗骨材③供給元', '粗骨材③数量', '混和剤①内容', '混和剤①供給元', '混和剤①数量', '混和剤②内容', '混和剤②供給元', '混和剤②数量', '混和剤③内容', '混和剤③供給元', '混和剤③数量'],
  itemNames: ['name', 'number', 'date', 'type', 'season', 'periodsText', 'price', 'compoundTable.cement.type', 'compoundTable.cement.supplier', 'compoundTable.cement.value', 'compoundTable.mineralAdmixture1.content', 'compoundTable.mineralAdmixture1.supplier', 'compoundTable.mineralAdmixture1.value', 'compoundTable.mineralAdmixture2.content', 'compoundTable.mineralAdmixture2.supplier', 'compoundTable.mineralAdmixture2.value', 'compoundTable.water.value', 'compoundTable.fineAggregate1.content', 'compoundTable.fineAggregate1.supplier', 'compoundTable.fineAggregate1.value', 'compoundTable.fineAggregate2.content', 'compoundTable.fineAggregate2.supplier', 'compoundTable.fineAggregate2.value', 'compoundTable.fineAggregate3.content', 'compoundTable.fineAggregate3.supplier', 'compoundTable.fineAggregate3.value', 'compoundTable.coarseAggregate1.content', 'compoundTable.coarseAggregate1.supplier', 'compoundTable.coarseAggregate1.value', 'compoundTable.coarseAggregate2.content', 'compoundTable.coarseAggregate2.supplier', 'compoundTable.coarseAggregate2.value', 'compoundTable.coarseAggregate3.content', 'compoundTable.coarseAggregate3.supplier', 'compoundTable.coarseAggregate3.value', 'compoundTable.chemicalAdmixture1.content', 'compoundTable.chemicalAdmixture1.supplier', 'compoundTable.chemicalAdmixture1.value', 'compoundTable.chemicalAdmixture2.content', 'compoundTable.chemicalAdmixture2.supplier', 'compoundTable.chemicalAdmixture2.value', 'compoundTable.chemicalAdmixture3.content', 'compoundTable.chemicalAdmixture3.supplier', 'compoundTable.chemicalAdmixture3.value'],
  itemTypes: ['string', 'string', 'string', 'string', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number', 'string', 'string', 'number'],
};

const tableFields = master.itemNames.map((name, i) => ({name, label: master.itemLabels[i], type: master.itemTypes[i]}));

export {
  master,
  tableFields,
};