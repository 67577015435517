import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const MasterMaintenancePrimaryArea = ({selected, children}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    if (newValue !== selected) {
      navigate(`../data-maintenance/${newValue}`);
    }
  };

  return (
    <div className={classes.root}>
      <Box sx={{ background: '#F8FAFB' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Container component="main" maxWidth="md">
            <Tabs value={selected}  onChange={handleTabChange}>
              <Tab value="master-download" label="マスタデータ取得" id="1" />
              <Tab value="master-maintenance" label="マスタデータ更新" id="2" />
              <Tab value="file-maintenance" label="マスタ添付ファイル管理" id="3" />
              <Tab value="blockchain-maintenance" label="ブロックチェーンデータのクリア" id="4" />
              {/* <Tab value="slip-data-registration" label="伝票データ登録" id="5" /> */}
            </Tabs>
          </Container>
        </Box>
        {children}
      </Box>
    </div>
  );
};

export default MasterMaintenancePrimaryArea;
