import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Typography } from '@mui/material';
import { DeleteForever, Launch } from '@mui/icons-material';
import dayjs from 'dayjs';

import MasterMaintenancePrimaryArea from './components/MasterMaintenancePrimaryArea';
import AppTable from '../../common/components/AppTable';
import useAppContext from '../../common/hooks/useAppContext';
import { formatDateTime } from '../../common/data-helper';

const tableFields = [
  { name: "Key", label: "ファイル名", type: "text" },
  { name: "date", label: "更新日時", type: "datetime", format: formatDateTime },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const FileMaintenancePage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const fileInputRef = useRef();
  const [bucket, setBucket] = useState(null);
  const [data, setData] = useState([]);

  const retrieveFiles = async () => {
    const result = await app.http().get('/master/maintenance/attachment');
    result.list.forEach((d) => d.date = dayjs(d.LastModified));
    setData(result.list);
    setBucket(result.bucket);
  };

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        await retrieveFiles();
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }  
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickAddFile = () => fileInputRef.current.click();

  const handleAddFile = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      try {
        app.setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        await app.http(true).post('/master/maintenance/attachment', formData);
        event.target.value = '';
        await retrieveFiles();
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    }
  };

  const handleOpenFile = (item) => async () => {
    const file = {
      name: item.Key,
      path: `${bucket}/${item.Key}`
    };
    const fileUrl = await app.http().getFileUrl(file);
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  const handleRemoveFile = (item) => async () => {
    app.showConfirmDialog('ファイルの削除確認', `${item.Key} を削除します。よろしいですか？`, async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          await app.http().delete(`/master/maintenance/attachment/${item.Key}`);
          await retrieveFiles();
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });
  };

  const rowActions = [
    { name: '閲覧', icon: <Launch size="small" color="secondary" />, handler: handleOpenFile },
    { name: '削除', icon: <DeleteForever size="small" color="secondary" />, handler: handleRemoveFile },
  ];

  return (
    <div className={classes.root}>
      <MasterMaintenancePrimaryArea selected="file-maintenance">
        <Container component="main" maxWidth="md">
          <Box sx={{ py: 2 }}>
            <Button color="info" variant="contained" onClick={handleClickAddFile}>添付ファイルの追加</Button>
            <input style={{ display: 'none' }} type="file" accept="application/pdf" ref={fileInputRef} onChange={handleAddFile} />
          </Box>
        </Container>
      </MasterMaintenancePrimaryArea>
      <Container component="main" maxWidth="md">
        <Box sx={{ my: 2 }}>
          <Typography variant="h5">添付ファイル一覧</Typography>
          <AppTable tableFields={tableFields} data={data} rowActions={rowActions} />
        </Box>
      </Container>
    </div>
  );
};

export default FileMaintenancePage;
