import * as yup from 'yup';
import { timestampToDate, parseDateStr } from './data-helper';

// convert flat data to json data
const toStructuredJson = (obj) => {
  if (!obj) {
    return obj;
  }
  const keys = Object.getOwnPropertyNames(obj);
  const result = {};
  keys.forEach((k) => {
    const path = k.split('.');
    let parent = result;
    for (let i = 0; i < path.length - 1; i++) {
      if (!parent[path[i]]) {
        parent[path[i]] = {};
      }
      parent = parent[path[i]];
    }
    parent[path[path.length - 1]] = obj[k];
  });
  return result;
};

const toFlatJson = (obj, formFields = []) => {
  if (!obj) {
    return obj;
  }
  const ignore = formFields.filter((d) => d.type === 'attachment' || d.type === 'multiselect').map((d) => d.name);
  const _toFlatJson = (obj, path = '') => {
    const keys = Object.getOwnPropertyNames(obj);
    const result = {};
    keys.forEach((k) => {
      const objKey = path ? `${path}.${k}` : k;
      if (!ignore.includes(objKey) && obj[k] != null && typeof obj[k] === 'object') {
        const items = _toFlatJson(obj[k], objKey);
        Object.assign(result, items);
      } else {
        result[objKey] = obj[k];
      }
    });
    return result;
  };
  return _toFlatJson(obj);
};

const getValueObject = (definition, data) => {
  const values = definition.filter((d) => d.type !== 'blank').reduce((acc, val) => {
    acc[val.name] = val.initialValue !== undefined ? val.initialValue : '';
    return acc;
  }, {});
  if (data) {
    Object.assign(values, toFlatJson(data, definition));
  }
  definition.filter((d) => d.type === 'date' || d.type === 'datetime').forEach((d) => values[d.name] = timestampToDate(values[d.name]));
  definition.filter((d) => d.type === 'datestr').forEach((d) => values[d.name] = parseDateStr(values[d.name]));
  definition.filter((d) => d.type === 'attachment').forEach((d) => {
    if (!values._attachments) {
      values._attachments = {};
    }
    values._attachments[d.name] = { newFiles: [], removeFiles: [] }
  });
  return values;
};

const getValidationSchemaObject = (definition) => {
  const schemas = definition.reduce((acc, val) => {
    if (val.schema) {
      acc[val.name] = val.schema;
    } else {
      if (val.type === 'text' || val.type === 'password' || val.type === 'select') {
        let schema = yup.string();
        if (val.required) {
          schema = schema.required(`${val.label}を入力してください`);
        }
        if (val.regex) {
          schema = schema.matches(val.regex, val.regexMsg ? val.regexMsg : '無効なフォーマットです');
        }
        acc[val.name] = schema;
      } else if (val.type === 'number') {
        let schema = yup.number();
        if (val.required) {
          schema = schema.required(`${val.label}を入力してください`);
        }
        acc[val.name] = schema;
      } else if (val.type === 'date' || val.type === 'datestr') {
        let schema = yup.mixed().test('isValidDate', `正しい日付(YYYY/MM/DD)を指定してください`, (value) => value ? value.isValid() : true);
        if (val.required) {
          schema = schema.required(`${val.label}を入力してください`);
        }
        acc[val.name] = schema;
      } else if (val.type === 'datetime') {
        let schema = yup.mixed().test('isValidDatetime', `正しい日時(YYYY/MM/DD hh:mm)を指定してください`, (value) => value ? value.isValid() : true);
        if (val.required) {
          schema = schema.required(`${val.label}を入力してください`);
        }
        acc[val.name] = schema;
      }
    }
    return acc;
  }, {});
  return yup.object(schemas);
};

export {
  getValueObject,
  getValidationSchemaObject,
  toStructuredJson,
  toFlatJson,
};
