const masters = [
  {
    label: '企業マスタ',
    name: 'Corporate',
    text: 'ID	会社名	参加状況',
    itemLabels: ['ID', '会社名', '参加状況'],
    itemNames: ['_id', 'corporateName', 'active'],
  },
  {
    label: 'ユーザマスタ',
    name: 'User',
    text: 'ID	ログインID	パスワード	ログイン表示名	企業ID	企業名	プラントID	プラント名	ロール',
    itemLabels: ['ID', 'ログインID', 'パスワード', 'ログイン表示名', '企業ID', '企業名', 'プラントID', 'プラント名', 'ロール'],
    itemNames: ['_id', 'loginId', 'password', 'displayName', 'corporateId', 'corporateName', 'plantId', 'plantName', 'role'],
  },
  {
    label: '現場マスタ',
    name: 'Construct',
    text: 'ID	施工会社ID	施工会社名	コンクリートプラント	削減活動を実施する建築物の名称	予定コンクリート打設量(m3)	公開ステータス	郵便番号	都道府県	住所	連絡先電話番号	低炭素社会実行計画への参加状況	省エネ法特定事業者番号	温対法特定排出者コード	入会申込日	入会申込日2年前	建築確認済証交付日	完了検査済証交付日	完了検査等確認書類	認証対象開始日	認証対象終了日	認証申請開始日	認証申請終了日	建築確認済証交付添付ファイル',
    itemLabels: ['ID', '施工会社ID', '施工会社名', 'コンクリートプラント', '削減活動を実施する建築物の名称', '予定コンクリート打設量(m3)', '公開ステータス', '郵便番号', '都道府県', '住所', '連絡先電話番号', '低炭素社会実行計画への参加状況', '省エネ法特定事業者番号', '温対法特定排出者コード', '入会申込日', '入会申込日2年前', '建築確認済証交付日', '完了検査済証交付日', '完了検査等確認書類', '認証対象開始日', '認証対象終了日', '認証申請開始日', '認証申請終了日', '建築確認済証交付添付ファイル'],
    itemNames: ['_id', 'corporateId', 'corporateName', 'plantIds', 'constructName', 'plannedVolume', 'disclosure', 'postalCode', 'prefecture', 'address', 'contact', 'particiationStatus', 'savingEntityId', 'globalWarmingEntityCode', 'enterDate', 'enterDate2YearsAgo', 'buildingConfirmationDate', 'completeDate', 'completeEvidenceName', 'targetStartDate', 'targetEndDate', 'applicationStartDate', 'applicationEndDate', 'buildingConfirmationEvidence'],
  },
  {
    label: '原単位マスタ',
    name: 'Units',
    text: '原単位ID	原単位名フィールド名	原単位名タイプ	原単名コンテンツ	表示名	JCREDIG	CO2排出量	ベースライン	流通ステージ	コンクリートフラグ	輸送タイプ	表示順序	係数数値	単位',
    itemLabels: ['原単位ID', '原単位名フィールド名', '原単位名タイプ', '原単名コンテンツ', '表示名', 'JCREDIG', 'CO2排出量', 'ベースライン', '流通ステージ', 'コンクリートフラグ', '輸送タイプ', '表示順序', '係数数値', '単位'],
    itemNames: ['_id', 'field', 'type', 'content', 'title', 'jcredit', 'co2', 'baseline', 'stage', 'concrete', 'transportType', 'order', 'value', 'unit']
  },
  {
    label: '製造プラントマスタ',
    name: 'ConcretePlant',
    text: '工場ID	企業ID	製造会社名	製造会社住所	認証番号	認証に関わるJIS番号	日本工業規格適合認証書添付ファイル',
    itemLabels: ['工場ID', '企業ID', '製造会社名', '製造会社住所', '認証番号', '認証に関わるJIS番号', '日本工業規格適合認証書添付ファイル'],
    itemNames: ['_id', 'corporateId', 'plantName', 'address', 'certificateNumber', 'JISnumber', 'JISEvidence'],
  },
  {
    label: '測距マスタ',
    name: 'Route',
    text: '輸送ID	輸送マテリアル	輸送元会社・工場名	輸送元住所	輸送先会社・工場名	輸送先住所	距離(km)	測距添付ファイル',
    itemLabels: ['輸送ID', '輸送マテリアル', '輸送元会社・工場名', '輸送元住所', '輸送先会社・工場名', '輸送先住所', '距離(km)', '測距添付ファイル'],
    itemNames: ['_id', 'material', 'fromCompany', 'fromAddress', 'toCompany', 'toAddress', 'distance', 'distanceEvidence'],
  },
  {
    label: '配合計画マスタ',
    name: 'CompoundPlan',
    text: '配合計画ID	呼び名	計画書No	作成日	種別(N,BB,ECM,BLS)	現場ID	企業ID	製造会社ID	製造会社名	配合計画添付ファイル	季節	適用期間	単価	セメント種類	セメント供給元	セメント数量	混和材①内容	混和材①供給元	混和材①数量	混和材②内容	混和材②供給元	混和材②数量	水量	細骨材①内容	細骨材①供給元	細骨材①数量	細骨材②内容	細骨材②供給元	細骨材②数量	細骨材③内容	細骨材③供給元	細骨材③数量	粗骨材①内容	粗骨材①供給元	粗骨材①数量	粗骨材②内容	粗骨材②供給元	粗骨材②数量	粗骨材③内容	粗骨材③供給元	粗骨材③数量	混和剤①内容	混和剤①供給元	混和剤①数量	混和剤②内容	混和剤②供給元	混和剤②数量	混和剤③内容	混和剤③供給元	混和剤③数量	水セメント比率	細骨材比',
    itemLabels: ['配合計画ID', '呼び名', '計画書No', '作成日', '種別(N,BB,ECM,BLS)', '現場ID', '企業ID', '製造会社ID', '製造会社名', '配合計画添付ファイル', '季節', '適用期間', '単価', 'セメント種類', 'セメント供給元', 'セメント数量', '混和材①内容', '混和材①供給元', '混和材①数量', '混和材②内容', '混和材②供給元', '混和材②数量', '水量', '細骨材①内容', '細骨材①供給元', '細骨材①数量', '細骨材②内容', '細骨材②供給元', '細骨材②数量', '細骨材③内容', '細骨材③供給元', '細骨材③数量', '粗骨材①内容', '粗骨材①供給元', '粗骨材①数量', '粗骨材②内容', '粗骨材②供給元', '粗骨材②数量', '粗骨材③内容', '粗骨材③供給元', '粗骨材③数量', '混和剤①内容', '混和剤①供給元', '混和剤①数量', '混和剤②内容', '混和剤②供給元', '混和剤②数量', '混和剤③内容', '混和剤③供給元', '混和剤③数量', '水セメント比率', '細骨材比'],
    itemNames: ['_id', 'name', 'number', 'date', 'type', 'constructId', 'corporateId', 'plantId', 'plantName', 'compoundPlanEvidence', 'season', 'periods', 'price', 'compoundTable.cement.type', 'compoundTable.cement.supplier', 'compoundTable.cement.value', 'compoundTable.mineralAdmixture1.content', 'compoundTable.mineralAdmixture1.supplier', 'compoundTable.mineralAdmixture1.value', 'compoundTable.mineralAdmixture2.content', 'compoundTable.mineralAdmixture2.supplier', 'compoundTable.mineralAdmixture2.value', 'compoundTable.water.value', 'compoundTable.fineAggregate1.content', 'compoundTable.fineAggregate1.supplier', 'compoundTable.fineAggregate1.value', 'compoundTable.fineAggregate2.content', 'compoundTable.fineAggregate2.supplier', 'compoundTable.fineAggregate2.value', 'compoundTable.fineAggregate3.content', 'compoundTable.fineAggregate3.supplier', 'compoundTable.fineAggregate3.value', 'compoundTable.coarseAggregate1.content', 'compoundTable.coarseAggregate1.supplier', 'compoundTable.coarseAggregate1.value', 'compoundTable.coarseAggregate2.content', 'compoundTable.coarseAggregate2.supplier', 'compoundTable.coarseAggregate2.value', 'compoundTable.coarseAggregate3.content', 'compoundTable.coarseAggregate3.supplier', 'compoundTable.coarseAggregate3.value', 'compoundTable.chemicalAdmixture1.content', 'compoundTable.chemicalAdmixture1.supplier', 'compoundTable.chemicalAdmixture1.value', 'compoundTable.chemicalAdmixture2.content', 'compoundTable.chemicalAdmixture2.supplier', 'compoundTable.chemicalAdmixture2.value', 'compoundTable.chemicalAdmixture3.content', 'compoundTable.chemicalAdmixture3.supplier', 'compoundTable.chemicalAdmixture3.value', 'ratios.waterCementRatio', 'ratios.fineAggregateRatio'],
  },
];
export default masters;