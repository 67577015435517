import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppTable from '../../common/components/AppTable';
import { AppSelect } from '../../common/components/AppFormField';
import useAppContext from '../../common/hooks/useAppContext';
import { concreteTypeToLabel, formatCompoundPlan } from '../../common/data-helper';
import { toFlatJson } from '../../common/form-helper';
import CompoundPlanDownload from './components/CompoundPlanDownload';
import CompoundPlanImport from './components/CompoundPlanImport';

const tableFields = [
  { name: "type", label: "コンクリート種別", type: "text", format: concreteTypeToLabel },
  { name: "name", label: "呼び方", type: "text" },
  { name: "season", label: "適用季節", type: "text" },
  { name: "periodsText", label: "適用期間", type: "text" },
  { name: "date", label: "配合計画作成日", type: "text" },
];

const useStyles = makeStyles(theme => ({
  root: {}
}));

const queryConstruct = async (app, constructId) => app.http().get(`/master/construct/${constructId}`);

const queryConcretePlants = async (app, plantIds) => {
  const res = await app.http().get('/master/concrete-plant');
  const filterdConcretePlants = res.filter((d) => plantIds.includes(d._id)).map((d) => ({ label: d.plantName, value: d._id, corporateId: d.corporateId }));
  if (filterdConcretePlants.length === 0) {
    filterdConcretePlants.push({ label: '（プラントが設定されていません）', value: '(none)' })
  }
  return filterdConcretePlants;
};

const queryCompoundPlans = async (app, constructId, selectedPlantId) => {
  let result = [];
  if (selectedPlantId !== '(none)') {
    const res3 = await app.http().get('/master/compound-plan', { constructId: constructId, plantId: selectedPlantId });
    const flatCompoundPlans = res3.map((d) => {
      const flatData = toFlatJson(d);
      flatData.label = formatCompoundPlan(d);
      flatData.periodsText = d.periods.map((x) => x.from + '-' + x.to).join(', ');
      return flatData;
    });
    flatCompoundPlans.sort((x, y) => x.label.localeCompare(y.label));
    result = flatCompoundPlans;
  }
  return result;
};

const CompoundPlanListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const params = useParams();
  const [construct, setConstruct] = useState({});
  const [compoundPlans, setCompoundPlans] = useState([]);
  const [concretePlants, setConcretePlants] = useState([]);
  const [plantId, setPlantId] = useState('');
  const plant = plantId ? concretePlants.find((d) => d.value === plantId) : {};

  useEffect(() => {
    const query = async () => {
      try {
        if (params.constructId) {
          app.setIsLoading(true);
          const res1 = await queryConstruct(app, params.constructId);
          setConstruct(res1);
          const res2 = await queryConcretePlants(app, res1.plantIds);
          setConcretePlants(res2);
          setPlantId(res2[0].value);
          const res3 = await queryCompoundPlans(app, params.constructId, res2[0].value);
          setCompoundPlans(res3);
        }
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, [params.constructId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePlant = async (event) => {
    try {
      setPlantId(event.target.value);
      const result = await queryCompoundPlans(app, params.constructId, event.target.value);
      setCompoundPlans(result);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const handleImport = async (data) => {
    try {
      app.setIsLoading(true);
      const req = {
        constructId: params.constructId,
        corporateId: plant.corporateId,
        plantId: plant.value,
        plantName: plant.label,
        data,
      };
      await app.http().post('/master/compound-plan/import', req);
      app.showMessageDialog('確認', '配合計画のインポート処理が完了しました。', async () => {
        const res = await queryCompoundPlans(app, params.constructId, plantId);
        setCompoundPlans(res);  
      });
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const handleClear = async (data) => {
    app.showConfirmDialog('削除確認', `配合計画の情報をクリアしようとしています。</br>実行してよろしいですか？`, async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          await app.http().delete(`/master/compound-plan/clear`, {
            constructId: params.constructId,
            plantId: plantId,
          });
          const res = await queryCompoundPlans(app, params.constructId, plantId);
          setCompoundPlans(res);
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });

  };

  const handleEditItem = (item) => () => navigate(`./${item._id}`);

  const breadcrumbs = [
    { name: '現場一覧', path: '../construct' },
    { name: construct ? construct.constructName : '', path: `../construct/${params.constructId}` },
  ];

  const rowActions = [
    { name: '詳細', icon: <ChevronRight color="primary" />, handler: handleEditItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={'配合計画一覧' + (construct ? `　${construct.constructName} ` : '')} breadcrumbs={breadcrumbs}>
        <Box sx={{ display: 'flex' }}>
          <CompoundPlanImport onSubmit={handleImport} />
          &nbsp;&nbsp;
          <Button variant="outlined" color="primary" onClick={handleClear}>クリア</Button>
        </Box>
      </AppPrimaryArea>
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ minWidth: 400 }}>
            <AppSelect name="test" label="プラント" value={plantId} selectList={concretePlants} onChange={handleChangePlant} />
          </Box>
          <Box sx={{ flex: 1 }}></Box>
          <Box sx={{ marginTop: 'auto', minWidth: 175 }}>
            <CompoundPlanDownload data={compoundPlans} name={`配合計画_${construct.constructName}_${plant.label}`} />
          </Box>
        </Box>
        <AppTable tableFields={tableFields} data={compoundPlans} rowActions={rowActions} />
      </Container>
    </div>
  );
};

export default CompoundPlanListPage;
