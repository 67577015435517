import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';

import useAppContext from '../hooks/useAppContext';

const useStyles = makeStyles(theme => ({
  container: {
  },
  chip: {
    marginRight: 4
  }
}));

const FileChip = ({ name, onClick, onRemove, readonly }) => {
  const classes = useStyles();
  return (
    <span className={classes.chip}>
      <Chip
        variant="outlined"
        color="primary"
        label={name}
        onClick={onClick}
        onDelete={readonly ? null : onRemove}
      />
    </span>
  );
};

const Attachment = ({ files = [], currentNewFiles = [], currentRemoveFiles = [], readonly = false, onChange, addLabel = "+", maxSize = 3 }) => {
  const classes = useStyles();
  const app = useAppContext();
  const fileInputRef = useRef();
  const [newFiles, setNewFiles] = useState(currentNewFiles);
  const [removeFiles, setRemoveFiles] = useState(currentRemoveFiles);

  useEffect(() => {
    setNewFiles(currentNewFiles);
    setRemoveFiles(currentRemoveFiles);
  }, [currentNewFiles, currentRemoveFiles]);

  const handleClickAddNewFile = () => fileInputRef.current.click();

  const handleAddNewFile = (event) => {
    if (event.target.files.length > 0) {
      const _newFiles = [...newFiles];
      const file = event.target.files[0];
      event.target.value = '';
      _newFiles.push(file);
      setNewFiles(_newFiles);
      if (onChange) {
        onChange({ newFiles: _newFiles, removeFiles });
      }
    }
  };

  const handleRemoveNewFile = (index) => () => {
    const _newFiles = [...newFiles];
    _newFiles.splice(index, 1);
    setNewFiles(_newFiles);
    if (onChange) {
      onChange({ newFiles: _newFiles, removeFiles });
    }
  };

  const handleRemoveExistingFile = (index) => () => {
    const _removeFiles = [...removeFiles];
    _removeFiles.push(files[index]);
    setRemoveFiles(_removeFiles);
    if (onChange) {
      onChange({ newFiles, removeFiles: _removeFiles });
    }
  };

  const handleOpenNewFile = (index) => () => {
    const fileUrl = URL.createObjectURL(newFiles[index]);
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
    URL.revokeObjectURL(fileUrl);
    // To download file instead of opening new tab, use the following code
    // const downLoadLink = document.createElement('a');
    // downLoadLink.download = newFiles[index].name;
    // downLoadLink.href = fileUrl;
    // downLoadLink.dataset.downloadurl = [newFiles[index].type, downLoadLink.download, downLoadLink.href].join(':');
    // downLoadLink.click();
  };

  const handleOpenExistingFile = (index) => async () => {
    const fileUrl = await app.http().getFileUrl(files[index]);
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
    // To download file instead of opening new tab, specify download = true at getFileUrl
  };

  const displayFiles = files ? files.filter((file) => !removeFiles.some((d) => file.path === d.path)) : [];
  const totalSize = displayFiles.length + newFiles.length;
  return (
    <div className={classes.container}>
      {displayFiles.map((d, index) => (
        <FileChip key={index} name={d.name} readonly={readonly} onClick={handleOpenExistingFile(index)} onRemove={handleRemoveExistingFile(index)} />
      ))}
      {newFiles.map((d, index) => (
        <FileChip key={index} name={d.name} readonly={readonly} onClick={handleOpenNewFile(index)} onRemove={handleRemoveNewFile(index)} />
      ))}
      {!readonly && totalSize < maxSize && (
        <span>
          <input style={{ display: 'none' }} type="file" accept="application/pdf" ref={fileInputRef} onChange={handleAddNewFile} />
          <Chip color="primary" label={addLabel} onClick={handleClickAddNewFile} />
        </span>
      )}
    </div>
  );
};

export default Attachment;
