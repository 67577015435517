import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import useAppContext from '../../common/hooks/useAppContext';
import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppForm from '../../common/components/AppForm';
import DQRDialog from '../../common/components/DQRDialog';
import AddPrecastConcreteFieldDialog from '../../common/components/AddPrecastConcreteFieldDialog';
import { AppText } from '../../common/components/AppFormField';

const useStyles = makeStyles(theme => ({
  root: {
  },
  plan: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.secondary.light,
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.primary.light
  },
  planList: {
    overflow: "auto",
    border: "1px solid",
    borderColor: theme.palette.primary.light,
    height: 500,
    marginBottom: 16
  }
}));

const defaultData1 = {
  portland1: 0,
  portland2: 0,
  bb: 0,
  slag: 0,
  flyash: 0,
  limestonePowder: 0,
  otherAdmixture: 0,
  fineAggregate1: 0,
  fineAggregate2: 0,
  coarseAggregate1: 0,
}
const defaultData2 = {
  remicon: 0,
  fuelUsed: 0,
  heatingValue: 0,
  emissionCoff: 0,
  concreteCoff: 0,
  reinforcingBar1: 0,
  formwork1: 0,
}
const EditPrecastConcretePage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const params = useParams();

  const [newPlanName, setNewPlanName] = useState("");
  const [newPlanFlag, setNewPlanFlag] = useState(false);
  const [data1, setData1] = useState(defaultData1);
  const [data1_copy, setData1_copy] = useState(defaultData1);
  const [data2, setData2] = useState(defaultData2);
  const [data2_copy, setData2_copy] = useState(defaultData2);
  const [data_add, setData_add] = useState({});
  const [data_add_copy, setData_add_copy] = useState({});
  const [pcaData, setPCaData] = useState([]);
  const [dialogData, setDialogData] = useState(null);
  const [selectedId, setSelectedId] = useState("");

  const [formFields1, setFormFields1] = useState([
    { name: "portland1", label: "普通ポルトランド", type: "number", required: true, unit: true },
    { name: "portland2", label: "中庸熱ポルトランド", type: "number", required: true, unit: true },
    { name: "bb", label: "高炉セメントB種", type: "number", required: true, unit: true },
    { name: "limestonePowder", label: "石灰石微粉末", type: "number", required: true, unit: true },
    { name: "blank1", label: "blank", type: "blank" },
    { name: "slag", label: "高炉スラグ微粉末", type: "number", required: true, unit: true },
    { name: "flyash", label: "フライアッシュ", type: "number", required: true, unit: true },
    { name: "otherAdmixture", label: "その他の混和剤", type: "number", required: true, unit: true },
    { name: "blank2", label: "blank", type: "blank" },
    { name: "fineAggregate1", label: "砕砂", type: "number", required: true, unit: true },
    { name: "fineAggregate2", label: "砂", type: "number", required: true, unit: true },
    { name: "coarseAggregate1", label: "砕石", type: "number", required: true, unit: true }
  ]);

  const [formFields2, setFormFields2] = useState([
    { name: "fuelUsed", label: "運搬車両の燃料使用原単位", type: "number", required: true, unit: true },
    { name: "heatingValue", label: "運搬車両の燃料単位発熱量", type: "number", required: true, unit: true },
    { name: "emissionCoff", label: "運搬車両の燃料排出係数", type: "number", required: true, unit: true },
    { name: "concreteCoff", label: "コンクリート運搬車両のCO2排出係数", type: "number", required: true, unit: true },
    { name: "blank1", label: "blank", type: "blank" },
    { name: "remicon", label: "生コン練り混ぜ", type: "number", required: true, unit: true },
    { name: "reinforcingBar1", label: "鉄筋製造", type: "number", required: true, unit: true },
    { name: "formwork1", label: "型枠製造", type: "number", required: true, unit: true }
  ]);

  // add any fields
  const [formFields_add, setFormFields_add] = useState([]);
  const [showAddFieldsDialog, setShowAddFieldsDialog] = useState(false);
  const handleFinishAddFieldsDQR = () => {
    setShowAddFieldsDialog(false);
    refreshData();
  };
  const submitActions_add = [
    { name: '項目作成', handler: () => setShowAddFieldsDialog(true), disabled: selectedId ? false : true }
  ];

  const changeData = (id) => {
    setNewPlanFlag(false);
    const result = pcaData.filter(e => e._id === id)[0];
    if (result) {
      setDialogData(result);
      const showData1 = {name: result.name};
      for (const key in defaultData1) {
        showData1[key] = result[key].value;
      }
      setData1(showData1);

      const showData2 = {};
      for (const key in defaultData2) {
        showData2[key] = result[key].value;
      }
      setData2(showData2);

      const showData3 = {};
      const addFormFields = []
      for (const key in result) {
        if (Number(result[key].order) > 17) {
          showData3[key] = result[key].value;
          addFormFields.push({ name: result[key]._id, label: result[key].content + "（" + result[key].unit + "）", type: "number", required: true, unit: true })
        }
      }
      setData_add(showData3);
      setFormFields_add(addFormFields);
      setSelectedId(id);
    } else {
      setSelectedId("");
      setData1(defaultData1);
      setData2(defaultData2);
      setData_add([]);
      setFormFields_add([]);
    }
  };

  const [file, setFile] = useState(null);
  const downloadFile = async () => {
    if (!selectedId) {
      return;
    }

    try {
      app.setIsLoading(true);
      const http = app.http(false, true);
      const res = await http.download('/master/precast-concrete/download', {id: selectedId});
      if (res) {
        let blob = new Blob([res], {type: `application/zip;`});
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = `原単位の管理_${dayjs().format('YYYY-MM-DD_HH-mm-ss')}.zip`;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };
  const formFields3 = [
    { name: "fileInput", label: "根拠となるファイル", type: "upload", file, setFile, accept: "*", multiple: true, size: 2 },
    { name: "fileOutput", label: "一括ダウンロードファイル", type: "button", endIcon: <FileDownload />, onClick: downloadFile, size: 3 }
  ];

  useEffect(async () => {
    try {
      app.setIsLoading(true);
      const http = app.http();
      const res = await http.get('/master/units');
      const data1 = formFields1.map(e => {
        const f = res.filter(r => r._id === e.name)[0]
        if (f) {
          return {name: e.name, label: e.label + "（" + f.unit + "）", type: "number", required: true, unit: true};
        } else {
          return e;
        }
      })
      setFormFields1(data1);
      const data2 = formFields2.map(e => {
        const f = res.filter(r => r._id === e.name)[0]
        if (f) {
          return {name: e.name, label: e.label + "（" + f.unit + "）", type: "number", required: true, unit: true};
        } else {
          return e;
        }
      })
      setFormFields2(data2);
      refreshData();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  }, []);

  const refreshData = async () => {
    try {
      app.setIsLoading(true);
      const http = app.http();
      const pca = await http.get('/master/precast-concrete');
      setPCaData(pca);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedId) {
      changeData(selectedId);
    }
  }, [pcaData]);

  const newPlan = async () => {
    setNewPlanName("");
    setNewPlanFlag(true);
    setSelectedId("");
    setData1(defaultData1);
    setData2(defaultData2);
    setData_add([]);
    setFormFields_add([]);
  };

  const handleSubmit = async () => {
    const data = { ...data1_copy, ...data2_copy };

    if (!newPlanName) {
      return;
    }
    data.name = newPlanName;

    try {
      app.setIsLoading(true);
      const formData = new FormData();
      if (file && file.length > 0) {
        for (let index = 0; index < file.length; index++) {
          formData.append('file', file[index]);
        }
      }

      for (const key in data) {
        formData.append(key, data[key]);
      }
      const http = app.http();
      await http.post('/master/precast-concrete', formData);
      setNewPlanName("");
      setNewPlanFlag(false);
      setFile(null);
      refreshData();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    const data = { ...data1_copy, ...data2_copy, ...data_add_copy };

    if (!selectedId) {
      return;
    }
    data._id = selectedId;

    try {
      app.setIsLoading(true);
      const formData = new FormData();
      if (file && file.length > 0) {
        for (let index = 0; index < file.length; index++) {
          formData.append('file', file[index]);
        }
      }

      for (const key in data) {
        formData.append(key, data[key]);
      }
      const http = app.http();
      await http.put('/master/precast-concrete', formData);
      setFile(null);
      refreshData();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const confirmed = await new Promise((resolve) => {
        app.showConfirmDialog('削除の確認', 'このデータを削除してよろしいですか？', (result) => resolve(result));
      });
      if (!confirmed) {
        return;
      }
      app.setIsLoading(true);
      const http = app.http();
      await http.delete(`/master/precast-concrete/${selectedId}`);
      refreshData();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const submitActions = [
    { name: '登録', handler: handleSubmit, disabled: newPlanFlag ? false : true },
    { name: '更新', handler: handleUpdate, disabled: selectedId ? false : true },
    { name: '削除', handler: handleDelete, disabled: selectedId ? false : true, skipValidation: true, color: 'primary', variant: 'outlined' },
    { name: 'DQR', handler: () => setShowDQRDialog(true), disabled: selectedId ? false : true },
  ];

  const breadcrumbs = [
    { name: '施工会社・現場名一覧', path: '../construct' },
    { name: '活動量一覧', path: `../construct/${params.constructId}/delivery` },
  ];

  const [showDQRDialog, setShowDQRDialog] = useState(false);
  const handleFinishSetDQR = () => {
    setShowDQRDialog(false);
    refreshData();
  };

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="原単位の管理" breadcrumbs={breadcrumbs} size="lg">
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{flex: 1, marginRight: 16}}>
            <div className={classes.planList}>
              {pcaData.map(e => (
                <div key={e._id} className={classes.plan} style={selectedId == e._id ? {background: "#DFEFFF"} : {}} onClick={() => changeData(e._id)}>
                  {e.name}
                </div>
              ))}
            </div>
            <div style={{marginBottom: 16}}>
              <Button variant="contained" color="info" onClick={newPlan} disabled={newPlanFlag} fullWidth={true}>
                新規
              </Button>
            </div>
            {
              newPlanFlag && 
              <AppText
                name="newPlanName"
                label="新しい計画名"
                value={newPlanName}
                required={true}
                onChange={(e) => setNewPlanName(e.target.value)}
              />
            }
          </div>
          <div style={{flex: 2, border: "1px solid #C1C6C8", borderRadius: 8, marginRight: 16, padding: 16}}>
          <AppForm formFields={formFields1} data={data1} columns={{ xs: 1, md: 1}} watch={setData1_copy}/>
          </div>
          <div style={{flex: 2}}>
            <div style={{border: "1px solid #C1C6C8", borderRadius: 8, padding: 16, marginBottom: 16}}>
              <AppForm formFields={formFields2} data={data2} columns={{ xs: 1, md: 1}} watch={setData2_copy}/>
            </div>
            <div style={{border: "1px solid #C1C6C8", borderRadius: 8, padding: 16, marginBottom: 16}}>
              <AppForm formFields={formFields_add} data={data_add} submitActions={submitActions_add} columns={{ xs: 1, md: 1}} watch={setData_add_copy}/>
            </div>
            <AppForm formFields={formFields3} submitActions={submitActions} columns={{ xs: 1, md: 5}}/>
          </div>
        </div>
      </AppPrimaryArea>
      {selectedId && <DQRDialog data={dialogData} open={showDQRDialog} onClose={() => setShowDQRDialog(false)} onFinish={handleFinishSetDQR}/>}
      {selectedId && <AddPrecastConcreteFieldDialog selectedId={selectedId} open={showAddFieldsDialog} onClose={() => setShowAddFieldsDialog(false)} onFinish={handleFinishAddFieldsDQR}/>}
    </div>
  );
};

export default EditPrecastConcretePage;
