import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppTable from '../../common/components/AppTable';
import useAppContext from '../../common/hooks/useAppContext';
import { formatTimestampAsDate, objectComparator, formatVolume } from '../../common/data-helper';

const useStyles = makeStyles(theme => ({
  root: {
  },
  selectText: {
    color: '#333333'
  }
}));

const DeliveryListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const params = useParams();

  const breadcrumbs = [
    { name: '施工会社・現場名一覧', path: '../construct' },
  ];

  const changePrecastConcreteId = async (item, activityID, isPCa) => {
    try {
      app.setIsLoading(true);
      const http = app.http();
      await http.post('/blockchain/slip/update', {activityID, precastConcreteId: item, isPCa});
      refreshData();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };
  const changePCa = async (item, activityID, precastConcreteId) => {
    try {
      app.setIsLoading(true);
      const http = app.http();
      await http.post('/blockchain/slip/update', {activityID, isPCa: item, precastConcreteId});
      refreshData();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };
  const [pcaData, setPCaData] = useState([]);
  const tableFields = [
    { name: "shippingDatetime", label: "納入発", type: "datetime", format: formatTimestampAsDate, xs: true },
    { name: "concreteName", label: "配合計画", type: "text" },
    { name: "placement", label: "打設箇所", type: "text" },
    { name: "volume", label: "数量", type: "number", xs: true, format: formatVolume },
    { name: "supplier", label: "プラント", type: "text" },
    { name: "precastConcreteId", label: "使用原単位", type: "precastConcreteId", onChange: changePrecastConcreteId, data: pcaData },
    { name: "isPCa", label: "PCa", type: "isPCa", onChange: changePCa }
  ];

  const [construct, setConstruct] = useState(null);
  const [data, setData] = useState([]);

  const refreshData = async () => {
    try {
      app.setIsLoading(true);
      const http = app.http();
      const pca = await http.get('/master/precast-concrete');
      setPCaData(pca);
      const construct = await http.get(`/master/construct/${params.constructId}`);
      setConstruct(construct);
      const slips = await http.get('/blockchain/slip', {
        constructID: params.constructId,
        supplierID: app.loginInfo.user.plantId,
      });
      slips.sort(objectComparator('shippingDatetime', 'desc'));
      setData(slips);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  }

  useEffect(() => {
    if (params.constructId) {
      refreshData();
    }
  }, [params.constructId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditItem = (item) => () => navigate(`${item._id}/edit`);

  const handleSelectItem = (item) => () => navigate(`${item._id}/view`);

  const toSettingPage = () => navigate('precastConcrete');

  const rowActions = [
    { name: '活動量詳細', icon: <ChevronRight color="primary" />, handler: handleSelectItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={`活動量一覧 ${construct ? '　' + construct.constructName : ''}`} breadcrumbs={breadcrumbs}>
        <Button size="large" variant="contained" color="info" onClick={handleEditItem({ _id: 'new' })}>
        活動量入力
        </Button>
        <Button sx={{ml: 4}} size="large" color="info" variant="contained" onClick={toSettingPage}>原単位の管理へ</Button>
      </AppPrimaryArea>
      <Container component="main" maxWidth="lg" sx={{ py: 2 }}>
        <AppTable tableFields={tableFields} data={data} rowActions={rowActions}/>
      </Container>
    </div>
  );
};

export default DeliveryListPage;
