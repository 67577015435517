import { getFormatDecimal } from '../../../data-helper';

const managementInfoFields = [
  { name: "name", label: "プロジェクト名称", type: "text", required: true, size: 3 },
];

const unitsTableFields = [
  { name: "title", label: "排出係数・原単位", type: "text" },
  { name: "value", label: "想定値", type: "number", format: getFormatDecimal(4) },
  { name: "unit", label: "単位", type: "text" },
];

export {managementInfoFields, unitsTableFields};