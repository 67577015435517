import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, MenuItem, Select, DialogContent, Dialog } from '@mui/material';

import useAppContext from '../hooks/useAppContext';
import { DataQuality } from '../data-helper';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  head: {
    width: 170,
    height: 50,
    fontWeight: 700,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(41, 125, 83)",
    border: "2px solid #fff"
  },
  secondHead: {
    width: 170,
    height: 50,
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "rgb(214, 234, 175)",
    border: "2px solid #fff"
  },
  thirdHead: {
    width: 30,
    height: 150,
    paddingTop: 5,
    textAlign: "center",
    fontWeight: 700,
    color: "#fff",
    backgroundColor: "rgb(99, 165, 55)",
    borderBottom: "2px solid #fff",
    borderTop: "2px solid #fff"
  },
  content: {
    width: 170,
    height: 50,
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "rgb(235, 245, 215)",
    border: "2px solid #fff"
  },
  paper: {
    maxWidth: "1000px !important"
  },
  select: {
    height: 40,
    width: 100
  },
  buttonArea: {
    justifyContent: 'center'
  },
  button: {
    minWidth: 80
  }
}));



const DQRDialog = ({ data, open, onClose, onFinish, readOnly }) => {
  const classes = useStyles();
  const app = useAppContext();

  const [dialogData, setDialogData] = useState({});
  useEffect(() => {
    let sortData = JSON.parse(JSON.stringify(data));
    delete sortData._id;
    delete sortData.name;
    const sortDataKeyArr = Object.keys(sortData).sort((a, b) => data[a].order - data[b].order);

    const newData = {};
    for (const key of sortDataKeyArr) {
      const dqr = data[key].dqr || [1, 1, 1, 1, 1]
      newData[key] = {
        name: data[key].content,
        dqr
      }
    }
    setDialogData({...newData})
  }, [data]);

  const handleSubmit = async () => {
    try {
      app.setIsLoading(true);
      let param = {}
      for (const key in data) {
        if (key !== "_id" && key !== "name") {
          param[key] = dialogData[key].dqr;
        } else {
          param[key] = data[key];
        }
      }
      const http = app.http();
      await http.post('/master/precast-concrete/dqr', param);
      onFinish();
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const changeData = (key, value, index) => {
    dialogData[key].dqr[index] = value
    setDialogData({...dialogData})
  };

  const renderTable = () => {
    const list = []
    let result1 = 0
    let result2 = 0
    let result3 = 0
    let result4 = 0
    let result5 = 0
    let dataLength = 0
    for (const key in dialogData) {
      dataLength += 1
      result1 += dialogData[key].dqr[0]
      result2 += dialogData[key].dqr[1]
      result3 += dialogData[key].dqr[2]
      result4 += dialogData[key].dqr[3]
      result5 += dialogData[key].dqr[4]
      list.push(
      <div key={key}>
        <div className={classes.head}>{dialogData[key].name === "生コン" ? "生コン練り混ぜ" : dialogData[key].name}</div>
        <div className={classes.secondHead}></div>
        <div className={classes.content}>
          <Select
            size="small"
            className={classes.select}
            onChange={(e) => changeData(key, e.target.value, 0)}
            value={dialogData[key].dqr[0]}
          >
            {DataQuality.map((s, i) => (
              <MenuItem key={i} value={s.value}>
                <span className={classes.selectText}>{s.label}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.content}>
          <Select
            size="small"
            className={classes.select}
            onChange={(e) => changeData(key, e.target.value, 1)}
            value={dialogData[key].dqr[1]}
          >
            {DataQuality.map((s, i) => (
              <MenuItem key={i} value={s.value}>
                <span className={classes.selectText}>{s.label}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.content}>
          <Select
            size="small"
            className={classes.select}
            onChange={(e) => changeData(key, e.target.value, 2)}
            value={dialogData[key].dqr[2]}
          >
            {DataQuality.map((s, i) => (
              <MenuItem key={i} value={s.value}>
                <span className={classes.selectText}>{s.label}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.content}>
          <Select
            size="small"
            className={classes.select}
            onChange={(e) => changeData(key, e.target.value, 3)}
            value={dialogData[key].dqr[3]}
          >
            {DataQuality.map((s, i) => (
              <MenuItem key={i} value={s.value}>
                <span className={classes.selectText}>{s.label}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.content}>
          <Select
            size="small"
            className={classes.select}
            onChange={(e) => changeData(key, e.target.value, 4)}
            value={dialogData[key].dqr[4]}
          >
            {DataQuality.map((s, i) => (
              <MenuItem key={i} value={s.value}>
                <span className={classes.selectText}>{s.label}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>)
    }

    list.push(
      <div key="total">
        <div className={classes.head}>DQR合計</div>
        <div className={classes.secondHead}></div>
        <div className={classes.content}>
          {(result1 / dataLength).toFixed(2)}
        </div>
        <div className={classes.content}>
          {(result2 / dataLength).toFixed(2)}
        </div>
        <div className={classes.content}>
          {(result3 / dataLength).toFixed(2)}
        </div>
        <div className={classes.content}>
          {(result4 / dataLength).toFixed(2)}
        </div>
        <div className={classes.content}>
          {(result5 / dataLength).toFixed(2)}
        </div>
      </div>)
    return list;
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.head}>データ品質指標</div>
          <div className={classes.head}>1-良い</div>
          <div className={classes.head}>2-普通</div>
          <div className={classes.head}>3-悪い</div>
        </div>
        <div className={classes.container}>
          <div style={{display: "flex"}}>
            <div className={classes.thirdHead} style={{paddingTop: 7}}>原単位の指標</div>
            <div>
              <div className={classes.secondHead} style={{width: 140}}>技術的代表性</div>
              <div className={classes.secondHead} style={{width: 140}}>地理的代表性</div>
              <div className={classes.secondHead} style={{width: 140}}>時間的代表性</div>
            </div>
          </div>
          <div>
            <div className={classes.content}>同じ技術のデータ</div>
            <div className={classes.content}>報告年と同一年次の<br/>データ</div>
            <div className={classes.content}>同一国、または<br/>国の下位区分が同じ<br/>データ</div>
          </div>
          <div>
            <div className={classes.content}>類似技術のデータ<br/>(二次データ源に基づく)</div>
            <div className={classes.content}>直近5年未満のデータ</div>
            <div className={classes.content}>同一地域、または<br/>同一小地域のデータ</div>
          </div>
          <div>
            <div className={classes.content}>別技術のデータ、<br/>または技術不明</div>
            <div className={classes.content}>5年以上前のデータ</div>
            <div className={classes.content}>グローバルのデータ、<br/>または地域不明</div>
          </div>
        </div>
        <div className={classes.container}>
          <div style={{display: "flex"}}>
            <div className={classes.thirdHead}>活動量の指標</div>
            <div>
              <div className={classes.secondHead} style={{width: 140, height: 100}}>完全性</div>
              <div className={classes.secondHead} style={{width: 140}}>信頼性</div>
            </div>
          </div>
          <div>
            <div className={classes.content} style={{height: 100}}>全ての関連サイトで<br/>特定期間の<br/>活動量データ</div>
            <div className={classes.content}>測定された<br/>活動量データ</div>
          </div>
          <div>
            <div className={classes.content} style={{height: 100}}>50%未満のサイトで特定<br/>期間、50%以上のサイト<br/>で短期間の活動量データ</div>
            <div className={classes.content}>一部仮定を含む<br/>活動量データ</div>
          </div>
          <div>
            <div className={classes.content} style={{height: 100}}>50%未満のサイトで短期<br/>間の活動量データ、<br/>または不明</div>
            <div className={classes.content}>財務データ、または<br/>不適格なデータ</div>
          </div>
        </div>

        <div style={{display: "flex"}}>
          <div>
            <div className={classes.head}>データ品質指標</div>
            <div className={classes.secondHead}>PCF合計に対するGHG貢献</div>
            <div className={classes.secondHead}>技術的代表性</div>
            <div className={classes.secondHead}>地理的代表性</div>
            <div className={classes.secondHead}>時間的代表性</div>
            <div className={classes.secondHead}>完全性</div>
            <div className={classes.secondHead}>信頼性</div>
          </div>
          <div style={{display: "flex", overflow: "auto"}}>
            {renderTable()}
          </div>
        </div>

        <div className={classes.container} style={{marginTop: 20}}>
          <Button
            className={classes.button}
            style={{marginRight: 24}}
            onClick={() => onClose(true)}
            color="primary"
            variant="outlined"
            autoFocus
          >
            閉じる
          </Button>
          <Button
            className={classes.button}
            onClick={handleSubmit}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={readOnly}
          >
            更新
          </Button>
        </div>
        
      </DialogContent>
    </Dialog>
  );
};

export default DQRDialog;
