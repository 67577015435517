import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AppPrimaryArea from '../../components/AppPrimaryArea';
import AppAccordion from '../../components/AppAccordion'
import AppDataTable from '../../components/AppDataTable';
import JCreditReportBasicInfo from './components/JCreditReportBasicInfo';
import JCreditReportDownload from './components/JCreditReportDownload';
import useAppContext from '../../hooks/useAppContext';
import { getFormatDecimal } from '../../data-helper';
import { toDisplayData } from './jcredit-report-helper';

const breadcrumbs = [
  { name: 'J-クレジット申請レポート', path: '../jcredit-report' },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const JCreditReportViewPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [groupFields, setGroupFields] = useState([]);
  const [tableFields, setTableFields] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    const paramsStr = sessionStorage.getItem('JCreditReportSubmitData');
    if (!paramsStr) {
      navigate('../');
    }
    const params = JSON.parse(paramsStr);
    const query = async () => {
      try {
        app.setIsLoading(true);
        const res = await app.http().post('/report/jcredit', params);
        // if (res.constructInfo.length < 10) {
        //   res.constructInfo = [...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo];
        //   res.constructInfo = [...res.constructInfo, ...res.constructInfo];
        // }
        const result = toDisplayData(res);
        setData(result.data);
        setGroupFields(result.groupFields);
        setTableFields(result.tableFields);
        setDisplayData(result.displayData);
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatDecimal = getFormatDecimal(3);

  const renderPanel = (label, value) => (
    <Box sx={{ flex: 1, textAlign: 'center', maxWidth: '33.3%' }}>
      <div style={{color: '#666666', borderBottom: 'solid #7697A9 1px'}}>{label}</div>
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{value}</span>
        <div style={{ fontSize: '0.9rem', position: 'absolute', bottom: 0, right: -50 }}>tCO2</div>
      </div>
    </Box>
  );

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="J-クレジット申請レポート" breadcrumbs={breadcrumbs} />
      <Container component="main" maxWidth={false} sx={{ py: 1, maxWidth: '90vw' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginTop: 'auto', marginBottom: '8px' }}>
            <Container component="main" disableGutters={true} maxWidth={false} sx={{ mt: 1, minWidth: 600, maxWidth: 900 }}>
              <AppAccordion title={data && data.managementInfo ? data.managementInfo.name : ''} border={true}>
                <Box sx={{ px: 2, pb: 2 }}>
                  <JCreditReportBasicInfo data={data} />
                </Box>
              </AppAccordion>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                {renderPanel('プロジェクト排出量合計', formatDecimal(data ? data.totalEmission : 0))}
                {renderPanel('ベースライン排出量合計', formatDecimal(data ? data.totalBaselineEmission : 0))}
                {renderPanel('排出削減量', formatDecimal(data ? data.totalReduction : 0))}
              </Box>
            </Container>
          </Box>
          <Box sx={{ flex: 1 }}></Box>
          <Box sx={{ marginTop: 'auto', marginBottom: '8px', minWidth: 175 }}>
            <JCreditReportDownload groupFields={groupFields} tableFields={tableFields} data={data} displayData={displayData} />
          </Box>
        </Box>
        <AppDataTable groupFields={groupFields} tableFields={tableFields} data={displayData} maxHeight="calc(100vh - 330px)" />
      </Container>
    </div>
  );
};

export default JCreditReportViewPage;
