import React from 'react';
import { Box, Typography } from '@mui/material';
import AppForm from '../../../components/AppForm'
import AppTable from '../../../components/AppTable'
import {managementInfoFields, unitsTableFields} from './JCreditReportBasicField';

const formFields = managementInfoFields;
const tableFields = unitsTableFields;

const JCreditReportBasicInfo = ({ data }) => {
  const { managementInfo, units } = data ? data : { managementInfo: {}, units: [] };
  return (
    <div>
      <Box sx={{ pb: 1 }}>
        <Typography variant="subtitle1">運営・管理者情報</Typography>
      </Box>
      <Box sx={{ px: 1 }}>
        <AppForm formFields={formFields} data={managementInfo} readonly={true} columns={{ xs: 1, md: 3 }} />
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography variant="subtitle1">原単位情報</Typography>
      </Box>
      <Box sx={{ px: 1 }}>
        <AppTable tableFields={tableFields} data={units} />
      </Box>
    </div>
  );
};

export default JCreditReportBasicInfo;
