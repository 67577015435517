import React from 'react';
import AppForm from '../../../common/components/AppForm'

const formFields = [
  { name: "section-basic", label: "プラント情報", type: "section" },
  // { name: "_id", label: "工場ID", type: "text" },
  { name: "plantName", label: "プラント名", type: "text" },
  { name: "address", label: "住所", type: "text", size: 2 },
  { name: "certificateNumber", label: "認証番号", type: "text" },
  { name: "JISnumber", label: "認証に関わるJIS番号", type: "text" },
  { name: "JISEvidence", label: "日本工業規格適合認証書添付ファイル", type: "attachment" },
];

const ConcretePlant = ({data}) => <AppForm formFields={formFields} data={data} readonly={true} columns={{ xs: 1, md: 3 }} />

export default ConcretePlant;
