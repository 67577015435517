import React from 'react';
import { Box, Typography } from '@mui/material';
import AppForm from '../../../components/AppForm'
import AppTable from '../../../components/AppTable'
import {managementInfoFields, unitsTableFields} from './CO2ReportBasicField';

const formFields = managementInfoFields;
const tableFields = unitsTableFields;

const CO2ReportBasicInfo = ({ data }) => {
  const { managementInfo, units } = data ? data : { managementInfo: {}, units: [] };
  return (
    <div>
      <Box sx={{ pb: 1 }}>
        <Typography variant="subtitle1">プロジェクト情報</Typography>
      </Box>
      <Box sx={{ px: 1 }}>
        <AppForm formFields={formFields} data={managementInfo} readonly={true} columns={{ xs: 1, md: 3 }} />
      </Box>
      {
        units.map((unit, i) => (
          <div key={i}>
            <Box sx={{ pt: 1 }}>
              <Typography variant="subtitle1">{unit.name}</Typography>
            </Box>
            <Box sx={{ px: 1 }}>
              <AppTable tableFields={tableFields} data={unit.units} />
            </Box>
          </div>
        ))
      }
    </div>
  );
};

export default CO2ReportBasicInfo;
