import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';

import useAppContext from '../common/hooks/useAppContext';
import AppForm from '../common/components/AppForm';

const useStyles = makeStyles(theme => ({
  background: {
    height: "100vh",
    background: "rgb(248, 250, 251)",
    overflow: "auto"
  },
  space6: {
    marginTop: theme.spacing(6),
  },
  loginContainer: {
    marginLeft: "auto !important",
    background: "#fff",
    padding: "24px 150px",
    borderRadius: 8
  },
  loginTitle: {
    fontSize: "24px !important",
    fontWeight: "700 !important",
    marginBottom: "24px !important"
  },
}));

const loginFields = [
  { name: "userCode", label: "アプリケーション内の現在のコードを入力します", type: "text", required: true, },
  { name: "friendlyDeviceName", label: "デバイス名を入力します(任意)", type: "text" },
];

const SetupMFAPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const data = values;
      data.session = localStorage.getItem('Session')
      app.setIsLoading(true);
      await app.http().post('/auth/setup-mfa', data);
      navigate("/verify-code");
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const submitActions = [
    { name: 'ログイン', handler: handleSubmit, fullWidth: true },
  ];

  return (
    <div className={classes.background}>
      <div className={classes.space6} />
      <Container component="main" maxWidth="sm" className={classes.loginContainer}>
        <div style={{padding: "24px 100px"}}>
          <Typography align="center" className={classes.loginTitle}>
          認証アプリMFAを設定します
          </Typography>

          <Typography align="left" style={{marginBottom: 24}}>
          １．ダウンロードした認証アプリを開きます。
          </Typography>

          <Typography align="left" style={{marginBottom: 24}}>
          ２．カメラで画面のQRコードをスキャンしますアプリにカメラを使用する許可が必要な場合があります。
          </Typography>

          <div style={{display: "flex", justifyContent: "center", marginBottom: 24}}>
            <QRCode value={localStorage.getItem('qrcodeStr')} />
          </div>

          <Typography align="left" style={{marginBottom: 24}}>
          ３．QRコードがスキャンできない場合は、secret keyを認証アプリに手動入力します。
          </Typography>
          <div style={{marginBottom: 24}}>secret key: {localStorage.getItem('SecretCode')}</div>

          <AppForm formFields={loginFields} submitActions={submitActions}  columns={{ xs: 1, md: 1 }} />
        </div>
      </Container>
    </div>
  );
};

export default SetupMFAPage;
