import React from 'react';
import { makeStyles } from '@mui/styles';
import {Dialog, LinearProgress, Typography} from '@mui/material';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: '80vw',
    maxWidth: '80vw',
  },
  content: {
    padding: 32,
    opacity: 1,
  },
  spacer: {
    height: 16
  }
}));

const ProgressDialog = ({open, msg, progress}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} classes={{paper: classes.paper}}>
      <div className={classes.content}>
        <Typography variant="body" align="left">{msg}</Typography>
        <div className={classes.spacer} />
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </Dialog>
  );
};

export default ProgressDialog;
