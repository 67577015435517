import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Hidden, Divider } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

import useAppContext from '../hooks/useAppContext';
import { ApplicationName } from '../data-helper';
import ChangePasswordDialog from './ChangePasswordDialog';

const useStyles = makeStyles(theme => ({
  appBar: {
    padding: `0px ${theme.spacing(2)}`,
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  name: {
    padding: '0 8px',
  },
}));

const AppHeader = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPasswordChangeDialog, setShowPasswordChangeDialog] = useState(false);

  const handleDrawerOpen = () => app.setIsDrawerOpen(!app.isDrawerOpen);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = async () => {
    setAnchorEl(null);
    await app.logout();
    navigate('/login');
  };

  const handleShowPasswordChangeDialog = (show) => () => {
    setShowPasswordChangeDialog(show);
    setAnchorEl(null);
  };

  const user = app.loginInfo ? app.loginInfo.user : {};
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense">
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
          <MenuIcon color="primary.light" />
        </IconButton>
        <Hidden smDown>
          <Typography variant="body1">
            {ApplicationName}
          </Typography>
        </Hidden>
        <div className={classes.grow} />
        <Typography variant="body1" className={classes.name}>
          {user.displayName}
        </Typography>
        <div>
          <IconButton aria-label="account" aria-controls="menu-appbar" onClick={handleMenu}>
            <AccountCircle color="primary" sx={{fill: "#C1C6C8"}}/>
          </IconButton>
          <Menu open={!!anchorEl} id="menu-appbar" anchorEl={anchorEl} keepMounted onClose={handleClose}>
            <MenuItem onClick={handleShowPasswordChangeDialog(true)}>パスワード変更</MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
          </Menu>
        </div>
      </Toolbar>
      <ChangePasswordDialog open={showPasswordChangeDialog} onClose={handleShowPasswordChangeDialog(false)} />
    </AppBar>
  );
};

export default AppHeader;
