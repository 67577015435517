import { getFormatDecimal } from '../../../data-helper';

const managementInfoFields = [
  { name: "name", label: "プロジェクト名称", type: "text", required: true, size: 2 },
  { name: "method", label: "プロジェクト方法論", type: "text", required: true },
  { name: "administrator", label: "運営管理者", type: "text", required: true },
  { name: "creator", label: "活動の実施者", type: "text", required: true },
  { name: "dataAdministrator", label: "データ管理責任者", type: "text", required: true },
  { name: "monitoring", label: "モニタリング担当者", type: "text", required: true },
];

const unitsTableFields = [
  { name: "title", label: "排出係数・原単位", type: "text" },
  { name: "value", label: "想定値", type: "number", format: getFormatDecimal(4) },
  { name: "unit", label: "単位", type: "text" },
];

export {managementInfoFields, unitsTableFields};