import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos, Launch } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  table: {},
  th: {
    color: '#ffffff !important',
    backgroundColor: '#42475F !important',
    borderBottom: 'solid #ffffff 1px !important',
    borderRight: 'solid #ffffff 1px !important',
    fontSize: '0.8rem !important',
    lineHeight: '1.0rem !important',
    padding: '8px !important',
    whiteSpace: 'pre-wrap',
  },
  thCollapse: {
    color: '#ffffff !important',
    backgroundColor: '#42475F !important',
    borderBottom: 'solid #ffffff 1px !important',
    borderRight: 'solid #ffffff 1px !important',
    fontSize: '0.8rem !important',
    lineHeight: '1.0rem !important',
    padding: '4px !important',
    whiteSpace: 'pre-wrap',
  },
  td: {
    fontSize: '0.8rem !important',
    lineHeight: '1.0rem !important',
    padding: '8px !important',
  },
  tdCollapse: {
    fontSize: '0.8rem !important',
    lineHeight: '1.0rem !important',
    padding: '4px !important',
  },
  tr: {
    '&:nth-child(2n)': {
      backgroundColor: '#F8FAFB'
    },
  },
  icon: {
    verticalAlign: 'bottom',
    cursor: 'pointer',
    fontSize: '1.2rem !important',
    fontWeight: 'bolder'
  },
}));

const checkCollapseField = (collapse, name) => {
  let isCollapse = false;
  let isCollapseExact = false;
  let collapseName = null;
  for (let i = 0; i < collapse.length; i++) {
    if (collapse[i] === name) {
      isCollapse = true;
      collapseName = collapse[i];
      isCollapseExact = true;
      break;
    } else if (name.indexOf(`${collapse[i]}.`) !== -1) {
      isCollapse = true;
      collapseName = collapse[i];
      break;
    }
  }
  return { isCollapse, isCollapseExact, collapseName };
};

const checkChild = (arr, name) => {
  const key = `${name}.`;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].name.indexOf(key) !== -1) {
      return true;
    }
  }
  return false;
};

const checkAlreadyDefined = (groupFields, name) => {
  for (let i = 0; i < groupFields.length; i++) {
    for (let j = 0; j < groupFields[i].length; j++) {
      if (groupFields[i][j].name === name) {
        return true;
      }
    }
  }
  return false;
};

const createDisplayMetadata = (collapse, groupFields, tableFields) => {
  const headers = [];
  const rowSize = groupFields.length + 1;
  groupFields.forEach((arr, i) => {
    const result = [];
    arr.forEach((d) => {
      const { isCollapse, isCollapseExact } = checkCollapseField(collapse, d.name);
      const hasChild = checkChild(i + 1 < groupFields.length ? groupFields[i + 1] : tableFields, d.name);
      if (isCollapse) {
        if (isCollapseExact) {
          result.push({ ...d, _expand: false, rowSpan: rowSize - i });
        }
      } else {
        result.push({ ...d, _expand: true, rowSpan: hasChild ? 1 : rowSize - i });
      }
    });
    headers.push(result);
  });
  // create header and field definition from table fields
  const fieldHeaders = [];
  const fields = [];
  let lastCollapseName = null;
  tableFields.forEach((d) => {
    const { isCollapse, collapseName } = checkCollapseField(collapse, d.name);
    const alreadyDefined = checkAlreadyDefined(groupFields, d.name);
    if (isCollapse) {
      if (collapseName !== lastCollapseName) {
        fields.push({ ...d, _expand: false });
      }
      lastCollapseName = collapseName;
    } else {
      if (!alreadyDefined) {
        fieldHeaders.push({ ...d, _expand: true, rowSpan: 1 });
      }
      fields.push({ ...d, _expand: true });
    }
  });
  // calculate colSpan
  const getColumnSpan = (name) => fields.reduce((acc, d) => d.name === name || d.name.indexOf(`${name}.`) !== -1 ? acc + 1 : acc, 0);
  headers.forEach((arr) => arr.forEach((d) => {
    d.colSpan = getColumnSpan(d.name);
  }));
  headers.push(fieldHeaders);
  return { headers, fields };
};

const AppDataTable = ({ groupFields, tableFields, data, maxHeight = 500 }) => {
  const classes = useStyles();
  const [collapsedItems, setCollapsedItems] = useState([]);
  const [displayHeaders, setDisplayHeaders] = useState([]);
  const [displayFields, setDisplayFields] = useState([]);

  useEffect(() => {
    const collapse = [];
    groupFields.forEach((x) => x.forEach((y) => y.canCollapse && y.initialCollapse && collapse.push(y.name)));
    const { headers, fields } = createDisplayMetadata(collapse, groupFields, tableFields);
    setCollapsedItems(collapse);
    setDisplayHeaders(headers);
    setDisplayFields(fields);
  }, [groupFields, tableFields]);

  const toggleExpand = (i, name) => (event) => {
    const collapse = collapsedItems.filter((key) => !key.startsWith(`${name}.`));
    const index = collapse.indexOf(name);
    if (index === -1) {
      collapse.push(name);
    } else {
      collapse.splice(index, 1);
    }
    const { headers, fields } = createDisplayMetadata(collapse, groupFields, tableFields);
    setCollapsedItems(collapse);
    setDisplayHeaders(headers);
    setDisplayFields(fields);
  };

  const handleLink = (h, d) => (event) => {
    if (h.format) {
      const url = h.format(d[h.name]);
      if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    }
  };

  const renderHeaderCell = (h, row) => {
    if (!h._expand) {
      return (
        <TableCell key={h.name} size="small" align="left" rowSpan={h.rowSpan} classes={{ root: classes.thCollapse }} sx={{ height: 20, top: row * 37, minWidth: 0 }}>
          <ArrowForwardIos color="secondary" classes={{ root: classes.icon }} onClick={toggleExpand(row, h.name)} />
        </TableCell>
      );
    } else {
      return (
        <TableCell key={h.name} size="small" align="left" colSpan={h.colSpan} rowSpan={h.rowSpan} classes={{ root: classes.th }} sx={{ height: 20, top: row * 37, minWidth: (h.width ? h.width : 80) }}>
          {h.label}
          {h.canCollapse && <ArrowBackIosNew color="secondary" classes={{ root: classes.icon }} onClick={toggleExpand(row, h.name)} />}
        </TableCell>
      );
    }
  };

  const renderHeaders = () => {
    return (
      <TableHead>
        {displayHeaders.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((h) => renderHeaderCell(h, rowIndex))}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  const renderFieldCell = (h, d) => {
    const sx = d._sx ? d._sx : {};
    if (!h._expand) {
      return (<TableCell key={h.name} size="small" classes={{ root: classes.tdCollapse }} sx={sx}>...</TableCell>);
    }
    switch (h.type) {
      case 'text':
      default:
        return (
          <TableCell key={h.name} size="small" align="left" classes={{ root: classes.td }} sx={sx}>
            {h.format ? h.format(d[h.name]) : d[h.name]}
          </TableCell>
        );
      case 'number':
        return (
          <TableCell key={h.name} size="small" align="right" classes={{ root: classes.td }} sx={sx}>
            {h.format ? h.format(d[h.name]) : Number.isNaN(d[h.name]) ? '-' : Number(d[h.name]).toLocaleString()}
          </TableCell>
        );
      case 'link':
        return (
          <TableCell key={h.name} size="small" align="left" classes={{ root: classes.td }} sx={sx}>
            {d[h.name] && <Launch color="secondary" classes={{ root: classes.icon }} onClick={handleLink(h, d)} />}
          </TableCell>
        );
      case 'dqrText':
        return (
          <TableCell key={h.name} size="small" align="left" classes={{ root: classes.td }} sx={sx}>
            {
              d[h.name]._id ? <div onClick={() => h.onClick(d[h.name]._id)} style={{textDecoration: "underline", textDecorationColor: "#00ACFF", color: "#00ACFF", cursor: "pointer"}}>{h.format ? h.format(d[h.name]?.name) : d[h.name]?.name}</div> : <div>{h.format ? h.format(d[h.name]?.name) : d[h.name]?.name}</div>
            }
          </TableCell>
        );
    }
  };

  const renderFields = () => {
    return (
      <TableBody>
        {data.map((d, i) => (
          <TableRow key={i} classes={{ root: classes.tr }}>
            {displayFields.map((h) => renderFieldCell(h, d))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: maxHeight }}>
          <Table stickyHeader className={classes.table} aria-label="simple table">
            {renderHeaders()}
            {renderFields()}
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export default AppDataTable;
