import masters from './masters-202206';

const getMasterByName = (name) => masters.find((m) => m.name === name);
const getMasterByText = (text) => masters.find((m) => text.startsWith(m.text));

export {
  masters,
  getMasterByName,
  getMasterByText,
};
