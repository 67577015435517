import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppTable from '../../common/components/AppTable';
import useAppContext from '../../common/hooks/useAppContext';
import {formatTimestampAsDateTimeShort, deliveryStatusToLabel,objectComparator, formatVolume} from '../../common/data-helper';

const breadcrumbs = [
  { name: '施工会社・現場名一覧', path: '../construct' },
];

const tableFields = [
  { name: "shippingDatetime", label: "納入発", type: "datetime", format: formatTimestampAsDateTimeShort, xs: true},
  { name: "concreteName", label: "配合計画", type: "text" },
  { name: "placement", label: "打設箇所", type: "text" },
  { name: "volume", label: "数量", type: "number", xs: true, format: formatVolume },
  { name: "status", label: "状況", type: "select", format: deliveryStatusToLabel, xs: true },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const DeliveryListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (params.constructId) {
      const query = async () => {
        try {
          app.setIsLoading(true);
          const slips = await app.http().get('/blockchain/slip', {
            constructID: params.constructId,
            supplierID: app.loginInfo.user.plantId,
          });
          slips.sort(objectComparator('shippingDatetime', 'desc'));
          setData(slips);
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      };
      query();
    }
  }, [params.constructId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditItem = (item) => () => navigate(`${item._id}/edit`);

  const handleSelectItem = (item) => () => navigate(`${item._id}/view`);

  const rowActions = [
    { name: '伝票詳細', icon: <ChevronRight color="primary" />, handler: handleSelectItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="伝票一覧" breadcrumbs={breadcrumbs}>
        <Button size="large" variant="contained" color="info" onClick={handleEditItem({ _id: 'new' })}>
          伝票作成
        </Button>
      </AppPrimaryArea>
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        <AppTable tableFields={tableFields} data={data} rowActions={rowActions} />
      </Container>
    </div>
  );
};

export default DeliveryListPage;
