import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Typography } from '@mui/material';
import { Stepper, Step, StepButton, StepContent } from '@mui/material';
import dayjs from 'dayjs';

import ProgressDialog from '../../common/components/ProgressDialog';
import AppDataTable from '../../common/components/AppDataTable';
import MasterMaintenancePrimaryArea from './components/MasterMaintenancePrimaryArea';
import TextAreaDialog from './components/TextAreaDialog';
import useAppContext from '../../common/hooks/useAppContext';
import { labelToSeason, formatCompoundPlan, labelToDeliveryStatus, labelToConcreteLocation } from '../../common/data-helper';

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const slipHeader = {
  label: '伝票データ',
  name: 'Slip',
  text: '建築物ID	製造プラントID	コンクリート種別	呼び名	季節	数量	打設箇所種別	打設箇所	納入発	納入着	状況	伝票作成者	受入確認者'
};

const unknownHeader = {
  label: '不明',
  name: 'Invalid',
};

const parse = (text) => {
  const fields = [];
  const texts = text.split(/\r\n|\r|\n/);
  if (texts.length < 2) {
    return null;
  }
  const labels = texts[0].split('\t');
  const names = texts[1].split('\t');
  for (let i = 0; i < labels.length; i++) {
    fields.push({ name: names[i], label: labels[i], type: 'text' });
  }
  const data = [];
  for (let i = 2; i < texts.length; i++) {
    const values = texts[i].split('\t');
    const result = {};
    for (let j = 0; j < names.length; j++) {
      result[names[j]] = values[j];
    }
    data.push(result);
  }
  return { fields, data };
};

const SlipDataRegistrationPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [header, setHeader] = useState(null);
  const [content, setContent] = useState('');
  const [tableFields, setTableFields] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState(null);

  const handleParseContent = (text) => {
    const header = text.startsWith(slipHeader.text) ? slipHeader : null;
    if (header != null) {
      const { fields, data } = parse(text);
      setContent(text);
      setHeader(header);
      setTableFields(fields);
      setDisplayData(data);
      setStep(1);
    } else {
      setContent('');
      setHeader(unknownHeader);
      setTableFields([]);
      setDisplayData([]);
    }
  };

  const handlePaste = async () => {
    const text = await navigator.clipboard.readText();
    handleParseContent(text);
  };

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = (text) => {
    if (text) {
      handleParseContent(text);
    }
    setDialogOpen(false);
  };

  const error = (msg) => ({
    response: {
      status: 400,
      data: {
        msg: msg
      }
    }
  });

  const handleExecute = async () => {
    app.showConfirmDialog('伝票データ登録の確認', `伝票データの登録を実行します。よろしいですか？`, async (result) => {
      if (result) {
        try {
          const http = app.http();
          for (let i = 0; i < displayData.length; i++) {
            setProgressMessage(`${i + 1} / ${displayData.length} 件目の伝票データを登録しています ...`);
            setProgress((i + 1) / displayData.length * 100);
            const d = displayData[i];
            const status = labelToDeliveryStatus(d.status);
            const concreteID = `${d.constructID}:${d.concreteType}:${d.concreteName}:${labelToSeason(d.season)}:${d.supplierID}`;
            const construct = await http.get(`/master/construct/${d.constructID}`);
            if (!construct) {
              throw error(`建築物 ${d.constructID} がマスタ上で見つかりません`);
            }
            const concretePlant = await http.get(`/master/concrete-plant/${d.supplierID}`);
            if (!concretePlant) {
              throw error(`製造プラント ${d.supplierID} がマスタ上で見つかりません`);
            }
            const compoundPlan = await http.get(`/master/compound-plan/${concreteID}`);
            if (!compoundPlan) {
              throw error(`配合計画 ${concreteID} がマスタ上で見つかりません`);
            }
            const createReq = {
              concreteID: compoundPlan._id,
              concreteName: formatCompoundPlan(compoundPlan),
              concreteType: compoundPlan.type,
              constructID: construct._id,
              constructName: construct.constructName,
              constructee: construct.corporateName,
              creator: d.creator,
              shippingDatetime: d.shippingDatetime ? dayjs(d.shippingDatetime, 'YYYY/MM/DD HH:mm').unix() : null,
              status: '0',
              supplier: concretePlant.plantName,
              supplierID: concretePlant._id,
              volume: Number(d.volume),
              location: labelToConcreteLocation(d.location),
              placement: d.placement,
              isPCa: false,
              precastConcreteId: 0
            }
            const createRes = await http.put('/blockchain/slip', createReq);
            if (status !== '0') {
              const acceptReq = {
                _id: createRes._id,
                deliveryDatetime: d.deliveryDatetime ? dayjs(d.deliveryDatetime, 'YYYY/MM/DD HH:mm').unix() : null,
                location: d.location,
                placement: d.placement,
                confirmer: d.confirmer,
                status: status
              };
              await http.post('/blockchain/slip/accept', acceptReq);
            }
          };
          await new Promise((resolve) => app.showMessageDialog('データ登録の終了', `伝票データの登録が完了しました。`, () => resolve()));
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          setStep(0);
          setHeader(null);
          setContent('');
          setTableFields([]);
          setDisplayData([]);
          setProgress(0);
          setProgressMessage(null);
        }
      }
    });
  };

  const text = header ? (header.name === 'Invalid' ? '入力されたデータは無効です' : header.label) : '';
  return (
    <div className={classes.root}>
      <MasterMaintenancePrimaryArea selected="slip-data-registration">
        <Container component="main" maxWidth="md">
          <Stepper activeStep={step} orientation="vertical">
            <Step key="step1">
              <StepButton color="inherit" onClick={() => setStep(0)}>
                データの指定
              </StepButton>
              <StepContent>
                <Typography variant="body2">登録したい伝票データを入力します</Typography>
                <Box sx={{ pt: 1 }}>
                  <Button color="primary" variant="outlined" onClick={handlePaste}>クリップボードから入力</Button>
                  &nbsp;&nbsp;
                  <Button color="primary" variant="outlined" onClick={handleOpenDialog}>テキストで入力</Button>
                </Box>
              </StepContent>
            </Step>
            <Step key="step2" disabled={!content}>
              <StepButton color="inherit" onClick={() => setStep(1)}>
                データの登録
              </StepButton>
              <StepContent>
                <Typography>伝票データ登録を実行します（1件ずつ登録を繰り返すため、時間がかかります）</Typography>
                <Box sx={{ py: 1 }}>
                  <Button color="info" variant="contained" disabled={!content} onClick={handleExecute}>伝票データ登録を実行</Button>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </Container>
      </MasterMaintenancePrimaryArea>
      <Container component="main" maxWidth="md">
        <Box sx={{ my: 1 }}>
          <Typography variant="h6">{text}</Typography>
        </Box>
        <AppDataTable groupFields={[]} tableFields={tableFields} data={displayData} maxHeight="calc(100vh - 350px)" />
      </Container>
      <TextAreaDialog open={dialogOpen} initialText="" onClose={handleCloseDialog} />
      <ProgressDialog open={!!progressMessage} msg={progressMessage} progress={progress} />
    </div>
  );
};

export default SlipDataRegistrationPage;
