import React from 'react';
import { makeStyles } from '@mui/styles';
import { Drawer, IconButton, Divider, List, ListItem, ListItemText, Toolbar } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import useAppContext from '../hooks/useAppContext';

const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
  menuItem: {
    margin: theme.spacing(1),
    borderLeft: 'solid 5px transparent'
  },
  menuItemSelected: {
    margin: theme.spacing(1),
    borderLeft: `solid 5px ${theme.palette.primary.main}`,
    color: '#333333'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  }
}));

const MenuList = ({ pages, handleMenuClick }) => {
  const classes = useStyles();
  return (<List>
    {pages.map((page, index) => (
      <div key={index}>
        <ListItem
          button
          onClick={handleMenuClick(page.path)}
          className={window.location.pathname.startsWith(page.path) ? classes.menuItemSelected : classes.menuItem}
        >
          <ListItemText primary={page.name} />
        </ListItem>
        {/* <Divider /> */}
      </div>
    ))}
  </List>
  );
};

const AppDrawer = ({ pages }) => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    app.setIsDrawerOpen(false);
  };

  const handleMenuClick = path => event => {
    navigate(path);
    app.setIsDrawerOpen(false);
  };

  return (
    <Drawer
      anchor="left"
      className={classes.drawer}
      open={app.isDrawerOpen}
      classes={{ paper: classes.drawerPaper }}
      variant="temporary"
      onClose={handleDrawerClose}
    >
      <Toolbar variant="dense">
        <IconButton onClick={handleDrawerClose}>
          {app.isDrawerOpen ? (<ChevronLeft />) : (<ChevronRight />)}
        </IconButton>
      </Toolbar>
      <Divider />
      <MenuList pages={pages} handleMenuClick={handleMenuClick} />
    </Drawer>
  );
};

export default AppDrawer;
