import axios from 'axios';
import { formatCompoundPlan } from './data-helper';

let isRefreshing = false;
let requests = [];

const httpHelper = (baseURL, useFormData = false, responseBlob = false) => {
  let client = null;
  const options = {
    baseURL,
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`
    }
  };
  if (responseBlob) {
    options.responseType = 'blob';
  }
  if (!useFormData) {
    options.headers['Content-Type'] = 'application/json';
  }
  client = axios.create(options);

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status == 403) {
        const { config } = error.response;
  
        if (!isRefreshing) {
          isRefreshing = true;
          return client.post('/auth/refresh-token', {refresh_token: localStorage.getItem('refresh_token')})
                        .then((sessionData) => {
                          const res = sessionData.data.result;
                          if (res.AuthenticationResult) {
                            localStorage.setItem('access_token', res.AuthenticationResult.AccessToken);
                            config.headers['authorization'] = `Bearer ${localStorage.getItem("access_token")}`;
                            requests.forEach((cb) => cb(`Bearer ${localStorage.getItem("access_token")}`));
                            requests = [];
                            isRefreshing = false;
                            return axios(config);
                          }
                          isRefreshing = false;
                        })
                        .catch((err) => {
                          isRefreshing = false;
                          requests = [];
                          return Promise.reject(err)
                        });
        } else {
          return new Promise((resolve) => {
            requests.push((token) => {
              config.headers.authorization = token;
              resolve(axios(config));
            });
          });
        }
      }
  
      return Promise.reject(error);
    }
  );

  const getFileUrl = async (file, download = false) => {
    const name = download ? file.name : 'inline';
    const res = await client.get(`/attachment/${file.path}/${name}`);
    return res.data.result;
  };

  const httpGet = async (path, params = null) => {
    const q = params ? '?' + Object.keys(params).map((k) => `${k}=${params[k]}`).join('&') : '';
    const res = await client.get(`${path}${q}`);
    return res.data.result;
  };

  const httpPost = async (path, body) => {
    const res = await client.post(path, body);
    return res.data.result;
  };

  const httpPut = async (path, body) => {
    const res = await client.put(path, body);
    return res.data.result;
  };

  const httpDelete = async (path, params = null) => {
    const q = params ? '?' + Object.keys(params).map((k) => `${k}=${params[k]}`).join('&') : '';
    const res = await client.delete(`${path}${q}`);
    return res.data.result;
  };

  const getSlipWithRelations = async (id, withRelations = true) => {
    const toSelectList = (obj, labelProp, valueProp) => obj ? [{ label: obj[labelProp], value: obj[valueProp] }] : [];
    const slip = await httpGet(`/blockchain/slip/${id}`);
    const result = {
      slip: slip,
      construct: null,
      compoundPlan: null,
      creator: null,
      confirmer: null,
      supplier: null,
      selectList: {}
    };
    if (withRelations) {
      if (slip) {
        const promises = [];
        if (slip.constructID) {
          promises.push(httpGet(`/master/construct/${slip.constructID}`).then((res) => {
            result.construct = res;
          }));
        }
        if (slip.concreteID) {
          promises.push(httpGet(`/master/compound-plan/${slip.concreteID}`).then((res) => {
            result.compoundPlan = res;
          }));
        }
        if (slip.creator) {
          promises.push(httpGet(`/master/user/${slip.creator}`).then((res) => {
            result.creator = res;
          }));
        }
        if (slip.confirmer) {
          promises.push(httpGet(`/master/user/${slip.confirmer}`).then((res) => {
            result.confirmer = res;
          }));
        }
        if (slip.supplierID) {
          promises.push(await httpGet(`/master/concrete-plant/${slip.supplierID}`).then((res) => {
            result.supplier = res;
          }));
        }
        await Promise.all(promises);
      }
    }
    if (result.construct) {
      result.selectList.construct = toSelectList(result.construct, 'constructName', '_id');
    }
    if (result.compoundPlan) {
      result.compoundPlan.periodsText = result.compoundPlan.periods.map((d) => d.from + '-' + d.to).join(', ');
      result.selectList.concreteID = [{
        label: formatCompoundPlan(result.compoundPlan),
        value: result.compoundPlan._id,
      }];
    }
    if (result.creator) {
      result.selectList.creator = toSelectList(result.creator, 'displayName', '_id');
    }
    if (result.confirmer) {
      result.selectList.confirmer = toSelectList(result.confirmer, 'displayName', '_id');
    }
    if (result.supplier) {
      result.selectList.supplier = toSelectList(result.supplier, 'plantName', '_id');
    }
    return result;
  };

  const getActivityWithRelations = async (id, withRelations = true) => {
    const toSelectList = (obj, labelProp, valueProp) => obj ? [{ label: obj[labelProp], value: obj[valueProp] }] : [];
    const activity = await httpGet(`/blockchain/activity/${id}`);
    const result = {
      activity: {
        ...activity,
        totalTracks: activity.acceptedTrackNumber + ' / ' + activity.shippingTrackNumber + ' 台',
        totalVolumes: activity.acceptedVolume.toFixed(2) + ' / ' + activity.shippingVolume.toFixed(2) + ' m\u00B3',
      },
      construct: null,
      compoundPlan: null,
      supplier: null,
      selectList: {}
    };
    if (withRelations) {
      if (activity) {
        const promises = [];
        if (activity.constructID) {
          promises.push(httpGet(`/master/construct/${activity.constructID}`).then((res) => {
            result.construct = res;
          }));
        }
        if (activity.concreteID) {
          promises.push(httpGet(`/master/compound-plan/${activity.concreteID}`).then((res) => {
            result.compoundPlan = res;
          }));
        }
        if (activity.supplierID) {
          promises.push(httpGet(`/master/concrete-plant/${activity.supplierID}`).then((res) => {
            result.supplier = res;
          }));
        }
        await Promise.all(promises);
      }
      if (result.construct) {
        result.selectList.construct = toSelectList(result.construct, 'constructName', '_id');
      }
      if (result.compoundPlan) {
        result.compoundPlan.periodsText = result.compoundPlan.periods.map((d) => d.from + '-' + d.to).join(', ');
        result.selectList.concreteID = [{
          label: formatCompoundPlan(result.compoundPlan),
          value: result.compoundPlan._id,
        }];
      }
      if (result.supplier) {
        result.selectList.supplier = toSelectList(result.supplier, 'plantName', '_id');
      }
    }
    return result;
  };

  const download = async (path, body) => {
    const res = await client.post(path, body);
    return res.data;
  };

  return {
    getFileUrl,
    get: httpGet,
    post: httpPost,
    put: httpPut,
    delete: httpDelete,
    getSlipWithRelations,
    getActivityWithRelations,
    download
  }
};

export default httpHelper;