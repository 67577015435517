import React, { useState } from 'react';
import { Button, MenuItem, Select } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { toLineData, downloadTsv } from '../../../common/tsv-file-helper';

const MasterDownload = ({ master, data }) => {
  const [encoding, setEncoding] = useState('SJIS');

  const handleChange = (event) => setEncoding(event.target.value);
  const handleDownload = (event) => {
    const tsv = [];
    const addLine = (d) => tsv.push(toLineData(d));
    event.preventDefault();

    // create header
    addLine(master.itemLabels);
    addLine(master.itemNames);
    // create data
    data.forEach((d) => {
      const lineData = master.itemNames.map((name) => d[name] == null ? '' : d[name]);
      addLine(lineData);
    });

    // execute download
    downloadTsv(tsv, master.name, encoding);
  };

  return (
    <React.Fragment>
      {data && data.length > 0 &&
        <div>
          <Select size="small" variant="standard" name="master" onChange={handleChange} value={encoding} sx={{ verticalAlign: 'bottom' }}>
            <MenuItem value="SJIS">Excel用</MenuItem>
            <MenuItem value="UTF8">UTF8</MenuItem>
          </Select>
          &nbsp;&nbsp;
          <Button size="inline" variant="outlined" endIcon={<FileDownload />} onClick={handleDownload}>
            TSVダウンロード
          </Button>
        </div>
      }
    </React.Fragment>
  );
};

export default MasterDownload;
