import React, {useState} from 'react';
import { makeStyles } from '@mui/styles';
import { DialogContent, Dialog, DialogTitle, DialogActions, Button, TextField } from '@mui/material';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: '70vw',
  },
  buttonArea: {
    justifyContent: 'center'
  },
  button: {
    minWidth: 80
  }
}));

const TextAreaDialog = ({open, initialText, onClose}) => {
  const classes = useStyles();
  const [text, setText] = useState(initialText);
  const handleChange = (event) => setText(event.target.value);
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>データテキストの入力</DialogTitle>
      <DialogContent>
        <TextField id="data-text" fullWidth multiline rows={20} value={text} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={() => onClose(text)} color="info" variant="contained" disabled={!text}>
          OK
        </Button>
        <Button className={classes.button} onClick={() => onClose()} color="primary" variant="outlined">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextAreaDialog;
